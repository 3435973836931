import React, { FC, useEffect, useState } from "react";
import MainLayout from "HOC/MainLayout/MainLayout";
import Props from "./Props";
import { IPost } from "Utilities/State.interfaces";
import { LoadData } from "Components/Theme/LoadData/LoadData";
import { IArgs, IResponse } from "Utilities/Interface";
import {
   isFavoritePostRequest,
   isLikeUnlikePostRequest,
} from "Components/Screen/Activate/Redux/Thunk";
import { isPostsOfUserRequestByActions } from "Apollo/GeneralRedux/Thunk";
import { toast } from "react-toastify";
import { FlexColumn } from "Components/Theme/Flex/Flex";
import useFetchDataWithRedux from "Hooks/useFetch/useFetchDataWithRedux";
import { SimplePostItem } from "Components/Theme/PostItem/PostItem";
import { PageHeading } from "Components/Theme/Pages/PageHeading";
import CommonMethods from "Utilities/CommonMethods";

const Favorites: FC<Props> = () => {
   const [user] = useFetchDataWithRedux();

   const [page, setPage] = useState<number>(1);
   const [loading, setLoading] = useState<boolean>(true);
   const [posts, setPosts] = useState<IPost[]>([]);

   useEffect(() => {
      getPosts({ page, limit: 100 }, true);

      return () => {
         setPosts([]);
      };
   }, [page]);

   const getPosts = (args: IArgs, flag: boolean): void => {
      setLoading(flag);

      isPostsOfUserRequestByActions(
         { ...args, userId: user.id, isFavorite: true },
         (res: IResponse) => {
            setPosts(res.data);
            setLoading(false);
            setPage(args.page);
         }
      );
   };

   const removePostFromFavorite = (id: string): void => {
      isFavoritePostRequest(id, false, (res: IResponse) => {
         if (res.status) {
            setPosts(posts?.filter((f) => f.id !== id));

            showToast(
               res.status,
               "Your post has been removed to favorites successfully!"
            );
         } else {
            showToast(res.status, "Something went wrong!");
         }
      });
   };

   const showToast = (success: boolean, msg): void => {
      if (success) {
         toast.success(msg);
      } else {
         toast.error(msg);
      }
   };

   const likePost = (postId: string, userId: string) => {
      isLikeUnlikePostRequest(postId, userId, (res: IResponse) => {
         setPosts([...CommonMethods.modifyPostObject(res?.data, posts)]);
      });
   };

   return (
      <MainLayout columns={3} docTitle="Favorites">
         <FlexColumn className="w-full">
            <PageHeading title="Favorites" />

            <LoadData loading={loading} arr={posts} msg="No posts available">
               <React.Fragment>
                  {posts.map((post: IPost, index) => (
                     <SimplePostItem
                        post={post}
                        onAction={(postId: string, actionId: number) => {
                           removePostFromFavorite(postId);
                        }}
                        postLikes={post?.likes?.length ? post?.likes?.length : ""}
                        likePost={() => likePost(post?.id, user?.id)}
                        isFavourite={true}
                        activeUser={user}
                        key={index}
                     />
                  ))}
               </React.Fragment>
            </LoadData>

            <FlexColumn className="w-full items-center justify-center p-5 shadow-lg rounded-lg bg-white">
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-12 w-12 mb-4 cursor-pointer bg-purple-500 p-2 rounded-full"
                  fill="#ffffff"
                  viewBox="0 0 24 24"
                  stroke="#ffffff"
                  strokeWidth="2"
               >
                  <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                  />
               </svg>
               <h3>You are up to date on posts from last 3 days</h3>
            </FlexColumn>
         </FlexColumn>
      </MainLayout>
   );
};

export default Favorites;
