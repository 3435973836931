import { gql } from '@apollo/client';
import { PostFragment } from 'Apollo/Fragments';

export const GET_POSTS_BY_ACTIONS = gql`
  query($userId: String! $page: Float $limit: Float $isSaved: Boolean $isFavorite: Boolean){
    getPostsByActions(userId: $userId page: $page limit: $limit isSaved: $isSaved isFavorite: $isFavorite){
        id
        ...PostFragment
    }
  }
  ${PostFragment}
`;

export const IS_EMAIL_INVITATION = gql`
  mutation($email: String!) {
    sentEmailInvite(email: $email)
  }
`;

export const GET_A_POST = gql`
  query($id: String!){
    getAPost(id: $id){
        id
        ...PostFragment
    }
  }
  ${PostFragment}
`;