import { API_URL_UPLOAD_IMAGE } from "Environment/environment";
import { toast } from "react-toastify";

const URL = `${API_URL_UPLOAD_IMAGE}`;

const useUploadFile = () => {
   const upload = async (file: File, type: string, callback) => {
      if (!file) {
         toast.error("Kindly upload file!.");
         callback(undefined);
      } else {
         const formData = new FormData();
         formData.append("type", type);
         formData.append("image", file);

         fetch(URL, {
            method: "POST",
            body: formData,
         })
            .then((r) => r.json())
            .then((res) => {
               if (res?.error) {
                  toast.error(res?.message);
                  callback(undefined);
               } else {
                  // toast.success(res?.message);
                  callback(res?.url);
               }
            })
            .catch(() => {
               toast.error("Something went wrong!.");
               callback(undefined);
            });
      }
   };

   return [upload];
};

export default useUploadFile;
