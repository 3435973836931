import React, { FC, useEffect } from "react";

import { FindWithSearch } from "Components/Theme/FindWithSearch/FindWithSearch";
import TabsNavigation from "Components/Theme/TabNavigation/TabNavigation";
import { friendsTabs } from "Utilities/Data";
import Props from "./Props";
import { AppRoutes } from "Routes/Routes";
import MainLayout from "HOC/MainLayout/MainLayout";
import { IArgs, IResponse, ITab } from "Utilities/Interface";
import { FlexColumn } from "Components/Theme/Flex/Flex";
import { LoadData } from "Components/Theme/LoadData/LoadData";
import { IUser } from "Utilities/State.interfaces";
import useFetchDataWithRedux from "Hooks/useFetch/useFetchDataWithRedux";
import { isUsersRequest } from "../Trainers/Redux/Thunk";
import { UserItem } from "Components/Theme/UserItem/UserItem";
import { UserTypeEnumerator } from "Utilities/Enums";

export const Friends: FC<Props> = () => {
   const [user, navigate] = useFetchDataWithRedux();

   const [activeTab, setActiveTab] = React.useState<ITab>(friendsTabs[0]);
   const [friends, setFriends] = React.useState<IUser[]>([]);

   const [loading, setLoading] = React.useState<boolean>(true);
   const [page, setPage] = React.useState<number>(1);
   const [search, setSearch] = React.useState<string>("");

   useEffect(() => {
      getTrainers({ page, searchTerm: search, limit: 50 });

      return () => {
         setPage(1);
         setLoading(true);
      };
   }, [page]);

   const getTrainers = (args: IArgs): void => {
      setLoading(true);

      if (!args.searchTerm) {
         delete args.searchTerm;
      }

      isUsersRequest(
         { ...args, type: UserTypeEnumerator.GOLF_USER },
         (res: IResponse) => {
            setFriends(res.data);
            setTimeout(() => setLoading(false), 1000);
         }
      );
   };

   return (
      <MainLayout columns={3} docTitle="Friends">
         <FlexColumn className="w-full">
            <FindWithSearch
               search={search}
               setSearch={setSearch}
               pressEnter={(val) => {
                  getTrainers({ page, searchTerm: val, limit: 50 });
                  setSearch(val);
               }}
               heading="Friends"
               subHeading="Invite and connect with your golf players"
            />

            <TabsNavigation
               tabs={friendsTabs}
               active={activeTab?.id}
               onClick={(tab) => {
                  setActiveTab(tab);
                  // if(tab.id === 1){
                  //    setFriends([]);
                  // } else {
                  //    setFriends([]);
                  // }
               }}
            />

            <LoadData loading={loading} msg="No data available." arr={friends}>
               <ul
                  role="list"
                  className="space-y-12 mt-5 sm:grid sm:grid-cols-3 sm:gap-y-12 sm:space-y-0 md:grid-cols-3 lg:grid-cols-3 "
               >
                  {friends.map((friend, index) => (
                     <UserItem
                        user={friend}
                        key={index}
                        onClick={() => navigate(`/${AppRoutes.friends}/${friend?.id}`)}
                     />
                  ))}
               </ul>
            </LoadData>
         </FlexColumn>
      </MainLayout>
   );
};
