import { isGolfCitiesRequest, isGolfCountriesRequest, isGolfCourseRequest } from 'Components/Screen/GolfClubs/Redux/Thunk';
import * as React from 'react';
import { PostTypesEnumerator } from 'Utilities/Enums';
import { ICity, ICountry, IResponse, IArgs } from 'Utilities/Interface';
import { IGolfCourse, IPostBuddyAvatar, IUserProfile } from "Utilities/State.interfaces";
import { isGetBuddyPostAvatarRequestViaCallback, isGetBuddyPostRequestViaCallback, isGetUserProfileRequestViaCallback } from "../../Components/Screen/UserSettings/screens/Profile/Redux/Thunk";
import { isPostsRequest } from 'Components/Screen/Activate/Redux/Thunk';
const useFetch = (args: IArgs | undefined): [boolean, ICountry[], ICity[]] => {

   const [load, setLoading] = React.useState<boolean>(false);
   const [countries, setCountries] = React.useState<ICountry[]>([]);
   const [cities, setCities] = React.useState<ICity[]>([]);

   React.useEffect(() => {
      setLoading(true);
      getCountries();
      getCities();
   }, [args]);

   const getCountries = (): void => {
      isGolfCountriesRequest(args, (res: IResponse) => {
         setCountries(res.data);
         setLoading(false);
      });
   };

   const getCities = (): void => {
      isGolfCitiesRequest(args, (res: IResponse) => {
         setCities(res.data);
         setLoading(false);
      });
   };

   return React.useMemo(() => [load, countries, cities], [cities, countries]);
};

export const useFetchGolfCourse = (courseId: string): [boolean, IGolfCourse] => {

   const [loading, setLoading] = React.useState<boolean>(true);
   const [golfCourse, setGolfCourse] = React.useState<IGolfCourse>(undefined);

   React.useEffect(() => {
      if(courseId){
         initialRender();
      }
   }, [courseId]);

   const initialRender = (): void => {
      isGolfCourseRequest(courseId, (res: IResponse) => {
         if(res.status){
            setGolfCourse(res.data);
            setTimeout(() => setLoading(false), 1000);
         } else {
            setLoading(false);
         }
      });
   };

   return React.useMemo(() => [loading, golfCourse], [loading, golfCourse]);
};

export const useFetchUserProfile = (userId: string): [IUserProfile] => {

   const [userProfile, setUserProfile] = React.useState<IUserProfile>(undefined);

   React.useEffect(() => {
      if(userId){
         initialRender();
      }
   }, [userId]);

   const initialRender = (): void => {
      isGetUserProfileRequestViaCallback({ userId }, (res: IResponse) => {
         setUserProfile(res.data);
      });
   };

   return React.useMemo(() => [userProfile], [userProfile]);
};

export const useFetchBuddyAvatars = (postId: string): [boolean, IPostBuddyAvatar[], () => void] => {

   const [userAvatar, setUserAvatar] = React.useState<IPostBuddyAvatar[]>(undefined);

   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   React.useEffect(() => {
      if(postId){
         setIsLoading(false);
         initialRender();
      }
   }, [postId]);

   const initialRender = (): void => {
      setIsLoading(true);
      isGetBuddyPostAvatarRequestViaCallback({ postId }, (res: IResponse) => {
         if (res?.status) {
            setUserAvatar(res.data); 
            setIsLoading(false);      
         }  
         else {
            setUserAvatar([]) ;          
            setIsLoading(false);
         }    
      });
   };

   const refreshRender = () => {
      setUserAvatar([]);
      initialRender();
   };

   return React.useMemo(() => [isLoading,userAvatar, refreshRender], [isLoading, userAvatar, refreshRender]);
};

export const useFetchBuddyPosts = (userId: string): [boolean, [], () => void] => {

   const [buddyPosts, setBuddyPosts] = React.useState<[]>(undefined);

   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   React.useEffect(() => {
      if(userId){
         setIsLoading(false);
         initialRender();
      }
   }, [userId]);

   const initialRender = (): void => {
      setIsLoading(true);
      isGetBuddyPostRequestViaCallback({ userId }, (res: IResponse) => {
         if (res?.status) {
            setBuddyPosts(res.data); 
            setIsLoading(false);      
         }  
         else {
            setBuddyPosts([]) ;          
            setIsLoading(false);
         }    
      });
   };

   const refreshRender = () => {
      setBuddyPosts([]);
      initialRender();
      setIsLoading(true);
   };

   return React.useMemo(() => [isLoading, buddyPosts, refreshRender], [isLoading, buddyPosts, refreshRender]);
};

export default useFetch;