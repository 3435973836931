import { IS_LOGOUT, USER_LATEST_POST, USER_POST_ID} from "Redux/Types";
import { IAction, IPostState } from "Utilities/State.interfaces";

const initialState: IPostState = {
   details: []
};

export const userPostReducer = (state = initialState, action: IAction): IPostState => {

   const { payload, type } = action;
   switch(type){

      case USER_LATEST_POST: {
         return {
            ...state,
            details: [...state.details, payload]       
         };
      }
      case IS_LOGOUT: {
         return {
            ...initialState
         };
      }
      default:
         return state;

   }
};