import { useEffect, useState } from "react";

import { FlexColumn, Span } from 'Components/Theme/Flex/Flex';
import { useParams } from 'react-router';
import { Redirect } from 'Routes/Redirect';
import { toast } from 'react-toastify';
import { FaSpinner } from 'react-icons/fa';
import { IResponse } from 'Utilities/Interface';
import ApiClient from 'Services/ApiClient';
import { IS_ACCOUNT_CONFIRMATION } from "./Redux/Query";

export default function ConfirmationAccount(): JSX.Element | null {

   const [loading, setLoading] = useState<boolean>(true);
   const { userId } = useParams<{ userId: string; }>();

   useEffect(() => {

      async function run(){
         const { data, errors } = await ApiClient.sendPostWithAuth({ userId }, IS_ACCOUNT_CONFIRMATION);
        
         console.log(errors);

         if(data?.userAccountVerification){
            toast.success("Your account has been verified successfully.");
         }

         if(!data?.userAccountVerification){
            toast.success("Your account has been already verified.");
         }                 

         if(!errors){
            setTimeout(() => {
               setLoading(false);
            }, 1000);
         }
      }

      run();      
   }, []);

   if(loading){
      return (
         <FlexColumn className="items-center justify-center fixed h-full w-full bg-zinc-300">
            <Span title="Loading ..." className="block text-lg mb-6" />
            <FaSpinner className="animate-spin text-5xl text-sky-600" />
         </FlexColumn>    
      );
   }   

   return <Redirect to={'..'} />;
}