import React, { FC } from 'react';

interface IInput{
   value: string | number | any;
   className: string;
   htmlFor: string;
   required: boolean;
   onChange: (value) => void;
   placeholder: string;   
   type: string;
   onKeyPress?: () => void;
   min?: number;
   max?: number;
   rows?: number;
   readonly?: boolean;
}

const TextInput:FC<IInput> = ({ htmlFor, required, className, value, onChange, placeholder, type, onKeyPress, readonly }) => {
   return (
      <input
         id={htmlFor} value={value}
         name={htmlFor} type={type ? type : "text"}
         onChange={(e) => onChange(e.target.value)}
         autoComplete={htmlFor}
         required={required}
         className={className}
         placeholder={placeholder} readOnly={readonly}
         onKeyPress={(e) => e.key === 'Enter' && onKeyPress()}
      />
   );
};

const NumberInput:FC<IInput> = ({ htmlFor, required, className, value, onChange, min, max, placeholder, readonly }) => {
   return (
      <input
         id={htmlFor} value={value}
         name={htmlFor} type="number"
         onChange={(e) => onChange(e.target.value)}
         autoComplete={htmlFor} placeholder={placeholder}
         required={required} min={min}
         className={className} max={max}
         readOnly={readonly}
      />
   );
};

const TextArea:FC<IInput> = ({ htmlFor, required, className, value, onChange, rows, placeholder }) => {
   return (
      <textarea
         id={htmlFor} value={value}
         name={htmlFor} rows={rows}
         onChange={(e) => onChange(e.target.value)}
         autoComplete={htmlFor}
         required={required} placeholder={placeholder}
         className={className}
      ></textarea>
   );
};

const InputField:FC<IInput> = (props) => {
    
   if(props.type === 'number'){
      return <NumberInput {...props} />;
   }
   
   if(props.type === 'textarea'){
      return <TextArea {...props} />;
   }

   return <TextInput {...props} />;

};

export default InputField;