import { Assets } from "Assets/Assets";
import { SimpleNavMenu } from "Components/Theme/SimpleNavMenu/SimpleNavMenu";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router";
import { shareLinks } from "Utilities/Data";
import AppSVG from "Utilities/Svg";
import Menu from "Components/Theme/Menu/Menu";

const SingleMarketPlacePost = () => {
   const navigate = useNavigate();

   return (
      <div className="z-50 inset-0" role="dialog" aria-modal="true">
         <div
            onClick={() => navigate("..")}
            className="absolute left-3 top-3 cross-white cursor-pointer z-10 md:right-3 lg:right-3 xl:right-3 "
         >
            {AppSVG.close}
         </div>
         <div className="fixed inset-y-0 right-0 flex flex-col md:flex-row lg:flex-row xl:flex-row w-full overflow-y-scroll">
            <div className="sm:flex-1 md:flex-1 lg:flex-1">
               <Carousel
                  showArrows={true}
                  autoPlay
                  autoFocus
                  width={"100%"}
                  thumbWidth={70}
                  useKeyboardArrows={true}
                  transitionTime={1000}
                  infiniteLoop={true}
               >
                  <div>
                     <img
                        src={
                           "https://cdn.pixabay.com/photo/2014/02/27/16/10/flowers-276014__340.jpg" ??
                  Assets.emptySrc
                        }
                        alt=""
                     />
                     <p className="legend">Legend 1</p>
                  </div>
                  <div>
                     <img
                        src={
                           "https://cdn.pixabay.com/photo/2014/02/27/16/10/flowers-276014__340.jpg" ??
                  Assets.emptySrc
                        }
                        alt=""
                     />
                     <p className="legend">Legend 2</p>
                  </div>
               </Carousel>
            </div>

            <div className="">
               <div className="flex flex-col sm:flex-1 h-full overflow-y-scroll bg-white">
                  <div>
                     <div className="pb-1 sm:pb-6">
                        <div>
                           <div className="relative h-40 sm:h-56">
                              <img
                                 className="absolute h-full w-full object-cover"
                                 src={
                                    "https://cdn.pixabay.com/photo/2014/02/27/16/10/flowers-276014__340.jpg" ??
                        Assets.emptySrc
                                 }
                              />
                           </div>
                           <div className="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                              <div className="sm:flex-1">
                                 <div>
                                    <div className="flex items-center">
                                       <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                            Beautiful Places on Earth
                                       </h3>
                                    </div>
                                    <p className="text-md text-gray-500">$10</p>
                                 </div>
                                 <div className="mt-5 flex flex-wrap space-x-1 sm:space-y-0 sm:space-x-3">
                                    <button
                                       type="button"
                                       className="inline-flex w-full flex-1 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                                    >
                          Message
                                    </button>
                                    <button
                                       type="button"
                                       className="inline-flex w-full flex-1 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                                    >
                          Call
                                    </button>

                                    <span className="ml-3 inline-flex sm:ml-0">
                                       <div className="relative inline-block text-left">
                                          <Menu
                                             type="popover"
                                             className="right-0"
                                             button={{
                                                title: AppSVG.ellipsisVertical,
                                                className:
                                  "inline-flex items-center rounded-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-400 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2",
                                             }}
                                          >
                                             <SimpleNavMenu
                                                menu={shareLinks()}
                                                onClick={(obj) => console.log(obj)}
                                                className="w-48 px-1"
                                             ></SimpleNavMenu>
                                          </Menu>
                                       </div>
                                    </span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                        <dl className="space-y-8 px-4 sm:space-y-6 sm:px-6">
                           <div>
                              <dt className="text-md font-bold text-black sm:w-40 sm:flex-shrink-0">
                      Address
                              </dt>
                              <dd className="mt-1 text-md text-gray-500 sm:col-span-2">
                                 <p> Raiwand Road Lahore, Pakistan</p>
                              </dd>
                           </div>
                           <div>
                              <dt className="text-md font-bold text-black sm:w-40 sm:flex-shrink-0">
                      Description
                              </dt>
                              <p className="mt-1 text-md text-gray-500 sm:col-span-2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                              </p>
                           </div>
                        </dl>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default SingleMarketPlacePost;
