
import ApiClient from 'Services/ApiClient';
import CommonMethods from 'Utilities/CommonMethods';
import { IArgs, IResponse } from 'Utilities/Interface';
import { GET_POSTS_BY_USER, IS_CREATE_NEW_POST, IS_DELETE_POST, IS_GET_ALL_POSTS_QUERY, IS_FAV_UNFAV_POST, IS_SAVED_UNSAVED_POST, IS_LIKE_UNLIKE_POST, ACCEPT_NOT_ACCEPT_BUDDY_POST } from './Query';

export const isPostsRequest = (args: IArgs, callback: (response: IResponse) => void): void => {

   const variables = { ...args };

   ApiClient.sendGetWithAuthWithCallback(variables, IS_GET_ALL_POSTS_QUERY, (res) => {

      callback(CommonMethods.responseProcess(true, res.data.getAllPosts));
      
   }, () => {

      callback(CommonMethods.responseProcess(false, []));

   });
 
};

export const isPostsOfUserRequest = (args: IArgs, userId: string, callback: (response: IResponse) => void): void => {

   const variables = { ...args };

   ApiClient.sendGetWithAuthWithCallback(variables, GET_POSTS_BY_USER, (res) => {

      callback(CommonMethods.responseProcess(true, res.data.getPostsByUser));
      
   }, () => {

      callback(CommonMethods.responseProcess(false, []));

   });
 
};

export const isCreateNewPostRequest = (details, callback: (response: IResponse) => void): void => {

   const variables = { details };

   ApiClient.sendPostWithAuthWithCallback(variables, IS_CREATE_NEW_POST, (res) => {

      callback(CommonMethods.responseProcess(true, res));
      
   }, (err) => {
      
      callback(CommonMethods.responseProcess(false, err?.message));

   });

};

export const isDeletePostRequest = (id: string, callback: (response: IResponse) => void): void => {

   const variables = { id };

   ApiClient.sendPostWithAuthWithCallback(variables, IS_DELETE_POST, (res) => {

      callback(CommonMethods.responseProcess(true, res));
      
   }, (err) => {
      
      callback(CommonMethods.responseProcess(false, err?.message));

   });

};

export const isFavoritePostRequest = (id: string, isFavorite: boolean, callback: (response: IResponse) => void): void => {

   const variables = { id, isFavorite };

   ApiClient.sendPostWithAuthWithCallback(variables, IS_FAV_UNFAV_POST, (res) => {
      callback(CommonMethods.responseProcess(true, res?.data?.isFavoriteUnFavoritePost));
      
   }, (err) => {
      
      callback(CommonMethods.responseProcess(false, err?.message));

   });

};

export const isSavedPostRequest = (id: string, isSaved: boolean, callback: (response: IResponse) => void): void => {

   const variables = { id, isSaved };

   ApiClient.sendPostWithAuthWithCallback(variables, IS_SAVED_UNSAVED_POST, (res) => {

      callback(CommonMethods.responseProcess(true, res?.data?.isSavedUnsavedPost));
      
   }, (err) => {
      
      callback(CommonMethods.responseProcess(false, err?.message));

   });

};

export const isLikeUnlikePostRequest = (postId: string, userId: string, callback: (response: IResponse) => void): void => {

   const variables = { postId, userId };

   ApiClient.sendPostWithAuthWithCallback(variables, IS_LIKE_UNLIKE_POST, (res) => {

      callback(CommonMethods.responseProcess(true, res?.data?.isLikeOrUnlikeAPost));
      
   }, (err) => {
      
      callback(CommonMethods.responseProcess(false, err?.message));

   });

};

export const acceptNotAcceptBuddyPost = (postId: string, userId: string, callback: (response: IResponse) => void): void => {

   const variables = { postId, userId };

   ApiClient.sendPostWithAuthWithCallback(variables, ACCEPT_NOT_ACCEPT_BUDDY_POST, (res) => {

      callback(CommonMethods.responseProcess(true, res));
      
   }, (err) => {
      
      callback(CommonMethods.responseProcess(false, err?.message));

   });

};