import * as React from 'react';
// import { S3BucketConfig } from 'Utilities/Constants';

export interface IWithS3Bucket {
   myBucket: any;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const withS3Bucket = <ComponentProps extends Props>(WrappedComponent: React.ComponentType<ComponentProps & Props>, S3_BUCKET: string) => {

   const WithS3BucketWrapper:React.FC<ComponentProps & Props> = (props) => {

      const myBucket = () => '';

      return <WrappedComponent {...props} myBucket={myBucket} />;

   };

   return WithS3BucketWrapper;

};
 
export default withS3Bucket;