import * as React from "react";
import AppSVG from "Utilities/Svg";
import InputField from "../FormElements/Input";
import { Label } from "../FormElements/Label";
import Props from "./Props";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import { Button } from "../FormElements/Button";
import CommonMethods from "Utilities/CommonMethods";
import { IResponse } from "Utilities/Interface";
import { isSendInvitationRequest } from "Apollo/GeneralRedux/Thunk";
export const RightSideContent: React.FC<Props> = () => {
   const [email, setEmail] = React.useState<string>("");
   const [loading, setLoading] = React.useState<boolean>(false);

   const sendInvite = (): void => {
      if (!email) {
         toast.error("Write the email address.");
      } else if (!CommonMethods.validateEmail(email)) {
         toast.error("Write the valid email address.");
      } else {
         setLoading(true);
         isSendInvitationRequest(email, (res: IResponse) => {
            if (res.status) {
               toast.success("Your invitation has been sent successfully!");
            } else {
               toast.error("Try again. Something went wrong.");
            }

            setLoading(false);
            setEmail("");
         });
      }
   };
   return (
      <div
         id="right_content"
         className="hidden col-span-2 px-2 py-4 sm:px-4 lg:px-8 mt-1 bg-white overflow-y-auto lg:block"
      >
         <div className="mb-2">
            <Label
               htmlFor="email"
               className="block text-sm font-medium text-gray-700"
               title="Invite your friends"
            />
            <div className="mt-1 relative rounded-md shadow-sm ">
               <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  {AppSVG.envelop("h-5 w-5 text-gray-400 md:h-4 md:w-4")}
               </div>
               <InputField
                  htmlFor="email"
                  placeholder="you@example.com"
                  value={email}
                  onChange={(val) => setEmail(val)}
                  type="email"
                  className="block w-full pl-10 md:pl-8 py-2 sm:text-sm md:text-xs lg:text-sm xl:text-sm border-gray-300 border-2 focus:border-primary focus:ring-0 rounded-md"
                  onKeyPress={() => sendInvite()}
                  readonly={false}
                  required={true}
               />
            </div>
         </div>
         <Button
            className="inline-flex items-center justify-center w-24 py-2 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
            disabled={loading}
            id="submit"
            loading={false}
            onClick={sendInvite}
            title={
               loading ? (
                  <FaSpinner className="animate-spin text-xs text-white" />
               ) : (
                  "Invite Now"
               )
            }
         />
         <div className="text-sm text-gray-500 block mt-10">Sponsored ads</div>
         <h3 className="text-sm text-gray-400 block mt-1">Coming soon</h3>
      </div>
   );
};
