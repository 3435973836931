import * as React from "react";
import Props from "./Props";

import withAuthentication from "HOC/WithAuthentication/WithAuthentication";

import { RightSideContent } from "Components/Theme/RightContent/RightContent";
import { SideBar } from "Components/Theme/SideBar/SideBar";
import NavBar from "Components/Theme/NavBar/NavBar";
import { Outlet } from "react-router-dom";
import { DocumentTitle } from "Components/Theme/DocumentTitle/DocumentTitle";

import { sideBarMainLinks, sideBarUserLinks } from "Utilities/Data";
import { ILinkObject } from "Utilities/Interface";
import { AppRoutes } from "Routes/Routes";
import { FlexColumn, Grid } from "Components/Theme/Flex/Flex";
import { isLogoutResponse } from "Components/Screen/SignIn/Redux/Thunk";

const MainLayout: React.FC<Props> = ({
   children,
   columns,
   docTitle,
   changeNavigation,
   user,
   dispatch,
   className,
}) => {
   const sideNavigations: ILinkObject[] = changeNavigation
      ? sideBarUserLinks
      : sideBarMainLinks;

   return (
      <DocumentTitle condition={docTitle} title={docTitle}>
         <FlexColumn className="w-full h-screen overflow-hidden">
            <NavBar
               user={user}
               profileImage={user?.userProfile?.profilePhoto}
               display={true}
               onLogout={() => dispatch(isLogoutResponse())}
            />

            <Grid className="grid-cols-12 sm:grid-cols-9 gap-4 grow h-full">
               <SideBar arr={sideNavigations} />

               <div
                  id="calendar_content_w_full"
                  className={`${
                     columns === 3
                        ? "lg:col-span-5 lg:pr-5"
                        : "col-span-7 lg:pr-8 lg:col-span-5"
                  } col-span-12 h-full overflow-y-auto pt-4 pb-6 px-2 sm:px-3 lg:pl-3 mb-5`}
               >
                  {children}
               </div>

               {columns === 3 && <RightSideContent condition={true} />}
            </Grid>
            <Outlet />
         </FlexColumn>
      </DocumentTitle>
   );
};

export default withAuthentication(MainLayout, AppRoutes.signin);
