import React, { FC } from "react";
import Props from "./Props";

import { SettingContainer } from "Components/Screen/UserSettings/sub-components/SettingContainer/SettingContainer";
import useFetch from "Hooks/useFetch/useFetch";

export const Settings: FC<Props> = ({ user, userProfile }) => {
   const [load, countries, cities] = useFetch(undefined);
   console.log(user?.type);
   const dataMapping = [
      {
         id: 1,
         heading: "Full Name",
         value:
        (userProfile?.firstname ?? "") + " " + (userProfile?.lastname ?? ""),
      },
      { id: 2, heading: "Gender", value: userProfile?.gender },
      {
         id: 3,
         heading: "Account Type",
         value: user?.type === "GOLF_USER" ? "Golfer" : "Golf User",
      },
      { id: 4, heading: "Biography", value: userProfile?.about },
      {
         id: 5,
         heading: "Country",
         value: countries.find((c) => c.id === userProfile?.countryId)?.name,
      },
      {
         id: 6,
         heading: "City",
         value: cities.find((c) => c.id === userProfile?.cityId)?.name,
      },
      { id: 7, heading: "Address", value: userProfile?.address },
      { id: 8, heading: "State", value: userProfile?.state },
      { id: 9, heading: "Postal Code", value: userProfile?.postalCode },
   ];

   return (
      <SettingContainer title="General Account Settings">
         <dl className="divide-y divide-gray-200">
            {dataMapping.map((list) => (
               <div
                  className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4"
                  key={list.id}
               >
                  <dt className="text-sm font-medium text-black">{list.heading}</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                     <span className="flex-grow">{list?.value ?? ""}</span>
                     <span className="ml-4 flex-shrink-0">
                        <button
                           type="button"
                           className="bg-white hidden rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                  Edit
                        </button>
                     </span>
                  </dd>
               </div>
            ))}
         </dl>
      </SettingContainer>
   );
};
