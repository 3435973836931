import React, { FC, useEffect, useState } from "react";
import MainLayout from "HOC/MainLayout/MainLayout";
import Props from "./Props";
import { IPost } from "Utilities/State.interfaces";
import { LoadData } from "Components/Theme/LoadData/LoadData";
import { IArgs, IResponse } from "Utilities/Interface";
import {
   isLikeUnlikePostRequest,
   isSavedPostRequest,
} from "Components/Screen/Activate/Redux/Thunk";
import { isPostsOfUserRequestByActions } from "Apollo/GeneralRedux/Thunk";
import { PostTypesEnumerator } from "Utilities/Enums";
import { toast } from "react-toastify";
import { Grid, FlexColumn } from "Components/Theme/Flex/Flex";
import useFetchDataWithRedux from "Hooks/useFetch/useFetchDataWithRedux";
import { SimplePostItem } from "Components/Theme/PostItem/PostItem";
import { PageHeading } from "Components/Theme/Pages/PageHeading";
import CommonMethods from "Utilities/CommonMethods";

export const Saved: FC<Props> = () => {
   const [user] = useFetchDataWithRedux();

   const [page, setPage] = useState<number>(1);
   const [loading, setLoading] = useState<boolean>(true);
   const [posts, setPosts] = useState<IPost[]>([]);

   useEffect(() => {
      getPosts({ page, limit: 100 }, true);

      return () => {
         setPosts([]);
      };
   }, [page]);

   const getPosts = (args: IArgs, flag: boolean): void => {
      setLoading(flag);
      console.log(args);
      isPostsOfUserRequestByActions(
         { ...args, userId: user.id, isSaved: true },
         (res: IResponse) => {
            setPosts(res.data);
            setLoading(false);
         }
      );
   };

   const removePostFromSaved = (id: string): void => {
      isSavedPostRequest(id, false, (res: IResponse) => {
         if (res.status) {
            setPosts(posts?.filter((f) => f.id !== id));
            showToast(
               res.status,
               "Your post has been removed to saved successfully!"
            );
         } else {
            showToast(res.status, "Something went wrong!");
         }
      });
   };

   const showToast = (success: boolean, msg): void => {
      if (success) {
         toast.success(msg);
      } else {
         toast.error(msg);
      }
   };

   const likePost = (postId: string, userId: string) => {
      isLikeUnlikePostRequest(postId, userId, (res: IResponse) => {
         setPosts([...CommonMethods.modifyPostObject(res?.data, posts)]);
      });
   };

   return (
      <MainLayout columns={3} docTitle="Saved">
         <FlexColumn className="w-full">
            <PageHeading title="All" />

            <LoadData loading={loading} arr={posts} msg="No posts available">
               <Grid className="grid-cols-2 gap-7 mb-28">
                  {posts.map((post: IPost, index) => (
                     <SimplePostItem
                        post={post}
                        onAction={(postId: string, actionId: number) =>
                           removePostFromSaved(postId)
                        }
                        postLikes={post?.likes?.length ? post?.likes?.length : ""}
                        likePost={() => likePost(post?.id, user?.id)}
                        activeUser={user}
                        key={index}
                        isSaved
                     />
                  ))}
               </Grid>
            </LoadData>
         </FlexColumn>
      </MainLayout>
   );
};
