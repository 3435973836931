import React, { FC, useState, useCallback } from "react";
import { Link } from "Components/Theme/Link/Link";
import { AppRoutes } from "Routes/Routes";
import Props from "./Props";
import { DocumentTitle } from "Components/Theme/DocumentTitle/DocumentTitle";
import { Label } from "Components/Theme/FormElements/Label";
import InputField from "Components/Theme/FormElements/Input";
import { IField, IResponse } from "Utilities/Interface";
import { Button } from "Components/Theme/FormElements/Button";
import { Assets } from "Assets/Assets";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import { useLocation } from "react-router";
import { Redirect } from "Routes/Redirect";
import { isResetPasswordRequest } from "./Redux/Thunk";
import { FE_BASE_URL } from "Utilities/Constants";
import AppSVG from "Utilities/Svg";
import { tokenService } from "Services/tokenService";

export const ResetPassword: FC<Props> = () => {
   const location = useLocation();

   const [values, setValues] = useState<{
      newPassword: string;
      confirmNewPassword;
   }>({ newPassword: "", confirmNewPassword: "" });
   const [loading, setLoading] = useState<boolean>(false);

   const [error, setError] = useState<boolean>(false);

   const [fields, setFields] = useState<IField[]>([
      {
         id: 1,
         key: "newPassword",
         disabled: false,
         placeholder: "",
         required: true,
         type: "password",
         label: "New Password *",
      },
      {
         id: 1,
         key: "confirmNewPassword",
         disabled: false,
         placeholder: "",
         required: true,
         type: "password",
         label: "Confirm New Password *",
      },
   ]);

   React.useEffect(() => {
      if (location.search && location.search.includes("?token=")) {
         const token: string[] = location.search.split("token=");
         tokenService.setAccessToken(token[1]);
         tokenService.setRefreshToken(token[1]);
      }
   }, []);

   const onChangeHandler = useCallback(
      (value, key: string): void => {
         setValues({ ...values, [key]: value });
      },
      [values]
   );

   const showHidePassword = useCallback(
      (index: number) => {
         fields[index] = {
            ...fields[index],
            type: fields[index].type === "password" ? "text" : "password",
         };

         setFields([...fields]);
      },
      [fields]
   );

   const submit = (): void => {
      if (!values.newPassword || !values.confirmNewPassword) {
         toast.error("Fields with (*) are required.");
         setError(true);
      } else if (values.newPassword !== values.confirmNewPassword) {
         toast.error("Entered passwords are not equal");
         setError(true);
      } else {
         setLoading(true);
         isResetPasswordRequest(
            {
               newPassword: values.newPassword,
               oldPassword: values.confirmNewPassword,
            },
            (res: IResponse) => {
               if (res.status) {
                  toast.success("Your password has been reset successfully!.");
                  tokenService.clearTokens();
                  setTimeout(() => {
                     window.location.replace(`${FE_BASE_URL}/${AppRoutes.signin}`);
                  }, 1000);
               } else {
                  toast.error("Password cannot be same as old password. ");
               }

               setLoading(false);
            }
         );
      }
   };

   if (!location.search || !location.search.includes("token")) {
      return <Redirect to={"/" + AppRoutes.signin} />;
   }

   return (
      <DocumentTitle condition={true} title="Reset Password">
         <div className="min-h-screen flex flex-col justify-center py-6 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
               <img
                  className="mx-auto w-24"
                  src={Assets.golfLogo}
                  alt="GolfGuiders"
               />
               <h2 className="mt-6 text-center text-lg text-gray-600">
            Reset Password to your account
               </h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
               <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                  <form className="space-y-6" action="#" method="POST">
                     {fields.map((field: IField, index: number) => (
                        <div key={index}>
                           <Label
                              htmlFor={field.key}
                              className="block text-sm font-medium text-gray-700"
                              title={field.label}
                           />
                           <div className="mt-1 relative">
                              <InputField
                                 htmlFor={field.key}
                                 placeholder={field.placeholder}
                                 required={field.required}
                                 value={values[field.key]}
                                 type={field.type}
                                 onChange={(val) => onChangeHandler(val, field.key)}
                                 className={`appearance-none block w-full px-3 py-2 border  ${
                                    (values.newPassword || !values.confirmNewPassword) &&
                        error
                                       ? "border-red-500"
                                       : "border-gray-300"
                                 } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                              />
                              <span
                                 className="absolute right-3 bottom-0 mb-2"
                                 onClick={() => showHidePassword(index)}
                              >
                                 {field.type === "password"
                                    ? AppSVG.eyeOpen("")
                                    : AppSVG.eyeClose("")}
                              </span>
                           </div>
                        </div>
                     ))}

                     <div>
                        <Button
                           className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                           disabled={false}
                           id="submit"
                           loading={false}
                           onClick={submit}
                           title={
                              loading ? (
                                 <FaSpinner className="animate-spin text-base text-white" />
                              ) : (
                                 "Submit"
                              )
                           }
                        />
                     </div>
                  </form>

                  <div className="mt-6">
                     <div className="relative mb-2">
                        <div className="absolute inset-0 flex items-center">
                           <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center text-sm">
                           <span className="px-2 bg-white text-gray-500">
                    Already have an account?
                           </span>
                           <Link
                              route={AppRoutes.signin}
                              className="bg-white underline text-blue-500 pr-2"
                              activeClassName=""
                              title="Sign In"
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </DocumentTitle>
   );
};
