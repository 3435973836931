import * as React from 'react';
import { Switch } from '@headlessui/react';

interface ISwitch{
   checked: boolean;
   className?: string;
   onChange: (flag: boolean) => void;
}

export const SelectSwitch:React.FC<ISwitch> = ({ checked, onChange }) => {

   return (
      <Switch
         checked={checked}
         onChange={() => onChange(!checked)}
         className={`
            ${checked ? 'bg-primary' : 'bg-gray-200'} ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer 
            transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary
        `}
      >
         <span
            aria-hidden="true"
            className={`
                inline-block h-5 w-5 rounded-full bg-white shadow ${checked ? 'translate-x-5' : 'translate-x-0'}
                transform ring-0 transition ease-in-out duration-200
            `}
         />
      </Switch>
   );
};