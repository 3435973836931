import {
   isSavedPostRequest,
   isFavoritePostRequest,
   isDeletePostRequest,
} from "Components/Screen/Activate/Redux/Thunk";
import * as React from "react";
import { toast } from "react-toastify";
import { IResponse } from "Utilities/Interface";

const usePostAction = (): [any, any, any] => {
   const savedUnSavedPost = (
      id: string,
      isSaved: boolean,
      callback: (res: IResponse | undefined) => void
   ): void => {
      isSavedPostRequest(id, isSaved, (res: IResponse) => {
         if (res.status) {
            console.log(isSaved);
            showToast(
               res.status,
               `Your post has been ${
                  isSaved ? "added to" : "removed from"
               } saved successfully!`
            );
            callback(res);
         } else {
            showToast(res.status, "Something went wrong!");
            return;
         }
      });
   };

   const favUnFavPost = (
      id: string,
      isFavorite: boolean,
      callback: (res: IResponse | undefined) => void
   ): void => {
      isFavoritePostRequest(id, isFavorite, (res: IResponse) => {
         if (res.status) {
            showToast(
               res.status,
               `Your post has been ${
                  isFavorite ? "added to" : "removed from"
               } favorites successfully!`
            );
            callback(res);
         } else {
            showToast(res.status, "Something went wrong!");
            return;
         }
      });
   };

   const deletePost = (
      id: string,
      callback: (res: IResponse | undefined) => void
   ): void => {
      isDeletePostRequest(id, (res: IResponse) => {
         if (res.status) {
            showToast(res.status, "Your post has been deleted successfully!");
            callback(res);
         } else {
            showToast(res.status, "Something went wrong!");
            return;
         }
      });
   };

   const acceptNotAcceptBuddyPost = (
      postId: string,
      userId: string,
      callback: (res: IResponse | undefined) => void
   ): void => {
      acceptNotAcceptBuddyPost(postId, userId, (res: IResponse) => {
         if (res.status) {
            console.log(res);
            // showToast(
            //    res.status,
            //    `Your post has been ${
            //       isFavorite ? "added to" : "removed from"
            //    } favorites successfully!`
            // );
            callback(res);
         } else {
            showToast(res.status, "Something went wrong!");
            return;
         }
      });
   };

   const showToast = (success: boolean, msg): void => {
      if (success) {
         toast.success(msg);
      } else {
         toast.error(msg);
      }
   };

   return React.useMemo(() => [savedUnSavedPost, favUnFavPost, deletePost], []);
};

export default usePostAction;
