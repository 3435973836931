import React, { FC } from 'react';
import { Label } from 'Components/Theme/FormElements/Label';

interface ICheckBox{
   title: string;
   className: string;
   type: string;
   onChange: (flag: boolean) => void;
   htmlFor: string;
   checked: boolean;
   children?: JSX.Element;
}

export const CheckBox:FC<ICheckBox> = ({ type, className, title, htmlFor, checked, onChange, children }) => {
   return (
      <div className={`flex ${className}`}>
         <input id={htmlFor} name={htmlFor}
            type={type} checked={checked} onChange={(e) => onChange(e.target.checked)}
            className="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" 
         />
         <Label htmlFor={htmlFor} className="ml-3 block text-sm font-medium text-gray-700" title={title} />
         { children ? children : null }
      </div>
   );
};