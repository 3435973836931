import { gql } from '@apollo/client';

export const CREATE_NEW_COMMENT = gql`
  mutation($comments: PostCommentInput!) {
    createPostComment(comments: $comments){
        id
    }
  }
`;

export const GET_ALL_COMMENT_OF_A_POST = gql`
  query($userId: String! $postId: String! $page: Float $limit: Float){
    getPostComments(userId: $userId postId:$postId page: $page limit: $limit){
        id
        post{
          user{
            userProfile{
              profilePhoto
            }
            username
          }
        }
        comment
        created
    }
  }
`;

export const DELETE_A_COMMENT = gql`
  mutation($id: String!) {
    deletePostComment(id: $id)
  }
`;