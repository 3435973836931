import ApiClient from 'Services/ApiClient';
import CommonMethods from 'Utilities/CommonMethods';
import { IResponse } from 'Utilities/Interface';
import { IS_FORGOT_PASSWORD, IS_RESET_PASSWORD } from './Query';

export const isForgotPasswordRequest = (email: string, callback: (response: IResponse) => void): void => {

   const variables = { email };

   ApiClient.sendPostWithAuthWithCallback(variables, IS_FORGOT_PASSWORD, (res) => {

      callback(CommonMethods.responseProcess(true, res.forgotPassword));
      
   }, () => {

      callback(CommonMethods.responseProcess(false, false));

   });

};

export const isResetPasswordRequest = (args: { newPassword: string; oldPassword: string; }, callback: (response: IResponse) => void): void => {

   const variables = { ...args };
 
   ApiClient.sendPostWithAuthWithCallback(variables, IS_RESET_PASSWORD, (res) => {
 
      callback(CommonMethods.responseProcess(true, res.resetPassword));
       
   }, () => {
 
      callback(CommonMethods.responseProcess(false, false));
 
   });
 
};