import React, { FC } from 'react';

import MainLayout from 'HOC/MainLayout/MainLayout';
import Props from './Props';

const Dashboard:FC<Props> = () => {

   return (

      <MainLayout columns={3}>

         <div className="flex flex-col w-full p-2">
            Dashboard
         </div>
         
      </MainLayout>

   );

};

export default Dashboard;