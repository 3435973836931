import React, { FC, useState, useEffect } from "react";

import MainLayout from "HOC/MainLayout/MainLayout";
import Props from "./Props";
import CreatePost from "./CreatePost";
import { IPost } from "Utilities/State.interfaces";
import { LoadData } from "Components/Theme/LoadData/LoadData";
import { IArgs, IResponse } from "Utilities/Interface";
import {
   isPostsRequest,
   isCreateNewPostRequest,
   isLikeUnlikePostRequest,
} from "./Redux/Thunk";
import useFetchDataWithRedux from "Hooks/useFetch/useFetchDataWithRedux";
import { PostTypesEnumerator, UserStatusEnumerator } from "Utilities/Enums";
import { SimplePostItem } from "Components/Theme/PostItem/PostItem";
import ProfileToaster from "./ProfileToaster";
import { FlexColumn } from "Components/Theme/Flex/Flex";
import usePostAction from "Hooks/PostAction/PostAction";
import CommonMethods from "Utilities/CommonMethods";

const Posts: FC<Props> = () => {
   const [user] = useFetchDataWithRedux();
   const [savedUnSavedPost, favUnFavPost, deletePost] = usePostAction();

   const [page, setPage] = useState<number>(1);
   const [search, setSearch] = useState<string>("");
   const [loading, setLoading] = useState<boolean>(true);
   const [posts, setPosts] = useState<IPost[]>([]);

   const [likeCount, setLikeCount] = useState<number[]>([]);

   useEffect(() => {
      getPosts({ page, limit: 10, searchTerm: search }, true);

      return () => {
         setPosts([]);
      };
   }, [page]);

   const getPosts = (args: IArgs, flag: boolean): void => {
      setLoading(flag);

      isPostsRequest(
         { ...args, type: PostTypesEnumerator.SOCIAL },
         (res: IResponse) => {
            setPosts(res.data);
            setLoading(false);
         }
      );
   };

   const createPost = (detailsDesc): void => {
      const details = {
         ...detailsDesc,
         userId: user.id,
         title: user.username,
         type: PostTypesEnumerator.SOCIAL,
         status: UserStatusEnumerator.ACTIVE,
         dateFrom: new Date().getTime(),
         dateTo: new Date("December 30, 2022").getTime(),
      };

      isCreateNewPostRequest(details, (res: IResponse) => {
         if (res.status) {
            getPosts({ page, limit: 3, searchTerm: search }, false);
         }
      });
   };

   const onAction = (postId: string, actionId: number): void => {
      const { isFavorite, isSaved } = posts.find((p) => p.id === postId);
      console.log(isFavorite);
      if (postId && actionId === 1) {
         favUnFavPost(postId, !isFavorite, (res: IResponse) => {
            setPosts([...CommonMethods.modifyPostObject(res?.data, posts)]);
         });
      }

      if (postId && actionId === 2) {
         savedUnSavedPost(postId, !isSaved, (res: IResponse) => {
            setPosts([...CommonMethods.modifyPostObject(res?.data, posts)]);
         });
      }

      if (postId && actionId === 4) {
         deletePost(postId, (res: IResponse) => {
            if (res?.status) getPosts({ searchTerm: search }, false);
         });
      }
   };

   const likePost = (postId: string, userId: string) => {
      isLikeUnlikePostRequest(postId, userId, (res: IResponse) => {
         setPosts([...CommonMethods.modifyPostObject(res?.data, posts)]);
      });
   };

   return (
      <MainLayout columns={3} docTitle="Posts" className="">
         <FlexColumn className="w-full">
            <ProfileToaster condition={user?.userProfile} />

            <CreatePost
               createNewPost={(details) => createPost(details)}
               userName={user?.username}
               profileImage={user?.userProfile?.profilePhoto}
            />

            <FlexColumn className="w-full mb-8 pt-1">
               <LoadData loading={loading} arr={posts} msg="No posts available">
                  <React.Fragment>
                     {posts.map((post, index) => (
                        <SimplePostItem
                           post={post}
                           onAction={(postId: string, actionId: number) =>
                              onAction(postId, actionId)
                           }
                           postLikes={post?.likes?.length ? post?.likes?.length : ""}
                           likePost={() => likePost(post?.id, user?.id)}
                           activeUser={user}
                           key={index}
                        />
                     ))}
                  </React.Fragment>
               </LoadData>
            </FlexColumn>
         </FlexColumn>
      </MainLayout>
   );
};

export default Posts;
