import React, { FC, useEffect, useState } from "react";
import { FindWithSearch } from "Components/Theme/FindWithSearch/FindWithSearch";
import Props from "./Props";
import TabsNavigation from "Components/Theme/TabNavigation/TabNavigation";
import { LoadData } from "Components/Theme/LoadData/LoadData";
import { clubsTabs } from "Utilities/Data";
import { IResponse, IArgs, ITab } from "Utilities/Interface";

import MainLayout from "HOC/MainLayout/MainLayout";
import { isGolfCoursesRequest } from "./Redux/Thunk";
import { FlexColumn, Grid, Image } from "Components/Theme/Flex/Flex";
import CityCountryDetails from "../Modals/CityCountryDetails";
import { Assets } from "Assets/Assets";
import { IGolfCourse } from "Utilities/State.interfaces";
import { Pagination } from "Components/Theme/Pagination/Pagination";
import { useNavigate } from "react-router";

const GolfClubs: FC<Props> = () => {
   const [activeTab, setActiveTab] = useState<ITab>(clubsTabs[0]);
   const [page, setPage] = useState<number>(1);
   const [search, setSearch] = useState<string>("");
   const [loading, setLoading] = useState<boolean>(false);
   const [golfCourses, setGolfCourses] = useState<IGolfCourse[]>([]);

   const navigate = useNavigate();

   const [detail, setDetail] = useState<{
      courseId: string;
      isOpen: boolean;
   }>(undefined);

   useEffect(() => {
      getCourses({ page, searchTerm: search, limit: 50 });
   }, [page]);

   const getCourses = (args: IArgs): void => {
      setLoading(true);

      if (!args.searchTerm) {
         delete args.searchTerm;
      }

      isGolfCoursesRequest(args, (res: IResponse) => {
         setGolfCourses(res.data);
         setLoading(false);
      });
   };

   return (
      <MainLayout columns={3} docTitle="Golf Clubs">
         <FlexColumn className="w-full">
            <FindWithSearch
               search={search}
               setSearch={setSearch}
               pressEnter={(val) => {
                  setSearch(val);
                  getCourses({ page, searchTerm: val, limit: 50 });
               }}
               heading="Golf Clubs"
               subHeading="Find your desired golf club"
            />

            <TabsNavigation
               tabs={clubsTabs}
               active={activeTab?.id}
               onClick={(tab) => {
                  setActiveTab(tab);
               }}
            />

            <LoadData
               loading={loading}
               msg="No data available."
               arr={golfCourses}
               className=""
            >
               <Grid className="px-0 gap-4 grid-cols-3  sm:grid-cols-3  md:grid-cols-4 lg:grid-cols-4 mb-1 overflow-y-auto pr-1">
                  {golfCourses.map((club: IGolfCourse) => {
                     return (
                        <div
                           className="rounded-lg relative mb-3 cursor-pointer h-auto col-span-1"
                           key={club.id}
                           onClick={() => navigate(club.id)}
                           // onClick={() => setDetail({
                           //    isOpen: true, courseId: club.id
                           // })}
                        >
                           <Image
                              src={club.image ?? Assets.emptySrc}
                              defaultSrc={club.image ?? Assets.emptySrc}
                              alt={club.name}
                              className="w-full h-full object-center object-cover rounded"
                           />
                           <label
                              htmlFor="desktop-user-photo"
                              className="absolute inset-0 duration-150 cursor-pointer transition-all w-full h-full bg-black bg-opacity-75 flex items-center justify-center text-sm font-medium text-white opacity-0 hover:opacity-100 focus-within:opacity-100"
                           >
                              <span>{club.name}</span>
                           </label>
                        </div>
                     );
                  })}
               </Grid>
            </LoadData>
            <Pagination
               currentPage={page}
               range={{ from: page * 50 - 50 + 1, to: page * 50 }}
               onChange={(id) => {
                  if (id === 1) {
                     setPage((page) => page - 1);
                  } else {
                     setPage((page) => page + 1);
                  }
               }}
            />

            {detail?.isOpen && (
               <CityCountryDetails
                  isOpen={detail?.isOpen ?? false}
                  courseId={detail?.courseId}
                  onClose={() => setDetail(undefined)}
               />
            )}
         </FlexColumn>
      </MainLayout>
   );
};

export default GolfClubs;
