import React, { FC } from "react";
import Props from "./Props";

const TabsNavigation: FC<Props> = ({ tabs, active, onClick, className }) => {
   return (
      <div className={`mt-6 mb-8 ${className}`}>
         <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
          Select a tab
            </label>
            <select
               id="tabs"
               name="tabs"
               value={tabs.find((t) => t.id === active)?.name}
               onChange={() => console.log()}
               className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
            >
               {tabs?.map((item) => {
                  if (!item.disbaled)
                     return <option value={item?.name}>{item?.name}</option>;
                  else return null;
               })}
            </select>
         </div>
         <div className="hidden sm:block">
            <div className="border-b border-gray-200">
               <nav
                  className="-mb-px flex sm:flex sm:flex-row sm:justify-between md:flex-row"
                  aria-label="Tabs"
               >
                  {tabs.map((tab, i) => (
                     <span
                        key={i}
                        onClick={() => onClick(tab)}
                        className={
                           tab.id !== active
                              ? `border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-1/5  md:w-1/5 py-2 px-1 cursor-pointer 
                              text-center border-b-2 font-medium text-sm  ${
                     tab?.disbaled &&
                                "pointer-events-none text-gray-500"
                     }`
                              : "border-primary text-primary w-1/5 py-2 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
                        }
                     >
                        {" "}
                        {tab.name}{" "}
                     </span>
                  ))}
               </nav>
            </div>
         </div>
      </div>
   );
};

export default TabsNavigation;
