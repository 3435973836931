const ACCESS_TOKEN_KEY = 'authenticationAccessToken',
   REFRESH_TOKEN_KEY = 'authenticationRefreshToken';

class TokenService {
   public getAccessToken(): string {
      return localStorage.getItem(ACCESS_TOKEN_KEY);
   }

   public setAccessToken(token: string): void {
      localStorage.setItem(ACCESS_TOKEN_KEY, token);
   }

   public getRefreshToken(): string {
      return localStorage.getItem(REFRESH_TOKEN_KEY);
   }

   public setRefreshToken(token: string): void {
      localStorage.setItem(REFRESH_TOKEN_KEY, token);
   }

   public clearTokens(): void {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);

      const myTheme = localStorage.getItem('my-theme');

      // localStorage.clear();
      localStorage.removeItem('my-theme');
      localStorage.removeItem('authenticationAccessToken');
      localStorage.removeItem('authenticationRefreshToken');
      localStorage.removeItem('i18nextLng');

      localStorage.setItem('my-theme', myTheme);

      sessionStorage.clear();
   }

   public logoutWithToken(): void {
      this.clearTokens();
   }
}

class UserService {
   public getMyUserId(): string {
      return localStorage.getItem(ACCESS_TOKEN_KEY)
         ? JSON.parse(atob(localStorage.getItem(ACCESS_TOKEN_KEY).split('.')[1])).id
         : undefined;
   }
}

export const tokenService: TokenService = new TokenService();

export const userService: UserService = new UserService();
