import * as React from "react";
import { FlexColumn, FlexRow } from "Components/Theme/Flex/Flex";
interface IPageHeader {
   subTitle?: string;
   title?: string;
}
export const PageHeading: React.FC<IPageHeader> = ({ title, subTitle }) => {
   return (
      <FlexRow className="items-center mb-6 justify-between">
         <FlexColumn className="w-auto">
            <h3 className="text-xl leading-6 font-medium text-black"> {title} </h3>
         </FlexColumn>
      </FlexRow>
   );
};
