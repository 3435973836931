import { FlexColumn, FlexRow } from "Components/Theme/Flex/Flex";
import React, { FC, useRef, useState, useEffect } from "react";
import IComment from "../Props";
import { toast } from "react-toastify";
import useAutosizeTextArea from "../useAutoSizeTextArea";
import { createAComment, getAllCommentsOfSinglePost } from "../Services/thunk";
import { IResponse } from "Utilities/Interface";
import {
   PostCommentTypesEnumerator,
   PostTypesEnumerator,
} from "Utilities/Enums";
import { FaSpinner } from "react-icons/fa";

interface IAddAComment {
   placeHolder: string;
   postId: string;
   userId: string;
   getAllComments: (postId: string, userId: string) => void;
}
const AddAComment: FC<IAddAComment> = ({
   placeHolder,
   postId,
   userId,
   getAllComments,
}) => {
   const ref = useRef<HTMLTextAreaElement>(null);
   const [comment, setComment] = useState<string>(undefined);
   const [isLoading, setIsLoading] = useState<boolean>(false);
   useAutosizeTextArea(ref.current, comment);

   const submitComment = (e): void => {
      const comments = {
         userId,
         postId,
         comment,
         type: PostCommentTypesEnumerator.TYPE,
         status: PostCommentTypesEnumerator.STATUS,
      };

      if (
         (comment && !e.key) ||
      (comment && (e.ctrlKey || e.metaKey) && e.key == "Enter")
      ) {
         setIsLoading(true);
         createAComment(comments, (res: IResponse) => {
            if (res.status) {
               toast.success("Your Comment Has Been Added");
               setComment("");
               setIsLoading(false);

               getAllCommentsOfSinglePost(
                  { postId, userId, page: 1, limit: 50 },
                  (res) => {
                     getAllComments(postId, userId);
                  }
               );
            }
         });
      }
   };

   return (
      <FlexColumn className={"w-full"}>
         <FlexRow
            className={`${
               placeHolder === "Reply..."
                  ? "w-full rounded mt-3 ml-[10px]"
                  : "p-3 w-full rounded"
            } `}
         >
            <textarea
               ref={ref}
               placeholder={placeHolder}
               value={comment}
               onChange={(e) => setComment(e.target.value)}
               rows={1}
               tabIndex={0}
               onKeyDown={(e) => submitComment(e)}
               className={`${
                  placeHolder === "Reply..." ? "mr-3" : ""
               } rounded-xl py-1 px-3 pl-3 pr-8 mb-2 bg-transparent w-full text-black resize-none border border-gray-400 text-sm
                active:ring-0 focus:ring-0 comment-input focus:border-primary active:border-primary overflow-hidden`}
            ></textarea>
            {!isLoading && (
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  width={"16px"}
                  className="text-sm absolute rotate-45 transform hover:cursor-pointer active:ring-0 focus:ring-0 comment-input hover:fill-green-300"
                  style={
                     placeHolder === "Reply..."
                        ? { right: 26, top: 16 }
                        : { right: 36, top: 16 }
                  }
                  onClick={(e) => submitComment(e)}
               >
                  <path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z" />
               </svg>
            )}

            {isLoading && (
               <FaSpinner
                  style={
                     placeHolder === "Reply..."
                        ? { right: 26, top: 16 }
                        : { right: 36, top: 16 }
                  }
                  className="animate-spin text-black text-sm absolute comment-input"
               />
            )}
         </FlexRow>
         {placeHolder === "Add a comment ..." && (
            <div className="ml-4 -mt-3 text-xs text-gray-400">
          Press ctrl + enter to send
            </div>
         )}
      </FlexColumn>
   );
};

export default AddAComment;
