class SVG {
   public promotBadgeSvg = (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         className="text-black group-hover:text-gray-500 flex-shrink-0 h-6 w-6"
         fill="none"
         viewBox="0 0 24 24"
         stroke="currentColor"
         strokeWidth="1"
      >
         <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
         />
      </svg>
   );

   public starSvg = (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         className="text-black group-hover:text-gray-500 flex-shrink-0 h-6 w-6"
         fill="none"
         viewBox="0 0 24 24"
         stroke="currentColor"
         strokeWidth="1"
      >
         <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
         />
      </svg>
   );

   public facebookSvg = (
      <svg
         className="w-5 h-5"
         aria-hidden="true"
         fill="currentColor"
         viewBox="0 0 20 20"
      >
         <path
            fillRule="evenodd"
            d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
            clipRule="evenodd"
         />
      </svg>
   );

   public twitterSvg = (
      <svg
         className="w-5 h-5"
         aria-hidden="true"
         fill="currentColor"
         viewBox="0 0 20 20"
      >
         <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
      </svg>
   );

   public githubSvg = (
      <svg
         className="w-5 h-5"
         aria-hidden="true"
         fill="currentColor"
         viewBox="0 0 20 20"
      >
         <path
            fillRule="evenodd"
            d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
            clipRule="evenodd"
         />
      </svg>
   );

   public notificationSvg = (
      <svg
         className="h-6 w-6 block"
         xmlns="http://www.w3.org/2000/svg"
         fill="none"
         viewBox="0 0 24 24"
         stroke="currentColor"
         aria-hidden="true"
      >
         <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
         />
      </svg>
   );

   public settingSvg = (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         className="h-6 w-6 block"
         fill="none"
         aria-hidden="true"
         viewBox="0 0 24 24"
         stroke="currentColor"
         strokeWidth="2"
      >
         <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
         />
         <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
         />
      </svg>
   );

   public userCircleSvg = (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         className="h-6 w-6"
         fill="none"
         viewBox="0 0 24 24"
         stroke="currentColor"
         strokeWidth="2"
      >
         <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
         />
      </svg>
   );

   public securitySvg = (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         className="h-6 w-6"
         fill="none"
         viewBox="0 0 24 24"
         stroke="currentColor"
         strokeWidth="2"
      >
         <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
         />
      </svg>
   );

   public privacySvg = (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         className="h-6 w-6"
         fill="none"
         viewBox="0 0 24 24"
         stroke="currentColor"
         strokeWidth="2"
      >
         <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
         />
      </svg>
   );

   public locationSvg = (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         className="h-6 w-6"
         fill="none"
         viewBox="0 0 24 24"
         stroke="currentColor"
         strokeWidth="2"
      >
         <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
         />
      </svg>
   );

   public languageSvg = (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         className="h-6 w-6"
         fill="none"
         viewBox="0 0 24 24"
         stroke="currentColor"
         strokeWidth="2"
      >
         <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129"
         />
      </svg>
   );

   public blockedSvg = (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         className="h-6 w-6"
         viewBox="0 0 20 20"
         fill="currentColor"
      >
         <path
            fillRule="evenodd"
            d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
            clipRule="evenodd"
         />
      </svg>
   );

   public progressStep = (
      <svg
         className="w-5 h-5 text-white"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 20 20"
         fill="currentColor"
         aria-hidden="true"
      >
         <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
         />
      </svg>
   );

   public userAvatar = (
      <svg
         className="h-full w-full text-gray-300"
         fill="currentColor"
         viewBox="0 0 24 24"
      >
         <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
   );

   public billingSvg = (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         className="h-6 w-6"
         fill="none"
         viewBox="0 0 24 24"
         stroke="currentColor"
         strokeWidth="2"
      >
         <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
         />
      </svg>
   );

   public ellipsisHorizontal = (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         className="h-5 w-5 text-gray-400"
         viewBox="0 0 20 20"
         fill="currentColor"
      >
         <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
      </svg>
   );

   public ellipsisVertical = (
      <svg
         className="h-5 w-5"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 20 20"
         fill="currentColor"
         aria-hidden="true"
      >
         <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
      </svg>
   );

   public dateSelectionSvg = (fill: string) => {
      return (
         <svg
            className="text-gray-300 flex-shrink-0 h-5 w-5 sm:-ml-1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill={`${fill}`}
            aria-hidden="true"
         >
            <path
               fillRule="evenodd"
               d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
               clipRule="evenodd"
            />
         </svg>
      );
   };

   public labledSvg = (
      <svg
         className="text-gray-300 flex-shrink-0 h-5 w-5 sm:-ml-1"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 20 20"
         fill="currentColor"
         aria-hidden="true"
      >
         <path
            fillRule="evenodd"
            d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
            clipRule="evenodd"
         />
      </svg>
   );

   public avatarPictureSvg = (
      <svg
         className="flex-shrink-0 h-5 w-5 text-gray-300 sm:-ml-1"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 20 20"
         fill="currentColor"
         aria-hidden="true"
      >
         <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
            clipRule="evenodd"
         />
      </svg>
   );

   public eyeOpen = (className?: string) => (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         className={`h-5 w-5 text-gray-400 cursor-pointer ${className}`}
         viewBox="0 0 20 20"
         fill="currentColor"
      >
         <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
         <path
            fillRule="evenodd"
            d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
            clipRule="evenodd"
         />
      </svg>
   );

   public eyeClose = (className?: string) => (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         className={`h-5 w-5 text-gray-400 cursor-pointer ${className}`}
         viewBox="0 0 20 20"
         fill="currentColor"
      >
         <path
            fillRule="evenodd"
            d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
            clipRule="evenodd"
         />
         <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
      </svg>
   );

   public close = (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         className="h-5 w-5 text-black"
         viewBox="0 0 20 20"
         fill="currentColor"
      >
         <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
         />
      </svg>
   );

   public globe = (fill: string) => {
      return (
         <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-300"
            fill={`${fill}`}
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
         >
            <path
               strokeLinecap="round"
               strokeLinejoin="round"
               d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
         </svg>
      );
   };

   public envelop = (className: string) => {
      return (
         <svg
            className={`${className}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
         >
            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
         </svg>
      );
   };

   public attachment = (
      <svg
         className="-ml-1 h-5 w-5 mr-2 group-hover:text-gray-500"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 20 20"
         fill="currentColor"
         aria-hidden="true"
      >
         <path
            fillRule="evenodd"
            d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
            clipRule="evenodd"
         />
      </svg>
   );

   public FavoriteSvg = (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         version="1.1"
         width="512px"
         height="512px"
         x="0"
         y="0"
         viewBox="0 0 24 24"
         className="h-4 w-4 mr-1"
      >
         <g>
            <path
               d="m19.1601 18.3853.516 2.0703c.4337 1.7401-1.4275 3.1703-2.9964 2.2711l-4.6597-2.6708c-.0125-.0072-.0275-.0072-.04 0l-4.65971 2.6708c-1.56888.8992-3.43008-.531-2.99637-2.2711l.516-2.0703 4.65364-2.6673c1.55264-.8899 3.46024-.8899 5.01284 0z"
               fill="#b8d4cc"
               data-original="#c4e6ff"
            />
            <g clip-rule="evenodd" fill-rule="evenodd">
               <path
                  d="m10.0871 2.32841c.6622-1.771214 3.1637-1.771213 3.8258 0l1.9303 5.16309c.0063.01676.0204.02668.0346.02847l5.3321.66855c1.7767.22276 2.4227 2.45748 1.0551 3.60058l-3.9078 3.2665c-.0129.0108-.0197.0294-.015.048l1.3339 5.352c.4337 1.7401-1.4275 3.1703-2.9964 2.2711l-4.6597-2.6708c-.0125-.0072-.0275-.0072-.04 0l-4.65968 2.6708c-1.56888.8992-3.43008-.531-2.99637-2.2711l1.33394-5.352c.00462-.0186-.0021-.0372-.01504-.048l-3.90783-3.2665c-1.367573-1.1431-.72156-3.37782 1.05508-3.60058l5.3321-.66855c.01429-.00179.02837-.01171.03464-.02847zm1.8844.67836c-.0005.00067-.0053.00657-.011.02201l-1.9303 5.16309c-.26593.71131-.90424 1.21791-1.65918 1.31256l-5.33211.66857c-.01325.0016-.01886.0042-.01962.0046-.00103.0004-.00144.0007-.00212.0013-.00212.0019-.00937.01-.01401.0259-.00465.0159-.00303.0271-.00203.0307.00037.0013.00075.0023.00164.0036.00073.0012.00437.0067.01492.0155l3.90783 3.2665c.60185.5031.86248 1.3059.673 2.0661l-1.33394 5.3521c-.00351.0141-.00319.0212-.0031.0228.00012.0018.00038.0028.00082.004.00124.0033.00631.0128.01889.0224.01258.0095.02236.0113.02422.0115.00017-.0001.00122-.0001.00152-.0002.00064-.0001.00681-.0014.01883-.0083l4.65974-2.6708c.6285-.3603 1.4005-.3603 2.0291 0l4.6597 2.6708c.012.0069.0182.0082.0188.0083.0009.0002.0011.0002.0015.0002.0018-.0002.0117-.002.0243-.0115.0125-.0096.0176-.0191.0188-.0224.0005-.0012.0007-.0022.0009-.004.0001-.0016.0004-.0087-.0031-.0228l-1.334-5.3521c-.1895-.7602.0712-1.563.673-2.0661l3.9079-3.2665c.0105-.0088.0141-.0143.0149-.0155.0009-.0013.0012-.0023.0016-.0036.001-.0036.0026-.0148-.002-.0307-.0047-.0159-.0119-.024-.014-.0259-.0007-.0006-.0011-.0009-.0021-.0013-.0008-.0004-.0064-.003-.0197-.0046l-5.3321-.66857c-.7549-.09465-1.3932-.60125-1.6592-1.31256l-1.9302-5.16309c-.0058-.01555-.0106-.02142-.0111-.02202-.0005-.00071-.0006-.00077-.0008-.00084-.0012-.00083-.0106-.00592-.0277-.00592-.017 0-.0264.00505-.0276.00589-.0002.0001-.0002.00004-.0009.00088z"
                  fill="#26394c"
                  data-original="#024493"
               />
               <path
                  d="m12.6261 1.74194c.5173-.19341 1.0934.06918 1.2869.58649l1.9302 5.16309c.0063.01676.0204.02668.0347.02847l5.3321.66855c1.7766.22276 2.4226 2.45746 1.055 3.60066l-3.9078 3.2664c-.0129.0108-.0197.0294-.015.048l1.3339 5.352c.4337 1.7402-1.4275 3.1703-2.9964 2.2711l-4.6597-2.6708c-.4791-.2747-.6449-.8857-.3703-1.3649.2747-.4791.8857-.6449 1.3649-.3703l4.6597 2.6708c.012.0069.0182.0082.0188.0084.0009.0001.0011.0001.0015.0001.0019-.0002.0117-.0019.0243-.0115.0125-.0096.0176-.0191.0188-.0224.0003-.0006.0005-.0013.0006-.002.0001-.0006.0002-.0012.0003-.002.0001-.0016.0004-.0087-.0031-.0228l-1.334-5.352c-.1894-.7603.0712-1.5631.673-2.0662l3.9079-3.2665c.0105-.0088.0142-.0143.0149-.0154.0009-.0014.0013-.0023.0016-.0037.001-.0036.0026-.0148-.002-.0307-.0047-.0159-.0119-.0239-.014-.0258-.0005-.0004-.0008-.0007-.0013-.001-.0002-.0001-.0005-.0003-.0008-.0004-.0008-.0004-.0064-.0029-.0197-.0046l-5.3321-.66855c-.7549-.09465-1.3932-.60125-1.6592-1.31256l-1.9302-5.16309c-.1934-.51731.0692-1.09346.5865-1.28686z"
                  fill="#00815e"
                  data-original="#1e93ff"
               />
            </g>
         </g>
      </svg>
   );

   public SavedSvg = (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         version="1.1"
         width="512px"
         height="512px"
         x="0"
         y="0"
         className="h-4 w-4 mr-1"
         viewBox="0 0 24 24"
      >
         <g>
            <g clip-rule="evenodd" fill-rule="evenodd">
               <path
                  d="m9 4c0-1.65685 1.3431-3 3-3h6v16l-1.5294-.8157c-.2941-.1568-.6471-.1568-.9412 0l-.5294.2824v-8.4667c0-1.65685-1.3431-3-3-3h-3z"
                  fill="#b8d4cc"
                  data-original="#c4e6ff"
               />
               <path
                  d="m9 4c0-1.65685 1.3431-3 3-3h8c1.6569 0 3 1.34315 3 3v14c0 .3513-.1843.6768-.4855.8575s-.6752.1902-.9851.0249l-5.0588-2.6981c-.2941-.1568-.6471-.1568-.9412 0l-.5294.2824v-2.2285c.7872-.2784 1.663-.2179 2.4118.1814l3.5882 1.9137v-12.3333c0-.55228-.4477-1-1-1h-8c-.5523 0-1 .44772-1 1v1h-2z"
                  fill="#26394c"
                  data-original="#024493"
               />
               <path
                  d="m1 8c0-1.65685 1.34315-3 3-3h8c1.6569 0 3 1.34315 3 3v14c0 .3513-.1843.6768-.4855.8575s-.6752.1902-.9851.0249l-5.05881-2.6981c-.29412-.1568-.64706-.1568-.94118 0l-5.05882 2.6981c-.30994.1653-.68388.1558-.98509-.0249-.3012-.1807-.4855-.5062-.4855-.8575zm3-1c-.55228 0-1 .44772-1 1v12.3333l3.58824-1.9137c.88235-.4706 1.94117-.4706 2.82352 0l3.58824 1.9137v-12.3333c0-.55228-.4477-1-1-1z"
                  fill="#00815e"
                  data-original="#1e93ff"
               />
            </g>
         </g>
      </svg>
   );
}

const AppSVG = new SVG();
export default AppSVG;
