import ApiClient from 'Services/ApiClient';
import CommonMethods from 'Utilities/CommonMethods';
import { IArgs, IResponse } from 'Utilities/Interface';
import { GET_ALL_USERS } from './Query';

export const isUsersRequest = (args: IArgs, callback: (response: IResponse) => void): void => {

   const variables = { ...args };

   ApiClient.sendGetWithAuthWithCallback(variables, GET_ALL_USERS, (res) => {

      callback(CommonMethods.responseProcess(true, res.data.getUsers));
      
   }, () => {

      callback(CommonMethods.responseProcess(false, []));

   });
 
};