import { gql } from '@apollo/client';
import { CityFragment, CountryFragment, GolfCourseFragment } from 'Apollo/Fragments';

export const GET_ALL_GOLF_COURSES = gql`
  query($page: Float $limit: Float $searchTerm: String){
    getAllGolfCourses(page: $page limit: $limit searchTerm: $searchTerm){
        id
        ...GolfCourseFragment
    }
  }
  ${GolfCourseFragment}
`;

export const GET_ALL_COUNTRIES = gql`
  query($page: Float $limit: Float $searchTerm: String){
    getAllCountries(page: $page limit: $limit searchTerm: $searchTerm){
        id
        ...CountryFragment
    }
  }
  ${CountryFragment}
`;

export const GET_ALL_CITIES = gql`
  query($page: Float $limit: Float $searchTerm: String){
    getAllCities(page: $page limit: $limit searchTerm: $searchTerm){
        id
        countryId
        ...CityFragment
    }
  }
  ${CityFragment}
`;

export const GET_A_GOLF_COURSE = gql`
  query($id: String!){
    getAGolfCourse(id: $id){
        id
        ...GolfCourseFragment
    }
  }
  ${GolfCourseFragment}
`;

export const GET_A_COUNTRY = gql`
  query($id: String!){
    getACountry(id: $id){
        id
        ...CountryFragment
    }
  }
  ${CountryFragment}
`;

export const GET_A_CITY = gql`
  query($id: String!){
    getACity(id: $id){
        id
        countryId
        ...CityFragment
    }
  }
  ${CityFragment}
`;