import ApiClient from 'Services/ApiClient';
import CommonMethods from 'Utilities/CommonMethods';
import { IResponse } from 'Utilities/Interface';
import { IAction } from 'Utilities/State.interfaces';
import { isUpdateProfileAction } from './Action';
import { IS_CREATE_USER_DETAIL_QUERY, IS_GET_USER_DETAIL_QUERY, IS_UPDATE_USER_DETAIL_QUERY, IS_GET_CITIES_BY_COUNTRY, IS_GET_BUDDY_POST_AVATAR_QUERY, IS_GET_BUDDY_POSTS_QUERY } from './Query';

export const isGetUserProfileRequest = (args: { userProfileId?: string, userId?: string }) => async (dispatch: (arg0: IAction) => void) => {
   if(args?.userId || args?.userProfileId){
      const variables = { ...args };

      ApiClient.sendGetWithAuthWithCallback(variables, IS_GET_USER_DETAIL_QUERY, (res) => {
   
         dispatch(isUpdateProfileAction(res.data.getUserProfile));
         
      }, (err) => {         
         console.log(err);
      });
   } else {
      dispatch(isUpdateProfileAction(undefined));
   }

};

export const isGetUserProfileRequestViaCallback = (args: { userProfileId?: string, userId?: string }, callback?): void => {

   const variables = { ...args };

   ApiClient.sendGetWithAuthWithCallback(variables, IS_GET_USER_DETAIL_QUERY, (res) => {
   
      callback(CommonMethods.responseProcess(true, res.data.getUserProfile));
         
   }, (err) => {
         
      callback(CommonMethods.responseProcess(false, undefined));
   
   });

};

export const isGetBuddyPostAvatarRequestViaCallback = (args: { postId?: string }, callback?): void => {

   const variables = { ...args };

   ApiClient.sendGetWithAuthWithCallback(variables, IS_GET_BUDDY_POST_AVATAR_QUERY, (res) => {
   
      callback(CommonMethods.responseProcess(true, res.data.getUserAvatarsByBuddyPost));
         
   }, (err) => {
         
      callback(CommonMethods.responseProcess(false, undefined));
   
   });

};

export const isGetCitiesByCountryRequest = (args: { countryId: string }, callback?): void => {

   const variables = { ...args };

   ApiClient.sendGetWithAuthWithCallback(variables, IS_GET_CITIES_BY_COUNTRY, (res) => {
   
      callback(CommonMethods.responseProcess(true, res.data.getCitiesByCountry));
         
   }, (err) => {
         
      console.log(err);
      callback(CommonMethods.responseProcess(false, []));
   
   });

};

export const isCreateProfileRequest = (details, callback: (response: IResponse) => void) => async (dispatch: (arg0) => void) => {

   const variables = { details };

   ApiClient.sendPostWithAuthWithCallback(variables, IS_CREATE_USER_DETAIL_QUERY, (res) => {

      setTimeout(() => {
         dispatch(isGetUserProfileRequest({ userId: details?.userId, userProfileId: res.data.addUserDetail?.id }));
      }, 1000);
      callback(CommonMethods.responseProcess(true, res.data.addUserDetail));
      
   }, (err) => {
      
      console.log(err);
      callback(CommonMethods.responseProcess(false, undefined));

   });

};

export const isUpdateProfileRequest = (id: string, details, callback: (response: IResponse) => void) => async (dispatch: (arg0) => void) => {

   const variables = { id, details };
 
   ApiClient.sendPostWithAuthWithCallback(variables, IS_UPDATE_USER_DETAIL_QUERY, (res) => {
 
      dispatch(isGetUserProfileRequest({ userId: details?.user?.id, userProfileId: id }));
      callback(CommonMethods.responseProcess(true, res.data.updateUserDetail));
       
   }, (err) => {
       
      callback(CommonMethods.responseProcess(false, err?.message));
 
   });
 
};

export const isGetBuddyPostRequestViaCallback = (args: { userId?: string }, callback?): void => {

   const variables = { ...args };

   ApiClient.sendGetWithAuthWithCallback(variables, IS_GET_BUDDY_POSTS_QUERY, (res) => {
   
      callback(CommonMethods.responseProcess(true, res?.data?.getUserAcceptedBuddyPosts));
         
   }, (err) => {
         
      callback(CommonMethods.responseProcess(false, undefined));
   
   });

};