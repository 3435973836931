const S3_BUCKET ='golfguiders-user-profile';
const S3_BUCKET_REGION ='us';
const S3_BUCKET_ACCESS_KEY ='CHFKXM0DMHR2Q7U2RJXX';
const S3_BUCKET_SECRET_ACCESS_KEY ='08sfUS3j0mBK8BHAo6XatehnjcwAOR4860ZxwmVU';

export const FE_BASE_URL = "https://app.golfvez.net";

export const S3BucketConfig = {
   bucketName: S3_BUCKET,
   region: S3_BUCKET_REGION,
   accessKeyId: S3_BUCKET_ACCESS_KEY,
   secretAccessKey: S3_BUCKET_SECRET_ACCESS_KEY,
};

export const Constants = {
   GOOGLE_API_KEY: 'AIzaSyAO_UXdA0VImc1EMVeh0A34ZsRvCMVJRt8'
};

export const Classes = {
   basicButton: 'py-2 inline-flex items-center justify-center cursor-pointer border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary'
};
