import * as React from 'react';
import { FlexColumn, FlexRow } from '../Flex/Flex';

interface ICheckPermission {
   condition: boolean;
   children?: JSX.Element
}

export const CheckPermission:React.FC<ICheckPermission> = ({ condition, children }) => {
   
   if(condition){
      return <FlexColumn className="w-full">
         { children }
      </FlexColumn>;
   }
    
   return (
      <FlexRow className="w-full py-2 text-sm text-red-600 font-medium">
            You don't have permission.
      </FlexRow>
   );
};