import { gql } from '@apollo/client';
import { UserProfileFragment, CityFragment, PostFragment } from 'Apollo/Fragments';

export const IS_CREATE_USER_DETAIL_QUERY = gql`
  mutation($details: InputUserProfile!) {
    addUserDetail(details: $details){
        id        
    }
  }
`;

export const IS_UPDATE_USER_DETAIL_QUERY = gql`
  mutation($id: String! $details: UpdateUserProfile!) {
    updateUserDetail(id: $id details: $details){
        id       
    }
  }
`;

export const IS_GET_USER_DETAIL_QUERY = gql`
  query($userProfileId: String $userId: String) {
    getUserProfile(userProfileId: $userProfileId userId: $userId){
        id        
        ...UserProfileFragment
    }
  }
  ${UserProfileFragment}
`;

export const IS_GET_CITIES_BY_COUNTRY = gql`
  query($countryId: String!) {
    getCitiesByCountry(countryId: $countryId){
        id        
        ...CityFragment
    }
  }
  ${CityFragment}
`;

export const IS_GET_BUDDY_POST_AVATAR_QUERY = gql`
  query($postId: String!) {
    getUserAvatarsByBuddyPost(postId: $postId){
        username
        userAvatar   
        userId
        postId   
    }
  }
`;

export const IS_GET_BUDDY_POSTS_QUERY = gql`
  query($userId: String!) {
    getUserAcceptedBuddyPosts(userId: $userId){
        id
        isUsersAccepted
        
        post{
          user{
            username
            userProfile{
              firstname
              lastname
              profilePhoto
            }
          } 
          id
          userId
          likes
          description
          address
          title
          created
        }   
    }
  }
`;