import React, { FC } from 'react';
import Props from './Props';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { SettingContainer } from 'Components/Screen/UserSettings/sub-components/SettingContainer/SettingContainer';
import { ISimpleMenu } from 'Utilities/Interface';
import { SelectOption } from 'Components/Theme/FormElements/Select';
import { SelectWithSearch } from 'Components/Theme/FormElements/SelectWithSearch';

export const Language:FC<Props> = () => {

   const [values, setValues] = React.useState<{ language: ISimpleMenu, date: any, timezone: ISimpleMenu } | undefined>({ 
      date: new Date(), language: { id: '', name: 'English' }, timezone: undefined 
   });

   const isSave = (): void => {
      console.log();
   };

   return (
      
      <SettingContainer title="Language">

         <div className="mt-3">

            <dl className="divide-y divide-gray-200">
                            
               <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Language:</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                     <span className="flex-grow">
                        <SelectOption
                           className="max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border border-gray-300 rounded-md"
                           options={[{ id: '1', name: 'English' }]} 
                           setSelected={(obj) => setValues({ ...values, language: obj })} selected={values?.language}                  
                        />
                     </span>
                     <span className="ml-4 flex-shrink-0">
                       
                     </span>
                  </dd>
               </div>

               <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5 z-50">
                  <dt className="text-sm font-medium text-gray-500">Date format:</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                     <span className="flex-grow">
                        <DatePicker
                           className="max-w-lg block w-full z-50 shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                           dateFormat="MM/dd/yyyy"
                           selected={values.date}
                           disabled={true}
                           onChange={(date) => setValues({ ...values, date })}
                        />
                     </span>
                     <span className="ml-4 flex-shrink-0 flex items-start space-x-4">
                        
                     </span>
                  </dd>
               </div>

               <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 hidden d-none">
                  <dt className="text-sm font-medium text-gray-500">Automatic timezone:</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                     <span className="flex-grow">
                        America/Los_Angeles
                     </span>
                  </dd>
               </div>
                            
            </dl>

            <div className="divide-y divide-gray-200 mb-4 w-full"></div>
            <button onClick={isSave} className="hidden px-6 py-2 text-sm bg-primary text-white rounded-md float-right ">
                  Save
            </button>

         </div>

      </SettingContainer>      

   );

};