import * as React from "react";

import Menu from "Components/Theme/Menu/Menu";
import {
   FlexColumn,
   FlexRow,
   RenderHTML,
   Span,
} from "Components/Theme/Flex/Flex";
import { SimpleNavMenu } from "Components/Theme/SimpleNavMenu/SimpleNavMenu";
import AppSVG from "Utilities/Svg";
import { IPostItem } from "./Props";
import Avatar from "Components/Theme/AvatarWithName/Avatar";
import { postActions } from "Utilities/Data";
import { Assets } from "Assets/Assets";
import { PostActions } from "./PostActions";
import CommonMethods from "Utilities/CommonMethods";
import PostType from "./PostType";
import { useNavigate, useParams } from "react-router";
import { AppRoutes } from "Routes/Routes";
import { FaMapMarkerAlt, FaSpinner } from "react-icons/fa";
import {
   useFetchBuddyAvatars,
   useFetchUserProfile,
} from "Hooks/useFetch/useFetch";
import MainCommentSection from "Components/Screen/CommentSection/MainCommentSection";
import {
   isLikeUnlikePostRequest,
   isPostsRequest,
} from "Components/Screen/Activate/Redux/Thunk";
import { useSelector } from "react-redux";
import { IReducers } from "Utilities/State.interfaces";
import { IResponse } from "Utilities/Interface";
import { PostTypesEnumerator } from "Utilities/Enums";

export const SimplePostItem: React.FC<IPostItem> = (props) => {
   const {
      post,
      activeUser,
      onAction,
      isGeneral,
      isSaved,
      removeMenu,
      likePost,

      isBuddyPost,
      acceptCount,
      isFavourite,
      postLikes,
   } = props;

   const [userProfile] = useFetchUserProfile(post?.userId);

   const [isLoading, userAvatar, refreshRender] = useFetchBuddyAvatars(post?.id);

   const acceptedGolfPostUserIds = userAvatar
      ?.filter((f) => f)
      ?.map((f) => f.userId);

   const { postId } = useParams();

   const user = useSelector((state: IReducers) => state.loginReducer?.user);

   const [openComment, setOpenComment] = React.useState<boolean>(false);

   const [openMenu, setOpenMenu] = React.useState<boolean>(false);

   const navigate = useNavigate();

   const goToSpecificPost = (): void => {
      navigate(`/${AppRoutes.posts}/${post.id}`);
   };

   if (isSaved) {
      return (
         <div
            className="col-span-2 mb-2 transition-all shadow-lg p-3 hover:bg-slate-100
             bg-white rounded-lg duration-150 hover:scale-100"
         >
            <div className="sm:flex">
               {post?.attachment && (
                  <div className="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
                     <PostType
                        src={post?.attachment ?? Assets.emptySrc}
                        type={post?.attachmentType}
                        alt={post?.title}
                        className="h-36 object-cover w-full sm:w-36 border border-gray-300 bg-white rounded-lg"
                     />
                  </div>
               )}
               <div className="w-full">
                  <FlexRow className="w-full items-start justify-between">
                     <FlexColumn className="pr-4">
                        <h5 className="text-base font-bold mb-1 capitalize">
                  Post by {post?.user?.username}
                        </h5>
                        <PostDate
                           date={post?.created}
                           isSaved={true}
                           isFavorite={false}
                           favoritePost={post?.isFavorite}
                           savedPost={post?.isSaved}
                        />
                        <Span
                           className="text-sm text-gray-500 block mt-3 pointer-events-auto"
                           title={
                              post?.description.length > 1000
                                 ? post?.description.slice(0, 1000) + " ... "
                                 : post?.description
                           }
                        />
                     </FlexColumn>
                     <div
                        className="flex items-center"
                        onClick={(e) => {
                           e.stopPropagation();
                           setOpenMenu(!openMenu);
                        }}
                     >
                        <Menu
                           type="popover"
                           className="right-0"
                           button={{
                              title: AppSVG.ellipsisHorizontal,
                              className:
                      "inline-flex justify-center px-3 py-2 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-slate-100 hover:bg-gray-50 focus:outline-none",
                           }}
                        >
                           <SimpleNavMenu
                              menu={postActions(post?.isSaved, post?.isFavorite)
                                 .slice(0, 3)
                                 .filter((item) => item?.id !== 1)}
                              onClick={(obj) => {
                                 if (obj.id === 0) {
                                    goToSpecificPost();
                                 } else onAction(post.id, obj.id);
                              }}
                              className="w-48 px-1"
                           ></SimpleNavMenu>
                        </Menu>
                     </div>
                  </FlexRow>
                  <div className="relative flex items-center my-3">
                     <div className="flex-shrink-0 mr-3">
                        <Avatar
                           src={userProfile?.profilePhoto ?? null}
                           title={
                              post?.user?.username?.substring(0, 2) ??
                    activeUser?.username?.substring(0, 2)
                           }
                           buddyAvatar={false}
                           className="flex-shrink-0 h-7 w-7 text-xs rounded-full"
                        />
                     </div>
                     <div className="flex-1 min-w-0">
                        <span className="absolute inset-0" aria-hidden="true"></span>
                        <p className="text-sm text-gray-500 truncate">
                  Saved from
                           <Span
                              className="font-medium ml-2 text-xs underline"
                              title={`${post?.user?.username} posts`}
                           />
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
   if (isGeneral) {
      return (
         <div className="col-span-1 mb-4 border border-gray-300 p-4 rounded-md">
            <div className="relative flex items-center space-x-3 mb-4">
               <div className="flex-shrink-0 h-8 w-8">
                  <Avatar
                     buddyAvatar={false}
                     src={userProfile?.profilePhoto ?? null}
                     title={
                        post?.user?.username?.substring(0, 2) ??
                activeUser?.username?.substring(0, 2)
                     }
                     className="flex-shrink-0 h-8 w-8 text-xs rounded-full"
                  />
               </div>
               <div className="flex-1 min-w-0">
                  <span className="absolute inset-0" aria-hidden="true"></span>
                  <p className="text-sm font-medium text-gray-900">
                     {/* {post?.user?.username ? post?.user?.username} */}
                  </p>
                  <PostDate
                     date={post?.created}
                     isSaved={false}
                     isFavorite={false}
                     favoritePost={post?.isFavorite}
                     savedPost={post?.isSaved}
                  />
               </div>
            </div>

            <FlexColumn className="relative rounded-lg bg-white mb-2 px-4 items-start justify-start space-x-3 w-full pointer-events-auto break-all">
               <RenderHTML HTML={CommonMethods.replaceWithBr(post?.description)} />
            </FlexColumn>

            {post?.attachment && (
               <PostType
                  src={post?.attachment ?? Assets.emptySrc}
                  type={post?.attachmentType}
                  alt={post?.title}
                  className="w-full rounded object-cover mb-2"
               />
            )}
            <FlexRow className="items-center justify-start">
               <FaMapMarkerAlt className="text-gray-400 text-2xl p-0 m-0" />
               <span className="text-sm"> {post?.address} </span>
            </FlexRow>
         </div>
      );
   }
   // console.log(acceptPost?.id === post?.id);

   // console.log(acceptedGolfPostUserIds?.includes(user?.id));
   return (
      <FlexColumn className="w-full shadow-lg rounded-lg bg-white mb-5 row-auto mb-20">
         <div className="relative rounded-lg p-4 flex items-center space-x-3">
            <div className="flex-shrink-0 h-8 w-8">
               <Avatar
                  buddyAvatar={false}
                  src={userProfile?.profilePhoto ?? null}
                  title={
                     post?.user?.username?.substring(0, 2) ??
              activeUser?.username?.substring(0, 2)
                  }
                  className="flex-shrink-0 h-8 w-8 text-xs rounded-full"
               />
            </div>
            <div className="flex-1 min-w-0">
               <span className="absolute inset-0" aria-hidden="true"></span>
               <p className="text-sm font-medium text-gray-900">
                  {post?.user?.username}
               </p>
               <PostDate
                  date={post?.created}
                  isSaved={false}
                  isFavorite={true}
                  favoritePost={post?.isFavorite}
                  savedPost={post?.isSaved}
               />
            </div>
            {isBuddyPost && (
               <div className="flex text-sm text-gray-500 items-center">
                  <div className="flex -space-x-1 relative z-0 mr-2">
                     {isLoading ? (
                        <div className="flex flex-row animate-pulse relative z-30 -space-x-2">
                           <div className="rounded-full bg-slate-300 h-8 w-8"></div>
                           <div className="rounded-full bg-slate-300 h-8 w-8"></div>
                           <div className="rounded-full bg-slate-300 h-8 w-8"></div>
                        </div>
                     ) : (
                        userAvatar?.map((value, index) => {
                           if (value.userAvatar)
                              return (
                                 <img
                                    key={index}
                                    className="relative z-30 inline-block h-8 w-8 rounded-full ring-2 ring-white"
                                    src={value?.userAvatar}
                                    alt={""}
                                 />
                              );
                           else
                              return (
                                 <Avatar
                                    buddyAvatar={true}
                                    src={null}
                                    title={activeUser?.username?.substring(0, 2)}
                                    className="flex-shrink-0 h-8 w-8  text-xs rounded-full"
                                 />
                              );
                        })
                     )}
                  </div>
                  {/* + {acceptCount} */}
               </div>
            )}
            {!removeMenu && (
               <div
                  onClick={(e) => {
                     e.stopPropagation();
                     setOpenMenu(!openMenu);
                  }}
               >
                  <Menu
                     type="popover"
                     className="right-0"
                     button={{
                        title: AppSVG.ellipsisHorizontal,
                        className:
                  "inline-flex justify-c px-3 py-2 text-sm font-medium rounded-md text-gray-700 bg-slate-100 hover:bg-gray-50 focus:outline-none",
                     }}
                  >
                     {isBuddyPost ? (
                        <SimpleNavMenu
                           menu={postActions(
                              post?.isSaved,
                              post?.isFavorite,
                              acceptedGolfPostUserIds?.includes(user?.id)
                           )
                              .slice(
                                 postId ? 1 : 0,
                                 activeUser?.id === post?.user?.id ? 5 : 4
                              )
                              .filter((item) =>
                                 activeUser?.id === post?.user?.id
                                    ? item.name !== "Accept" && item.name !== "Accepted"
                                    : item.name
                              )}
                           onClick={(obj) => {
                              if (obj.id === 0) {
                                 goToSpecificPost();
                              } else {
                                 if (obj.id === 3) {
                                    onAction(post.id, obj.id);
                                    setTimeout(() => {
                                       refreshRender();
                                    }, 2000);
                                 }
                                 onAction(post.id, obj.id);
                              }
                           }}
                           className="w-48 px-1"
                        ></SimpleNavMenu>
                     ) : (
                        <SimpleNavMenu
                           menu={
                              isFavourite
                                 ? postActions(post?.isSaved, post?.isFavorite)
                                    .slice(0, 3)
                                    .filter((item) => isFavourite && item?.id !== 2)
                                    .filter((item) =>
                                       activeUser?.id === post?.user?.id
                                          ? item.name !== "Accept" &&
                                item.name !== "Accepted"
                                          : item.name
                                    )
                                 : postActions(post?.isSaved, post?.isFavorite)
                                    .slice(
                                       postId ? 1 : 0,
                                       activeUser?.id === post?.user?.id ? 5 : 3
                                    )
                                    .filter((item) =>
                                       activeUser?.id === post?.user?.id
                                          ? item.name !== "Accept" &&
                                item.name !== "Accepted"
                                          : item.name
                                    )
                           }
                           onClick={(obj) => {
                              if (obj.id === 0) {
                                 goToSpecificPost();
                              } else {
                                 onAction(post.id, obj.id);
                              }
                           }}
                           className="w-48 px-1"
                        ></SimpleNavMenu>
                     )}
                  </Menu>
               </div>
            )}
         </div>
         {/* {post.title && <span className="text-lg pb-3 px-4"> {post?.title} </span>} */}
         {isBuddyPost && (
            <FlexRow className="items-center justify-start pb-3 px-4 hover:underline">
               <FaMapMarkerAlt
                  className="text-lg mr-2"
                  style={{ color: "#960A0A" }}
               />
               <span className="text-medium text-sm"> {post?.address} </span>
            </FlexRow>
         )}

         <FlexColumn className="relative rounded-lg bg-white pb-4 px-4 items-start justify-start space-x-3 w-full pointer-events-auto break-all">
            <RenderHTML HTML={CommonMethods.replaceWithBr(post?.description)} />
         </FlexColumn>

         {post?.attachment && (
            <PostType
               src={post?.attachment ?? Assets.emptySrc}
               type={post?.attachmentType}
               alt={post?.title}
               className="w-full rounded-t"
            />
         )}
         <PostActions
            shares={22}
            views={10}
            likePost={likePost}
            postLikes={postLikes}
            isLiked={post?.likes.includes(user?.id)}
            onClick={() => setOpenComment(!openComment)}
         />
         {openComment && (
            <MainCommentSection
               post={post}
               userProfile={userProfile}
               activeUser={activeUser}
            />
         )}
      </FlexColumn>
   );
};

export function PostDate({
   date,
   isSaved,
   isFavorite,
   favoritePost,
   savedPost,
}): JSX.Element {
   return (
      <FlexRow className="items-center justify-start p-0">
         {favoritePost && (
            <React.Fragment>
               <div className="md:block lg:block"> {AppSVG.FavoriteSvg}</div>

               <p className="text-xs text-gray-500 font-medium mr-1">
                  {favoritePost && "Favorite"}
               </p>
            </React.Fragment>
         )}

         {savedPost && (
            <React.Fragment>
               <div className="md:block lg:block">{AppSVG.SavedSvg}</div>
               <p className="text-xs text-gray-500 font-medium mr-1">
                  {savedPost && "Saved"}
               </p>
            </React.Fragment>
         )}

         {isFavorite === isSaved && (
            <React.Fragment>
               {AppSVG.FavoriteSvg}
               <p className="text-xs text-gray-500 font-medium mr-1">
                  {favoritePost && "Favorites - "}
               </p>
               {AppSVG.SavedSvg}

               <p className="text-xs text-gray-500 font-medium mr-1">
                  {savedPost && "Saved"}
               </p>
            </React.Fragment>
         )}

         <p className="text-xs text-gray-500 truncate">
            {CommonMethods.unixStampToDate(date) +
          " / " +
          new Date(date).toLocaleTimeString()}
         </p>
      </FlexRow>
   );
}
