import React, { FC, useCallback, useEffect, useState } from "react";
import { SearchIcon } from "@heroicons/react/solid";
import PlacesAutocomplete, {
   geocodeByAddress,
   getLatLng,
} from "react-places-autocomplete";
import { FlexRow } from "Components/Theme/Flex/Flex";
import { FaSpinner } from "react-icons/fa";

interface ISearchLocation {
   address: string;
   getPosition?: (position: { lat: number; lng: number }) => void;
}

export const SearchLocation: FC<ISearchLocation> = ({
   address,
   getPosition,
}) => {
   const [add, setAdd] = useState<string>("");

   const [showMapPanel, setShowMapPanel] = useState<boolean>(true);
   const handleChange = (add: string): void => {
      setAdd(add);
      setShowMapPanel(true);
   };

   useEffect(() => {
      if (address) {
         setAdd(address);
      }

      return () => {
         setAdd("");
      };
   }, [address]);

   const SearchLocation = useCallback(
      (add: string): void => {
         geocodeByAddress(add)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => getPosition(latLng))
            .catch((error) => console.error("Error", error));
      },
      [add]
   );

   return (
      <PlacesAutocomplete value={add} onChange={handleChange}>
         {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div className="relative">
               {showMapPanel && (
                  <React.Fragment>
                     <div className="relative max-w-xl rounded-t ">
                        <SearchIcon
                           className="pointer-events-none absolute left-4 h-5 w-5 text-gray-400"
                           aria-hidden="true"
                           style={{ transform: "translateY(-65%)", top: "65%" }}
                        />
                        <input
                           {...getInputProps({
                              placeholder: "Search Places ...",
                              className: `md:text-sm h-12 w-full border-0 mt-4 ring-1 ring-black ring-opacity-5 bg-transparent 
                     rounded-xl shadow-2xl pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm`,
                           })}
                        />
                     </div>

                     <div
                        className="autocomplete-dropdown-container rounded-b bg-gray-100 left-0 right-0 absolute max-w-xl max-h-72 scroll-py-2 overflow-y-auto mt-4 text-sm text-gray-800 ease-out duration-300"
                        style={{ zIndex: 1000, top: "50px" }}
                     >
                        {loading && (
                           <FlexRow className="items-center justify-center py-1 bg-transparent">
                              <FaSpinner className="animate-spin text-sm text-black" />
                           </FlexRow>
                        )}
                        {suggestions.map((suggestion) => {
                           const className = suggestion.active
                              ? "suggestion-item--active py-2 pl-5 pr-2 block hover:bg-gray-50 hover:text-primary cursor-pointer"
                              : "suggestion-item py-2 pl-5 pr-2 block hover:bg-gray-50 hover:text-primary cursor-pointer";
                           return (
                              <div
                                 {...getSuggestionItemProps(suggestion, {
                                    className,
                                 })}
                                 onClick={() => {
                                    SearchLocation(suggestion.description);
                                    setShowMapPanel(!showMapPanel);
                                 }}
                              >
                                 <span>{suggestion.description}</span>
                              </div>
                           );
                        })}
                     </div>
                  </React.Fragment>
               )}
            </div>
         )}
      </PlacesAutocomplete>
   );
};
