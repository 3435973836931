import * as React from 'react';

import { DocumentTitle } from 'Components/Theme/DocumentTitle/DocumentTitle';
import { FlexColumn } from 'Components/Theme/Flex/Flex';
import { SettingHeading } from '../Heading/Heading';

interface ISettingContainer{
   title: string;
   children: JSX.Element;
}

export const SettingContainer:React.FC<ISettingContainer> = ({ title, children }) => {

   const [refresh, setRefresh] = React.useState<boolean>(false);

   React.useEffect(() => {
      if(title){
         setTimeout(() => {
            setRefresh(!refresh);
         }, 1000);
      }
   }, [title]);

   return (
      
      <DocumentTitle condition={refresh} title={title}>

         <FlexColumn className="w-full">

            <SettingHeading title={title} />

            <div className="mt-5 border-t border-gray-200">
               
               { children }

            </div>

         </FlexColumn>

      </DocumentTitle>      

   );

};