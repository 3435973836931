import React, { FC, ComponentType } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Redirect } from 'Routes/Redirect';
import { IReducers, IUser } from 'Utilities/State.interfaces';

export interface IWithAuthentication {
   user?: IUser;
   navigate?: (to: string, options) => void;
   dispatch?: (args?) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const withAuthentication = <ComponentProps extends Props>(WrappedComponent: ComponentType<ComponentProps & Props>, redirectRoute: string) => {

   const WithProtectedWrapper:FC<ComponentProps & Props> = (props) => {

      const state = useSelector((state: IReducers) => state.loginReducer);
      const dispatch = useDispatch();

      const navigate = useNavigate();

      if(!state.isLoggedIn){
         return <Redirect to={`/${redirectRoute}`} />;
      }

      else {
         return <WrappedComponent {...props} dispatch={dispatch} navigate={navigate} user={state.user} />;
      }

   };

   return WithProtectedWrapper;

};
 
export default withAuthentication;