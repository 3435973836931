import { IS_GET_USER_DETAIL_QUERY } from 'Components/Screen/UserSettings/screens/Profile/Redux/Query';
import { toast } from 'react-toastify';
import ApiClient from 'Services/ApiClient';
import { tokenService } from 'Services/tokenService';
import CommonMethods from 'Utilities/CommonMethods';
import { IResponse } from 'Utilities/Interface';
import { IAction, IUser } from 'Utilities/State.interfaces';
import { isLoginAction, isLogoutAction } from './Action';
import { IS_LOGIN_QUERY, GET_MY_COMPLETE_DETAILS_QUERY, IS_ACCOUNT_CONFIRMATION, IS_LOGOUT } from './Query';

export const isLoginRequest = (args: { username: string; password: string; }, callback: (response: IResponse) => void) => async (dispatch: (arg0: IAction) => void) => {

   const variables = { ...args };

   ApiClient.sendPostWithAuthWithCallback(variables, IS_LOGIN_QUERY, async (res) => {

      tokenService.setAccessToken(res.data?.signIn?.accessToken);
      tokenService.setRefreshToken(res.data?.signIn?.refreshToken);

      ApiClient.sendGetWithAuthWithCallback({}, GET_MY_COMPLETE_DETAILS_QUERY, async (res1) => {

         let user: IUser = res1.data?.getMyCompleteDetails;

         const { data } = await ApiClient.sendVariablesGetWithAuth({ userId: user?.id }, IS_GET_USER_DETAIL_QUERY);

         if(data){            
            user = { ...user, userProfile: data?.getUserProfile };
         }

         dispatch(isLoginAction(user));         
         callback(CommonMethods.responseProcess(true, user));

      }, (err) => {

         tokenService.setRefreshToken('');
         callback(CommonMethods.responseProcess(false, err?.message));

      });      
      
   }, (err) => {

      tokenService.setRefreshToken('');
      callback(CommonMethods.responseProcess(false, err?.message));

   });

};

export const isAccountVerificationRequest = (userId: string, callback: (response: IResponse) => void): void => {

   const variables = { userId };

   ApiClient.sendPostWithAuthWithCallback(variables, IS_ACCOUNT_CONFIRMATION, (res) => {

      callback(CommonMethods.responseProcess(true, res.userAccountVerification));
      
   }, () => {

      callback(CommonMethods.responseProcess(false, false));

   });

};

export const isLogoutResponse = () => async (dispatch: (arg0: IAction) => void) => {
   ApiClient.sendPostWithAuthWithCallback({}, IS_LOGOUT, () => {
      dispatch(isLogoutAction());
      tokenService.clearTokens();
      location.reload();
   }, () => {
      toast.error('Something went wrong');
      return;
   });
};
