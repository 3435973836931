import { IField } from "Utilities/Interface";
import { IUser } from "Utilities/State.interfaces";
import { IValues } from './Props';

export const allSteps = [
   { id: 1, status: 'inprogress', name: 'Profile' },
   { id: 2, status: 'incomplete', name: 'Personal' },
   // { id: 3, status: 'incomplete', name: 'Notifications' }
];

export const Fields1: IField[] = [
   { id: 1, key: 'firstName', disabled: false, placeholder: '', required: true, type: 'text', label: 'First name *', readonly: false },
   { id: 2, key: 'lastName', disabled: false, placeholder: '', required: true, type: 'text', label: 'Last name *', readonly: false },
   { id: 3, key: 'gender', disabled: false, placeholder: '', required: false, type: 'text', label: 'Gender', readonly: false, dropdown: true },
   { id: 4, key: 'about', disabled: false, placeholder: '', required: false, type: 'text', label: 'Biography', readonly: false },
   { id: 5, key: 'country', disabled: false, placeholder: '', required: false, type: 'text', label: 'Country', readonly: false, dropdown: true },
   { id: 6, key: 'city', disabled: false, placeholder: '', required: false, type: 'text', label: 'City', readonly: false, dropdown: true },
   { id: 7, key: 'street', disabled: false, placeholder: '', required: false, type: 'text', label: 'Address', readonly: false },
   { id: 8, key: 'state', disabled: false, placeholder: '', required: false, type: 'text', label: 'State', readonly: false },
   { id: 9, key: 'postal', disabled: false, placeholder: '', required: true, type: 'text', label: 'Postal Code *', readonly: false }
];

export const Fields2: IField[] = [
   // { id: 1, key: 'firstName', disabled: false, placeholder: '', required: true, type: 'text', label: 'First Name' },
   // { id: 2, key: 'lastName', disabled: false, placeholder: '', required: true, type: 'text', label: 'Last Name' },
   // { id: 3, key: 'email', disabled: false, placeholder: '', required: true, type: 'email', label: 'Email Address' },
   // { id: 4, key: 'scode', disabled: false, placeholder: '', required: true, type: 'number', label: 'Security Code' },
   // { id: 5, key: 'cardnum', disabled: false, placeholder: '', required: true, type: 'number', label: 'Card Number' },
   // { id: 6, key: 'expiry', disabled: false, placeholder: '', required: true, type: 'date', label: 'Expiration Date' },
   // { id: 7, key: 'postal', disabled: false, placeholder: '', required: true, type: 'text', label: 'ZIP / Postal Code' }
];

export const getDataObject = (obj: IValues, user: IUser, fileLoading): undefined | any => {

   const data = {};

   if(obj?.about){
      data['about'] = obj?.about;
   }

   if(obj?.postal){
      data['postalCode'] = obj?.postal;
   }

   if(obj?.street){
      data['address'] = obj?.street;
   }

   if(obj?.state){
      data['state'] = obj?.state;
   }

   if(obj?.street){
      data['state'] = obj?.state;
   }

   if(obj?.firstName){
      data['firstname'] = obj?.firstName;
   }

   if(obj?.lastName){
      data['lastname'] = obj?.lastName;
   }

   if(user){
      data['type'] = user.type;
      data['status'] = user.status;
      data['userId'] = user.id;
   }

   if(obj?.country){
      data['countryId'] = obj?.country?.id;
   }

   if(obj?.city){
      data['cityId'] = obj?.city?.id;
   }

   if(obj?.gender){
      data['gender'] = obj?.gender?.name;
   }

   if(fileLoading?.pUrl){
      data['profilePhoto'] = fileLoading?.pUrl;
   }

   if(fileLoading?.cUrl){
      data['coverPhoto'] = fileLoading?.cUrl;
   }

   return data;
};