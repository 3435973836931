import React, { FC } from 'react';
import { ICity, ICountry } from 'Utilities/Interface';
import { FaSpinner } from 'react-icons/fa';
import { IPost, IUser, IUserFriend, IUserProfile } from 'Utilities/State.interfaces';

interface ILoadData{
   loading: boolean;
   arr: ICountry[] | ICity[] | IUser[] | IPost[] | IUserProfile[] | IUserFriend[];
   children: JSX.Element;
   msg: string;
   className?: string;
}

export const LoadData:FC<ILoadData> = (props) => {

   const { loading, arr, children, msg, className } = props;

   if(loading){
      return <div className={`flex w-full py-3 flex-col items-center justify-center ${className}`}>
         <FaSpinner className="animate-spin text-base text-black" />
      </div>;
   }
   
   if(!loading && !arr?.length){
      return <div className="flex w-full py-3 flex-col items-center justify-center text-base text-black">
         <span> { msg } </span>
      </div>;
   }

   else return children;
};