import { ApolloError } from '@apollo/client';
import { client } from '../Apollo/ApolloClient';

class ApiHandle {

   // Methods with second error callback
   public sendPostWithAuthWithCallback(variables, mutation, successCallback: (res) => void, errorCallback: (error) => void): void {
      client.mutate({
         variables: variables,
         mutation: mutation,
         fetchPolicy: 'no-cache'
      }).then((res) => {
         successCallback(res);
      }).catch((error) => {
         errorCallback(error);
      });
   }

   public sendGetWithAuthWithCallback(variables, query, successCallback: (res) => void, errorCallback: (error) => void): void {
      client.query({
         variables: variables,
         query: query,
         fetchPolicy: 'no-cache',
      }).then((res) => {
         successCallback(res);
      }).catch((error) => {
         errorCallback(error);
      });
   }

   public async sendPostWithAuth(variables, mutation): Promise<{ data, errors }> {
      
      const { data, errors } = await client.mutate({
         variables: variables,
         mutation: mutation,
         fetchPolicy: 'no-cache'
      });

      return { data, errors };
   }

   public async sendVariablesGetWithAuth(variables, query): Promise<{ data, loading: boolean, error: ApolloError }> {
      
      const { data, loading, error } = await client.query({
         variables: variables,
         query: query,
         fetchPolicy: 'no-cache',
      });

      return { data, loading, error };
   }

   public async sendGetWithAuth(query): Promise<{ data, loading: boolean, error: ApolloError }> {
      
      const { data, loading, error } = await client.query({
         query: query,
         fetchPolicy: 'no-cache',
      });

      return { data, loading, error };
   }

   public subscribeGetWithAuth = (query, successCallback, errorCallback): void => {
      client.subscribe({
         query: query,
         fetchPolicy: 'network-only'
      }).subscribe({

         next(data) {
            successCallback(data);
         },
         error(err) {
            errorCallback(err);
         }
      });
   };

   public stopSubscription = (query): void => {
      client.subscribe({
         query: query,
         fetchPolicy: 'network-only'
      }).subscribe({
         next(data) {
            console.log(data);
         }
      }).unsubscribe();
   };

}

const ApiClient = new ApiHandle();

export default ApiClient;
