import { Alert } from "Components/Theme/Alert/Alert";
import Avatar from "Components/Theme/AvatarWithName/Avatar";
import { FlexColumn, FlexRow } from "Components/Theme/Flex/Flex";
import { LoadData } from "Components/Theme/LoadData/LoadData";
import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import CommonMethods from "Utilities/CommonMethods";
import { IResponse } from "Utilities/Interface";
import { IPost, IUser, IUserProfile } from "Utilities/State.interfaces";
import ReplyActions from "../ReplyActions/ReplyActions";
import {
   deleteASingleComment,
   getAllCommentsOfSinglePost,
} from "../Services/thunk";

interface IShowComment {
   userProfile: IUserProfile | undefined;
   post: IPost | undefined;
   createdAt?: number;
   activeUser: IUser;
   comments: any;
   isLoading: boolean;
   getAllComments: (postId: string, userId: string) => void;
}
const ShowComments: FC<IShowComment> = ({
   userProfile,
   post,
   activeUser,
   comments,
   isLoading,
   getAllComments,
}) => {
   const [showReply, setShowReply] = useState<boolean>(false);

   const DeleteAComment = (commentId: string) => {
      deleteASingleComment({ id: commentId }, (res) => {
         if (res.data) getAllComments(post?.id, post?.user?.id);
      });
   };

   return (
      <LoadData loading={isLoading} arr={comments} msg="">
         {comments?.map((comment) => (
            <FlexColumn className="relative w-11/12 ml-6 mt-5 md:ml-12 sm:ml-6 lg:ml-8">
               <FlexColumn className=" relative rounded  shadow-sm p-3 cursor-pointer bg-gray-50">
                  <div className="relative flex items-center">
                     <div className="flex-shrink-0 text-white mr-2">
                        <FlexColumn className="w-10 h-10 rounded-full items-center justify-center text-sm uppercase font-medium bg-cc-3tree-orangedark">
                           <Avatar
                              buddyAvatar={false}
                              src={userProfile?.profilePhoto ?? null}
                              title={
                                 post?.user?.username?.substring(0, 2) ??
                      activeUser?.username?.substring(0, 2)
                              }
                              className="flex-shrink-0 h-6 w-6 text-xs rounded-full"
                           />
                        </FlexColumn>
                     </div>
                     <div className="min-w-0 flex-1 text-cc-bg-grey opacity-90 ">
                        <FlexRow className="items-center trash-red">
                           <p className="text-sm font-medium mr-2">
                              {post.user?.username}
                           </p>
                           <svg
                              onClick={() => DeleteAComment(comment.id)}
                              className="w-3 h-3 absolute right-20 "
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                           >
                              <path d="M424 80C437.3 80 448 90.75 448 104C448 117.3 437.3 128 424 128H412.4L388.4 452.7C385.9 486.1 358.1 512 324.6 512H123.4C89.92 512 62.09 486.1 59.61 452.7L35.56 128H24C10.75 128 0 117.3 0 104C0 90.75 10.75 80 24 80H93.82L130.5 24.94C140.9 9.357 158.4 0 177.1 0H270.9C289.6 0 307.1 9.358 317.5 24.94L354.2 80H424zM177.1 48C174.5 48 171.1 49.34 170.5 51.56L151.5 80H296.5L277.5 51.56C276 49.34 273.5 48 270.9 48H177.1zM364.3 128H83.69L107.5 449.2C108.1 457.5 115.1 464 123.4 464H324.6C332.9 464 339.9 457.5 340.5 449.2L364.3 128z" />
                           </svg>
                           <p className="text-sm font-normal absolute right-0">
                              {CommonMethods.unixStampToDateTime(comment.created).newTime}
                           </p>
                        </FlexRow>
                     </div>
                  </div>

                  <p className="block py-3 text-sm text-cc-bg-grey opacity-90 break-all">
                     {comment.comment}
                  </p>
               </FlexColumn>
               <ReplyActions
                  countReplies={2}
                  onClick={() => setShowReply(!showReply)}
               />

               {/* {showReply && (
                  <Fragment>
                     <MainComment
                        PlaceHolderComment={"Reply..."}
                        activeUser={activeUser}
                        post={post}
                        userProfile={userProfile}
                     />
                     <ShowReplies
                        userProfile={userProfile}
                        post={post}
                        activeUser={activeUser}
                     />
                  </Fragment>
               )} */}
            </FlexColumn>
         ))}
      </LoadData>
   );
};

export default ShowComments;
