import React from "react";

export function FlexRow({ className, children, ...props }) {
   return (
      <div className={`flex flex-row ${className}`} {...props}>
         {children}
      </div>
   );
}

export function FlexColumn({ className, children, ...props }) {
   return (
      <div className={`flex flex-col ${className}`} {...props}>
         {children}
      </div>
   );
}

export function Grid({ className, children, ...props }) {
   return (
      <div className={`grid ${className}`} {...props}>
         {children}
      </div>
   );
}

export function Image({ className, src, defaultSrc, ...props }) {
   return <img src={src ? src : defaultSrc} className={className} {...props} />;
}

export function Span({ className, title, ...props }) {
   return (
      <span className={`w-full ${className}`} {...props}>
         {title}
      </span>
   );
}

export const RenderHTML = ({ HTML }) => (
   <span className="z-0" dangerouslySetInnerHTML={{ __html: HTML }}></span>
);
