import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { profileMenu, topLinks } from "Utilities/Data";
import { Strings } from "Utilities/Strings";
import AppSVG from "Utilities/Svg";
import { Link } from "../Link/Link";
import { AppRoutes } from "Routes/Routes";
import Menu from "../Menu/Menu";
import { toast } from "react-toastify";
import NotificationsMenu from "../Notifications/NotificationsMenu";
import Props from "./Props";
import { SimpleNavMenu } from "../SimpleNavMenu/SimpleNavMenu";
import { Label } from "../FormElements/Label";
import InputField from "../FormElements/Input";
import { ILinkObject, IResponse, ISimpleMenu } from "Utilities/Interface";
import { Alert } from "../Alert/Alert";
import { Assets } from "Assets/Assets";
import Avatar from "../AvatarWithName/Avatar";
import Svg from "libraries/AppSvg";
import Fade from "react-reveal/Fade";
import { sideBarMainLinks } from "Utilities/Data";
import { isSendInvitationRequest } from "Apollo/GeneralRedux/Thunk";
import CommonMethods from "Utilities/CommonMethods";
import { Button } from "../FormElements/Button";
import { FaSpinner } from "react-icons/fa";

const NavBar: FC<Props> = ({ onLogout, user, profileImage }) => {
   const [search, setSearch] = useState<string>("");

   const [isOpen, setIsOpen] = useState<boolean>(false);

   const navigate = useNavigate();

   const sideNavigations: ILinkObject[] = sideBarMainLinks;

   const onAvatarMenuItemClick = (obj: ISimpleMenu): void => {
      if (obj.name.toLowerCase() == Strings.ProfileMenu_Profile.toLowerCase()) {
         navigate("/" + AppRoutes.user + "/" + AppRoutes.profile);
      }
      if (
         obj.name.toLowerCase() ==
      Strings.ProfileMenu_AccountSettings.toLowerCase()
      ) {
         navigate("/" + AppRoutes.user + "/" + AppRoutes.settings);
      }
      if (obj.name.toLowerCase() == Strings.ProfileMenu_SignOut.toLowerCase()) {
         setIsOpen(true);
      }
   };

   const [openRightContent, setOpenRightContent] = useState<boolean>(false);
   const [openLeftContent, setOpenLeftContent] = useState<boolean>(false);

   const [email, setEmail] = React.useState<string>("");
   const [loading, setLoading] = React.useState<boolean>(false);

   const sendInvite = (): void => {
      if (!email) {
         toast.error("Write the email address.");
      } else if (!CommonMethods.validateEmail(email)) {
         toast.error("Write the valid email address.");
      } else {
         setLoading(true);
         isSendInvitationRequest(email, (res: IResponse) => {
            if (res.status) {
               toast.success("Your invitation has been sent successfully!");
            } else {
               toast.error("Try again. Something went wrong.");
            }

            setLoading(false);
            setEmail("");
         });
      }
   };
   return (
      <nav className="bg-white shadow z-50">
         <div className="px-2 sm:px-4 lg:px-8">
            <div className="relative h-16 flex justify-between">
               <div className="relative z-auto flex lg:px-0">
                  <div className="bg-red flex-shrink-0 flex items-center mr-1 cursor-pointer z-10">
                     <div
                        onClick={() => setOpenLeftContent(!openLeftContent)}
                        className="block lg:hidden"
                     >
                        {openLeftContent &&
                  Svg.crossIcon(
                     "h-6 w-6 cursor-pointer sm:block lg:hidden",
                     "black"
                  )}
                        {!openLeftContent &&
                  Svg.barStaggered(
                     "h-6 w-auto cursor-pointer sm:block lg:hidden",
                     "black"
                  )}
                     </div>
                     <Link
                        key={0}
                        route={AppRoutes.posts}
                        className="sm:flex sm:flex-col sm:items-center cursor-pointer border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 md:inline-flex lg:inline-flex px-2 pt-1 text-sm font-medium"
                        activeClassName="sm:flex sm:flex-col sm:items-center md:block lg:block text-gray-900 inline-flex items-center px-2 pt-1 border-b-0 text-sm font-medium"
                     >
                        <img
                           className="w-8 h-8 sm:block lg:h-12 md:h-9 sm:h-8 cursor-pointer md:w-auto"
                           src={Assets.golfLogo}
                           alt="GolfGuiders"
                        />
                     </Link>
                  </div>
                  <div className="max-w-lg w-full lg:max-w-xs flex-shrink-0 flex items-center pointer-events-none">
                     <Label htmlFor="search" className="sr-only" title="Search" />
                     <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                           <svg
                              className="h-5 w-5 text-gray-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                           >
                              <path
                                 fillRule="evenodd"
                                 d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                 clipRule="evenodd"
                              />
                           </svg>
                        </div>
                        <InputField
                           className="w-40 block sm:w-full md:w-full lg:w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                           htmlFor="search"
                           placeholder="Coming Soon"
                           type="search"
                           value={search}
                           required={false}
                           onChange={(val: string) => setSearch(val)}
                        />
                     </div>
                  </div>
               </div>

               <div className="relative z-0 flex-1 px-2 flex items-center justify-center sm:absolute sm:inset-0">
                  <div className="w-auto sm:max-w-xs h-full">
                     <div className="hidden md:ml-6 md:flex md:space-x-8 h-full">
                        {topLinks.map((link, i) => (
                           <Link
                              key={i}
                              route={link.route}
                              className="cursor-pointer border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-5 pt-2 border-b-2 text-sm font-medium mx-auto"
                              activeClassName="border-primary text-gray-900 inline-flex items-center px-5 pt-2 border-b-2 text-sm font-medium h-full"
                           >
                              <img alt="" src={link.image} width="34px" />
                           </Link>
                        ))}
                     </div>
                  </div>
               </div>

               <div className="z-10 flex items-center">
                  <Menu
                     type="popover"
                     className="right-0"
                     button={{
                        title: AppSVG.notificationSvg,
                        className:
                  "inline-flex items-center hidden justify-center p-2 mr-3 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-100",
                     }}
                  >
                     <NotificationsMenu />
                  </Menu>
                  <button
                     type="button"
                     aria-controls="mobile-menu"
                     aria-expanded="false"
                     className="inline-flex items-center justify-center p-2 ml-2 mr-1 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-100"
                     onClick={() =>
                        navigate(`/${AppRoutes.user}/${AppRoutes.settings}`)
                     }
                  >
                     <span className="sr-only">Settings</span>
                     {AppSVG.settingSvg}
                  </button>
                  <Menu
                     type="popover"
                     className="right-0 z-50"
                     isTitleAsChildren={
                        profileImage ? (
                           <Avatar
                              buddyAvatar={false}
                              src={profileImage ?? null}
                              title={user?.username?.substring(0, 2)}
                              className="flex-shrink-0 h-10 w-10 text-xs rounded-full"
                           />
                        ) : undefined
                     }
                     button={{
                        title: !profileImage
                           ? user?.username?.substring(0, 2)
                           : undefined,
                        className:
                  " text-white z-50 rounded-full flex flex-col uppercase items-center font-medium justify-center bg-primary text-sm focus:outline-none ring-2 ring-offset-2 ring-primary h-10 w-10",
                     }}
                  >
                     <SimpleNavMenu
                        className="w-52 px-1"
                        menu={profileMenu}
                        onClick={(obj) => onAvatarMenuItemClick(obj)}
                     />
                  </Menu>
                  <div
                     onClick={() => setOpenRightContent(!openRightContent)}
                     className="ml-3"
                  >
                     {!openRightContent &&
                Svg.barSort(
                   "h-6 w-6 cursor-pointer md:block sm:block lg:hidden",
                   "black"
                )}
                     {openRightContent &&
                Svg.crossIcon(
                   "h-6 w-6 cursor-pointer md:block sm:block lg:hidden",
                   "black"
                )}
                  </div>
               </div>
            </div>
         </div>
         {openLeftContent && (
            <Fade left condition={openLeftContent} delay={-400}>
               <div className="w-52 absolute z-10 left-0 sm:w-60 md:w-60 h-full bg-white shadow-2xl">
                  <div className=" sm:block md:block lg:block sm:col-span-2 md:col-span-2 lg:col-span-2 bg-white px-2 py-4 sm:px-4 lg:px-8 mt-1 relative ">
                     {sideNavigations.map((item: ILinkObject, i: number) => (
                        <Link
                           className={`${
                              item.disabled
                                 ? "text-gray-400 pointer-events-none "
                                 : "text-gray-900 hover:text-primary hover:bg-gray-50  pointer-events-auto"
                           } group flex items-center sm:text-sm lg:text-lg md:text-sm text-md px-2 py-2 text-base font-medium rounded-md`}
                           key={i}
                           route={item.route}
                           activeClassName="lg:text-lg text-primary md:text-sm sm:text-sm bg-gray-50 hover:text-primary hover:bg-gray-50 group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        >
                           <div className="flex">
                              <span className="mr-4 ">
                                 {item.svg ? (
                                    item.image
                                 ) : (
                                    <img
                                       alt={item.name}
                                       src={item.image}
                                       className="w-5 h-5 text-gray-400 group-hover:text-gray-500 flex-shrink-0 lg:h-6 lg:w-6 sm:w-4 sm:h-5 md:w-5 md:h-4"
                                    />
                                 )}
                              </span>
                              <span> {item.name} </span>
                           </div>
                        </Link>
                     ))}

                     <div className="flex border-t border-gray-300 py-3 px-5 mt-44 flex-col ">
                        <span className="text-xs sm:text-sm md:text-sm lg:text-sm  text-black block">
                  Privacy - Terms - Cookies
                        </span>
                        <span className="text-xs sm:text-sm md:text-sm lg:text-sm text-black block">
                  Copyright &copy; 2022
                        </span>
                     </div>
                  </div>
               </div>
            </Fade>
         )}
         {openRightContent && (
            <Fade right condition={openRightContent} delay={-400}>
               <div className="absolute right-0 z-0 w-60 h-full bg-white shadow-2xl">
                  <div
                     id="right_content"
                     className={`sm:block md:block col-span-2 px-2 py-4 sm:px-4 lg:px-8 mt-1 bg-white overflow-y-auto lg:block`}
                  >
                     <div className="mb-2">
                        <Label
                           htmlFor="email"
                           className="block text-sm font-medium text-gray-700"
                           title="Invite your friends"
                        />
                        <div className="mt-1 relative rounded-md shadow-sm ">
                           <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              {AppSVG.envelop("h-5 w-5 text-gray-400 md:h-4 md:w-4")}
                           </div>
                           <InputField
                              htmlFor="email"
                              placeholder="you@example.com"
                              value={email}
                              onChange={(val) => setEmail(val)}
                              type="email"
                              className="block w-full pl-10 md:pl-8 py-2 sm:text-sm md:text-xs border-gray-300 border-2 focus:border-primary focus:ring-0 rounded-md"
                              onKeyPress={() => sendInvite()}
                              readonly={false}
                              required={true}
                           />
                        </div>
                     </div>
                     <Button
                        className="inline-flex items-center justify-center w-24 py-2 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
                        disabled={loading}
                        id="submit"
                        loading={false}
                        onClick={sendInvite}
                        title={
                           loading ? (
                              <FaSpinner className="animate-spin text-xs text-white" />
                           ) : (
                              "Invite Now"
                           )
                        }
                     />
                     <div className="text-sm text-gray-500 block mt-10">
                Sponsored ads
                     </div>
                     <h3 className="text-sm text-gray-400 block mt-1">Coming soon</h3>
                  </div>
               </div>
            </Fade>
         )}
         <Alert
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            className=""
            title="Sign Out?"
            description="Are you sure that you want to sign out ?"
            forSuccess={true}
            onSubmit={onLogout}
         />
      </nav>
   );
};

export default NavBar;
