import React, { FC } from 'react';

interface ILabel{
   title: string;
   className: string;
   htmlFor: string;
}

export const Label:FC<ILabel> = ({ htmlFor, className, title }) => {
   return <label className={className} htmlFor={htmlFor}>
      { title }
   </label>;
};