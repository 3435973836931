import React, { FC, useCallback, useEffect, useState } from "react";
import Menu from "Components/Theme/Menu/Menu";
import AppSVG from "Utilities/Svg";
import { SimpleNavMenu } from "Components/Theme/SimpleNavMenu/SimpleNavMenu";
import PostType from "Components/Theme/PostItem/PostType";
import { SearchLocation } from "../UserSettings/screens/Location/SearchLocation";
import { Classes, Constants } from "Utilities/Constants";
import CommonMethods from "Utilities/CommonMethods";
import { PostTypesEnumerator, UserStatusEnumerator } from "Utilities/Enums";
import { toast } from "react-toastify";
import { CustomDatePicker } from "Components/Theme/FormElements/DatePicker";
import { FaSpinner } from "react-icons/fa";
import useUploadFile from "Hooks/Upload/UploadFile";
import { ButtonLabel } from "Components/Theme/FormElements/Button";
import { useDispatch } from "react-redux";
import { userLatestPost } from "../Activate/Redux/Action";

export const GolfBuddyCreation: FC<{
   createPost: (obj) => void;
   username: string;
   postId: string;
}> = ({ createPost, username, postId }) => {
   const [upload] = useUploadFile();
   const [values, setValues] = React.useState({
      title: "",
      desc: "",
      file: null,
      date: undefined,
      assign: [],
      label: "",
   });

   const [error, setError] = useState({
      address: false,
      date: false,
   });
   useEffect(() => {
      if (postId) console.log();
   }, [postId]);
   const dispatch = useDispatch();

   const [position, setPosition] = React.useState<{ lat: number; lng: number }>({
      lat: 0,
      lng: 0,
   });
   const [loading, setLoading] = useState<boolean>(false);
   const [fileLoading, setFileLoading] = React.useState<{
      post: boolean;
      attachment?: string;
   }>({ post: false, attachment: "" });

   useEffect(() => {
      if (position) {
         getAddress(position);
      }
   }, [position]);

   const getAddress = useCallback(
      ({ lat, lng }) => {
         fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=false&key=${Constants.GOOGLE_API_KEY}`
         )
            .then((res) => res.json())
            .then((res1) => {
               setValues({ ...values, label: res1?.results[1]?.formatted_address });
            });
      },
      [position]
   );

   const changeHandler = useCallback(
      (key: string, value) => {
         setValues({ ...values, [key]: value });
      },
      [values]
   );

   const uploadSelectedFile = useCallback(
      (file: File, type): void => {
         setFileLoading({ ...fileLoading, post: true });
         upload(file, type, (res: string) => {
            setFileLoading({ post: false, attachment: res });
         });
      },
      [fileLoading]
   );

   const onSubmit = (): void => {
      if (fileLoading.post) {
         toast.success("Please wait your attachment is uploading to server");
      } else if (
         values.desc &&
      values.date &&
      values.label &&
      !fileLoading.post
      ) {
         setLoading(true);

         const details = {
            description: values.desc,
            title: "",
            type: PostTypesEnumerator.BUDDY,
            status: UserStatusEnumerator.ACTIVE,
            dateFrom: new Date().getTime(),
            dateTo: new Date(values.date).getTime(),
            address: values.label,
         };

         if (fileLoading.attachment) {
            details["attachment"] = fileLoading.attachment;
            details["attachmentType"] = CommonMethods.getFileType(
               values.file?.type
            );
         }

         dispatch(userLatestPost(details));
         createPost(details);

         setTimeout(() => {
            setValues({
               title: "",
               desc: "",
               file: null,
               date: undefined,
               assign: [],
               label: "",
            });
            setLoading(false);
         }, 1500);
      } else {
         if (!values?.label) setError({ ...error, address: true, date: false });
         else if (!values?.date)
            setError({ ...error, address: false, date: true });
         toast.error("Please select location and date.");
      }
   };

   return (
      <div className="relative w-full mt-6 mb-10">
         <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-primary focus-within:ring-1 focus-within:ring-primary">
            {/* <label htmlFor="title" className="sr-only">
          Title
            </label>
            <input
               type="text"
               name="title"
               id="title"
               value={values.title}
               onChange={(e) => changeHandler("title", e.target.value)}
               className="block w-full border-0 pt-2.5 text-lg font-medium placeholder-gray-500 focus:ring-0"
               placeholder="Title"
            /> */}
            <label htmlFor="description" className="sr-only">
          Description
            </label>
            <textarea
               rows={2}
               name="description"
               id="description"
               value={values.desc}
               onChange={(e) => changeHandler("desc", e.target.value)}
               className="block w-full border-0 pt-2.5  py-0 resize-none placeholder-gray-500 focus:ring-0 sm:text-sm"
               placeholder="Write a description..."
            ></textarea>

            <div aria-hidden="true">
               <div className="py-2">
                  <div className="h-9"></div>
               </div>
               <div className="h-px"></div>
               <div className="py-2">
                  <div className="py-px">
                     <div className="h-9"></div>
                  </div>
               </div>
            </div>
         </div>

         <div className="absolute bottom-0 inset-x-px">
            <div className="flex flex-nowrap justify-end py-2 px-2 space-x-2 sm:px-3">
               <div className="flex-shrink-0">
                  <label id="listbox-label" className="sr-only">
                     {" "}
              Address{" "}
                  </label>
                  <div className="relative">
                     <Menu
                        type="popover"
                        className="right-0"
                        button={{
                           title: (
                              <React.Fragment>
                                 {" "}
                                 {AppSVG.globe(values?.label ? "green" : "none")}
                                 <span className="hidden truncate md:ml-2 md:block lg:block lg:ml-2">
                                    {" "}
                                    {!values.label ? "Address" : values.label}{" "}
                                 </span>
                              </React.Fragment>
                           ),
                           className:
                    " relative inline-flex items-center border-red-500 bg-red rounded-full py-2 px-2 bg-gray-50 text-sm font-medium text-gray-500 whitespace-nowrap hover:bg-gray-100 sm:px-3",
                        }}
                     >
                        <div
                           style={{ width: "300px" }}
                           className="w-full bg-white relative "
                        >
                           <SearchLocation
                              address={values.label}
                              getPosition={setPosition}
                           />
                        </div>
                     </Menu>
                  </div>
               </div>
               <div className="flex-shrink-0">
                  <label id="listbox-label" className="sr-only">
                     {" "}
              Add a due date{" "}
                  </label>
                  <div className="relative">
                     <CustomDatePicker
                        startDate={values.date}
                        setStartDate={(date) => changeHandler("date", date)}
                        className={`relative inline-flex items-center ${
                           error?.date && "border-2 border-red-500"
                        } rounded-full py-2 px-2 bg-gray-50  text-sm font-medium text-gray-500 whitespace-nowrap hover:bg-gray-100 sm:px-3`}
                     >
                        <React.Fragment>
                           {" "}
                           {AppSVG.dateSelectionSvg(
                              values?.date ? "green" : "currentColor"
                           )}{" "}
                           <span className="hidden truncate md:ml-2 md:block lg:ml-2 lg:block">
                              {" "}
                              {values?.date
                                 ? values?.date.toDateString() +
                        " / " +
                        values?.date.toLocaleTimeString()
                                 : "Date"}{" "}
                           </span>{" "}
                        </React.Fragment>
                     </CustomDatePicker>
                  </div>
               </div>
            </div>

            <div className="border-t border-gray-200 px-2 py-2 flex justify-between items-center space-x-3 sm:px-3">
               <div className="flex items-center">
                  <label className="-ml-2 -my-2 rounded-full cursor-pointer px-3 py-2 flex items-center text-left text-gray-400 group">
                     {AppSVG.attachment}
                     <span className="text-sm text-gray-500 group-hover:text-gray-600 italic">
                Attach a file
                     </span>
                     {values.file && (
                        <PostType
                           src={URL.createObjectURL(values.file)}
                           className="w-10 h-10 rounded ml-4"
                           alt={values.file?.name}
                           type={CommonMethods.getFileType(values.file?.type)}
                        />
                     )}

                     <input
                        type="file"
                        className="hidden"
                        onChange={(e) => changeHandler("file", e.target.files[0])}
                     />
                  </label>
                  <ButtonLabel
                     onClick={() => uploadSelectedFile(values.file, "image")}
                     title="Upload"
                     loading={fileLoading.post}
                     className={`ml-2 w-24 ${
                        values.file ? "flex" : "hidden"
                     } bg-white ${Classes.basicButton}`}
                  />
               </div>
               <div className="flex-shrink-0">
                  <button
                     type="button"
                     onClick={onSubmit}
                     disabled={loading || !values.desc}
                     className={`inline-flex items-center justify-center w-24 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
                        loading || !values.desc
                           ? "bg-green-300"
                           : "bg-primary hover:bg-primary"
                     }  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary`}
                  >
                     {loading ? (
                        <FaSpinner className="animate-spin text-white text-sm" />
                     ) : (
                        "Create"
                     )}
                  </button>
               </div>
            </div>
         </div>
      </div>
   );
};
