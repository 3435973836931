import ApiClient from 'Services/ApiClient';
import CommonMethods from 'Utilities/CommonMethods';
import { IArgs, IResponse } from 'Utilities/Interface';
import { GET_ALL_CITIES, GET_ALL_COUNTRIES, GET_A_CITY, GET_A_COUNTRY, GET_A_GOLF_COURSE, GET_ALL_GOLF_COURSES } from './Query';

export const isGolfCountriesRequest = (args: IArgs, callback: (response: IResponse) => void): void => {

   const variables = { ...args };

   ApiClient.sendPostWithAuthWithCallback(variables, GET_ALL_COUNTRIES, async (res) => {

      callback(CommonMethods.responseProcess(true, res.data.getAllCountries));
      
   }, () => {

      callback(CommonMethods.responseProcess(false, []));

   });

};

export const isGolfCoursesRequest = (args: IArgs, callback: (response: IResponse) => void): void => {

   const variables = { ...args };

   ApiClient.sendPostWithAuthWithCallback(variables, GET_ALL_GOLF_COURSES, async (res) => {

      callback(CommonMethods.responseProcess(true, res.data.getAllGolfCourses));
      
   }, () => {

      callback(CommonMethods.responseProcess(false, []));

   });

};

export const isGolfCitiesRequest = (args: IArgs, callback: (response: IResponse) => void): void => {

   const variables = { ...args };
 
   ApiClient.sendPostWithAuthWithCallback(variables, GET_ALL_CITIES, async (res) => {
 
      callback(CommonMethods.responseProcess(true, res.data.getAllCities));
       
   }, () => {
 
      callback(CommonMethods.responseProcess(false, []));
 
   });
 
};

export const isGolfCountryRequest = (id: string, callback: (response: IResponse) => void): void => {

   const variables = { id };

   ApiClient.sendPostWithAuthWithCallback(variables, GET_A_COUNTRY, async (res) => {

      callback(CommonMethods.responseProcess(true, res.data.getACountry));
      
   }, () => {

      callback(CommonMethods.responseProcess(false, undefined));

   });

};

export const isGolfCourseRequest = (id: string, callback: (response: IResponse) => void): void => {

   const variables = { id };

   ApiClient.sendPostWithAuthWithCallback(variables, GET_A_GOLF_COURSE, async (res) => {

      callback(CommonMethods.responseProcess(true, res.data.getAGolfCourse));
      
   }, () => {

      callback(CommonMethods.responseProcess(false, undefined));

   });

};

export const isGolfCityRequest = (id: string, callback: (response: IResponse) => void): void => {

   const variables = { id };
 
   ApiClient.sendPostWithAuthWithCallback(variables, GET_A_CITY, async (res) => {
 
      callback(CommonMethods.responseProcess(true, res.data.getACity));
       
   }, () => {
 
      callback(CommonMethods.responseProcess(false, undefined));
 
   });
 
};