import React, { FC, useState, useCallback } from "react";
import { ICreatePost } from "./Props";

import Avatar from "Components/Theme/AvatarWithName/Avatar";
import { FaSpinner } from "react-icons/fa";
import useUploadFile from "Hooks/Upload/UploadFile";
import { ButtonLabel } from "Components/Theme/FormElements/Button";
import AppSVG from "Utilities/Svg";
import CommonMethods from "Utilities/CommonMethods";
import PostType from "Components/Theme/PostItem/PostType";
import { Classes } from "Utilities/Constants";

const CreatePost: FC<ICreatePost> = (props) => {
   const [upload] = useUploadFile();
   const { createNewPost, userName, profileImage } = props;

   const [values, setValues] = React.useState({ desc: "", file: null });
   const [loading, setLoading] = useState<boolean>(false);
   const [fileLoading, setFileLoading] = React.useState<{
      post: boolean;
      attachment?: string;
   }>({ post: false, attachment: "" });

   const submit = (): void => {
      if (values.desc) {
         setLoading(true);
         const details = { description: values.desc };

         if (fileLoading.attachment) {
            details["attachment"] = fileLoading.attachment;
            details["attachmentType"] = CommonMethods.getFileType(
               values.file?.type
            );
         }

         createNewPost(details);

         setTimeout(() => {
            setLoading(false);
            setValues({ desc: "", file: null });
         }, 1000);
      }
   };

   const changeHandler = useCallback(
      (key: string, value) => {
         setValues({ ...values, [key]: value });
      },
      [values]
   );

   const uploadSelectedFile = useCallback(
      (file: File, type): void => {
         setFileLoading({ ...fileLoading, post: true });
         upload(file, type, (res: string) => {
            setFileLoading({ post: false, attachment: res });
         });
      },
      [fileLoading]
   );

   return (
      <div className="flex items-start mt-3 space-x-4 mb-3">
         <div className="flex-shrink-0">
            <Avatar
               buddyAvatar={false}
               src={profileImage}
               title={userName?.substring(0, 2)}
               className="flex-shrink-0 cc-h-32px cc-w-32px"
            />
         </div>
         <div className="min-w-0 flex-1">
            <form action="#" className="relative">
               <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-primary focus-within:ring-1 focus-within:ring-primary">
                  <label htmlFor="comment" className="sr-only">
              Add your comment
                  </label>
                  <textarea
                     rows={3}
                     name="comment"
                     id="comment"
                     value={values.desc}
                     onChange={(e) => changeHandler("desc", e.target.value)}
                     className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
                     placeholder="Add your post here..."
                  ></textarea>

                  <div className="py-2" aria-hidden="true">
                     <div className="py-px">
                        <div className="h-9"></div>
                     </div>
                  </div>
               </div>

               <div className="absolute bottom-0 inset-x-0 pl-3 pr-2 my-2 flex justify-between">
                  <div className="border-t border-gray-200 pt-2 pb-1 flex justify-between items-center space-x-1 px-1 w-full">
                     <div className="flex items-center">
                        <label className="-ml-2 -my-2 rounded-full cursor-pointer px-3 py-2 flex items-center text-left text-gray-400 group">
                           {AppSVG.attachment}
                           <span className="text-sm text-gray-500 group-hover:text-gray-600 italic">
                    Attach a file
                           </span>
                           {values.file && (
                              <PostType
                                 src={URL.createObjectURL(values.file)}
                                 className="w-10 h-10 rounded ml-4"
                                 alt={values.file?.name}
                                 type={CommonMethods.getFileType(values.file?.type)}
                              />
                           )}

                           <input
                              type="file"
                              className="hidden"
                              onChange={(e) => changeHandler("file", e.target.files[0])}
                           />
                        </label>
                        <ButtonLabel
                           onClick={() => uploadSelectedFile(values.file, "image")}
                           title="Upload"
                           loading={fileLoading.post}
                           className={`ml-2 w-24 ${
                              values.file ? "flex" : "hidden"
                           } bg-white ${Classes.basicButton}`}
                        />
                     </div>
                     <div className="flex-shrink-0">
                        <button
                           type="button"
                           onClick={submit}
                           disabled={loading || !values.desc}
                           className={`inline-flex items-center justify-center w-24 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
                              loading || !values.desc
                                 ? "bg-green-200"
                                 : "bg-primary hover:bg-primary"
                           }  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary`}
                        >
                           {loading ? (
                              <FaSpinner className="animate-spin text-white text-sm" />
                           ) : (
                              "Create"
                           )}
                        </button>
                     </div>
                  </div>
               </div>
            </form>
         </div>
      </div>
   );
};

export default CreatePost;
