import React, { FC } from 'react';
import Props from './Props';

import { SettingContainer } from 'Components/Screen/UserSettings/sub-components/SettingContainer/SettingContainer';
import { CheckPermission } from 'Components/Theme/CheckPermission/CheckPermission';

const people = [
   {
      name: 'Lindsay Walton',
      title: 'Professional Golf Player',
      department: 'Optimization',
      email: 'lindsay.walton@example.com',
      role: 'Member',
      image:
       'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      active: false
   },
   {
      name: 'Lindsay Walton',
      title: 'Professional Golf Trainer',
      department: 'Optimization',
      email: 'lindsay.walton@example.com',
      role: 'Member',
      image:
        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      active: true
   },
   {
      name: 'Lindsay Walton',
      title: 'Professional Golf Player',
      department: 'Optimization',
      email: 'lindsay.walton@example.com',
      role: 'Member',
      image:
        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      active: true
   },
   {
      name: 'Lindsay Walton',
      title: 'Professional Golf Trainer',
      department: 'Optimization',
      email: 'lindsay.walton@example.com',
      role: 'Member',
      image:
        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      active: false
   }
];

export const Blocking:FC<Props> = () => {

   return (
      
      <CheckPermission condition={false}>

         <SettingContainer title="Blocking">

            <div className="pt-5">
               <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto">
                     <h1 className="text-base font-semibold text-gray-900">Users</h1>
                     <p className="mt-2 text-sm text-gray-700">
                           A list of all the users in your account including their name, title, email and role.
                     </p>
                  </div>
                  <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                     <button
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                     >
                           Block all
                     </button>
                  </div>
               </div>
               <div className="mt-8 flex flex-col">
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                     <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                           <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                 <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Title
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Role
                                    </th>
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                       <span className="sr-only">Edit</span>
                                    </th>
                                 </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                 {people.map((person) => (
                                    <tr key={person.email}>
                                       <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                          <div className="flex items-center">
                                             <div className="h-10 w-10 flex-shrink-0">
                                                <img className="h-10 w-10 rounded-full" src={person.image} alt="" />
                                             </div>
                                             <div className="ml-4">
                                                <div className="font-medium text-gray-900">{person.name}</div>
                                                <div className="text-gray-500">{person.email}</div>
                                             </div>
                                          </div>
                                       </td>
                                       <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          <div className="text-gray-900">{person.title}</div>
                                          <div className="text-gray-500">{person.department}</div>
                                       </td>
                                       <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          <span className={`
                                          inline-flex rounded-full px-2 text-xs font-semibold leading-5
                                          ${ person.active ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800' }
                                       `}>
                                             { person.active ? 'Active' : 'InActive' }
                                          </span>
                                       </td>
                                       <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.role}</td>
                                       <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                          <span className="text-indigo-600 hover:text-indigo-900">
                                             { person.active ? 'Block' : 'Unblock' }
                                             <span className="sr-only">, {person.name}</span>
                                          </span>
                                       </td>
                                    </tr>
                                 ))}
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </SettingContainer>      

      </CheckPermission>
   );

};