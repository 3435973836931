import * as React from "react";

import { useNavigate, useParams } from "react-router-dom";
import Menu from "Components/Theme/Menu/Menu";
import AppSVG from "Utilities/Svg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { SimpleNavMenu } from "Components/Theme/SimpleNavMenu/SimpleNavMenu";
import { shareLinks } from "Utilities/Data";
import { useFetchGolfCourse } from "Hooks/useFetch/useFetch";
import { FlexColumn, Grid } from "Components/Theme/Flex/Flex";
import { FaSpinner } from "react-icons/fa";
import { IGolfCourse } from "Utilities/State.interfaces";
import { Assets } from "Assets/Assets";
import MainLayout from "HOC/MainLayout/MainLayout";
// import useDimensions from "react-use-dimensions";

export function ScreenModal(): JSX.Element {
   const { courseId, placeId } = useParams<{
      placeId?: string;
      courseId?: string;
   }>();

   const [loading, golfCourse] = useFetchGolfCourse(courseId);

   const navigate = useNavigate();

   console.log(placeId);

   const dataMapping = (c: IGolfCourse) => {
      return c
         ? [
            { id: 1, heading: "Name", text: c.name ?? "" },
            { id: 6, heading: "Season", text: c.season ?? "" },
            { id: 6, heading: "Greens", text: c.greens ?? "" },
            { id: 7, heading: "Par", text: c.par ?? "" },
            { id: 8, heading: "Holes", text: c.holes ?? "" },
            { id: 9, heading: "Length", text: c.length ?? "" },
            { id: 4, heading: "Year", text: c.year ?? "" },
            { id: 5, heading: "Architect", text: c.architect ?? "" },
         ]
         : [];
   };

   if (loading) {
      return (
         <MainLayout columns={3} docTitle="Calendar">
            <FlexColumn className="fixed items-center justify-center z-50 inset-0 w-100 h-100 overflow-hidden bg-gray-100">
               <FaSpinner className="animate-spin text-2xl text-black" />
            </FlexColumn>
         </MainLayout>
      );
   }

   if (golfCourse) {
      return (
         <div className="z-50 inset-0" role="dialog" aria-modal="true">
            <div
               onClick={() => navigate("..")}
               className="absolute left-3 top-3 cross-white cursor-pointer z-10 md:right-3 lg:right-3 xl:right-3 "
            >
               {AppSVG.close}
            </div>
            <div className="fixed inset-y-0 right-0 flex flex-col md:flex-row lg:flex-row xl:flex-row w-full overflow-y-scroll">
               <div className="sm:flex-1 md:flex-1 lg:flex-1">
                  <Carousel
                     showArrows={true}
                     autoPlay
                     autoFocus
                     width={"100%"}
                     thumbWidth={70}
                     useKeyboardArrows={true}
                     transitionTime={1000}
                     infiniteLoop={true}
                  >
                     <div>
                        <img src={golfCourse?.image ?? Assets.emptySrc} alt="" />
                        <p className="legend">Legend 1</p>
                     </div>
                     <div>
                        <img src={golfCourse?.image ?? Assets.emptySrc} alt="" />
                        <p className="legend">Legend 2</p>
                     </div>
                  </Carousel>
               </div>

               <div className="">
                  <div className="flex flex-col sm:flex-1 h-full overflow-y-scroll bg-white">
                     <div>
                        <div className="pb-1 sm:pb-6">
                           <div>
                              <div className="relative h-40 sm:h-56">
                                 <img
                                    className="absolute h-full w-full object-cover"
                                    src={golfCourse?.image ?? Assets.emptySrc}
                                    alt={golfCourse?.name}
                                 />
                              </div>
                              <div className="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                                 <div className="sm:flex-1">
                                    <div>
                                       <div className="flex items-center">
                                          <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                             {" "}
                                             {golfCourse?.name}{" "}
                                          </h3>
                                       </div>
                                       <p className="text-sm text-gray-500">
                                          {" "}
                                          {golfCourse?.email}{" "}
                                       </p>
                                    </div>
                                    <div className="mt-5 flex flex-wrap space-x-1 sm:space-y-0 sm:space-x-3">
                                       <button
                                          type="button"
                                          className="inline-flex w-full flex-1 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                                       >
                            Message
                                       </button>
                                       <button
                                          type="button"
                                          className="inline-flex w-full flex-1 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                                       >
                            Call
                                       </button>

                                       <span className="ml-3 inline-flex sm:ml-0">
                                          <div className="relative inline-block text-left">
                                             <Menu
                                                type="popover"
                                                className="right-0"
                                                button={{
                                                   title: AppSVG.ellipsisVertical,
                                                   className:
                                    "inline-flex items-center rounded-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-400 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2",
                                                }}
                                             >
                                                <SimpleNavMenu
                                                   menu={shareLinks()}
                                                   onClick={(obj) => console.log(obj)}
                                                   className="w-48 px-1"
                                                ></SimpleNavMenu>
                                             </Menu>
                                          </div>
                                       </span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                           <dl className="space-y-8 px-4 sm:space-y-6 sm:px-6">
                              <div>
                                 <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                        Address:
                                 </dt>
                                 <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                                    <p> {golfCourse?.address ?? "-"} </p>
                                 </dd>
                              </div>
                              <div>
                                 <h2 className="text-sm font-medium text-gray-500">
                        Tags
                                 </h2>
                                 <ul role="list" className="mt-2 leading-8">
                                    {dataMapping(golfCourse)
                                       .filter((f) => [1, 2, 3].includes(f.id))
                                       .map((item, index) => (
                                          <li className="inline mr-2" key={index}>
                                             <div className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5">
                                                <div className="text-sm font-medium text-gray-900">
                                                   {" "}
                                                   {item.text}{" "}
                                                </div>
                                             </div>
                                          </li>
                                       ))}
                                 </ul>
                              </div>
                              <Grid className="grid-cols-2">
                                 {dataMapping(golfCourse).map((item, index) => (
                                    <div className="break-all mb-3" key={index}>
                                       <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                                          {item.heading}
                                       </dt>
                                       <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 pr-2">
                                          {" "}
                                          {item.text}{" "}
                                       </dd>
                                    </div>
                                 ))}
                              </Grid>
                           </dl>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }

   return (
      <MainLayout columns={3} docTitle="Marketplace">
         <FlexColumn className="fixed items-center justify-center z-50 inset-0 w-100 h-100 overflow-hidden bg-gray-100 text-black font-medium text-lg">
        Something went wrong!.
         </FlexColumn>
      </MainLayout>
   );
}
