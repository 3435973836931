import * as React from 'react';

import { Link } from 'Components/Theme/Link/Link';
import { AppRoutes } from 'Routes/Routes';

const ProfileToaster = ({ condition }) => {

   const [show, setShow] = React.useState<boolean>(true);

   if(!condition && show){
      return (
         <div className="w-full mx-auto px-2 sm:px-6 lg:px-8 mb-3">
            <div className="p-2 rounded-lg bg-primary shadow-lg sm:p-3">
               <div className="flex items-center justify-between flex-wrap">
                  <div className="w-0 flex-1 flex items-center">
                     <span className="flex p-2 rounded-lg bg-primary">
                        <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                           viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                        </svg>
                     </span>
                     <p className="ml-3 font-medium text-white truncate text-sm">
                        <span className="md:hidden"> Please complete your profile.</span>
                        <span className="hidden md:inline"> Please complete your profile to activate your account.</span>
                     </p>
                  </div>
                  <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                     <Link
                        title="Update Now" activeClassName="" route={`${AppRoutes.user}/${AppRoutes.profile}`}
                        className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-primary bg-white hover:bg-indigo-50"
                     />
                  </div>
                  <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                     <button type="button"
                        className="-mr-1 flex p-2 rounded-md hover:bg-primary focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setShow(false)}
                     >
                        <span className="sr-only">Dismiss</span>
                        <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                           viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12" />
                        </svg>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      );
   }

   return null;
};

export default ProfileToaster;