import MainLayout from "HOC/MainLayout/MainLayout";
import React, { FC, useEffect, useState } from "react";
import { GolfBuddyCreation } from "./Creation";
import { IPost } from "Utilities/State.interfaces";
import { LoadData } from "Components/Theme/LoadData/LoadData";
import { IArgs, IResponse } from "Utilities/Interface";
import {
   isPostsRequest,
   isCreateNewPostRequest,
   acceptNotAcceptBuddyPost,
   isLikeUnlikePostRequest,
} from "Components/Screen/Activate/Redux/Thunk";
import useFetchDataWithRedux from "Hooks/useFetch/useFetchDataWithRedux";
import { PostTypesEnumerator } from "Utilities/Enums";
import { SimplePostItem } from "Components/Theme/PostItem/PostItem";
import { FlexColumn, Grid, Span } from "Components/Theme/Flex/Flex";
import { toast } from "react-toastify";
import usePostAction from "Hooks/PostAction/PostAction";
import { useFetchBuddyAvatars } from "Hooks/useFetch/useFetch";
import CommonMethods from "Utilities/CommonMethods";

export const GolfBuddy: FC<{ condition?: boolean }> = () => {
   const [user] = useFetchDataWithRedux();
   const [savedUnSavedPost, favUnFavPost, deletePost] = usePostAction();

   const [page, setPage] = useState<number>(1);
   const [search, setSearch] = useState<string>("");
   const [loading, setLoading] = useState<boolean>(true);
   const [posts, setPosts] = useState<IPost[]>([]);

   // const [userAvatar] = useFetchBuddyAvatars(post?.id);

   const [mypostId, setMyPostId] = useState<string>(undefined);

   // const [userAvatar, refreshRender] = useFetchBuddyAvatars(post?.id);

   useEffect(() => {
      getPosts({ page, limit: 100, searchTerm: search }, true);

      return () => {
         setPosts([]);
      };
   }, [page]);

   const getPosts = (args: IArgs, flag: boolean): void => {
      setLoading(flag);

      if (!args.searchTerm) {
         delete args.searchTerm;
      }

      isPostsRequest(
         { ...args, type: PostTypesEnumerator.BUDDY },
         (res: IResponse) => {
            setPosts(res.data);
            setLoading(false);
            setPage(args.page);
         }
      );
   };

   const createPost = (details): void => {
      isCreateNewPostRequest(
         { ...details, userId: user.id },
         (res: IResponse) => {
            if (res.status) {
               setMyPostId(res.data.data.createPost.id);
               getPosts({ page, limit: 100, searchTerm: search }, false);
               showToast(res.status, "Your post has been created successfully!");
            }
         }
      );
   };
   const onAction = (postId: string, actionId: number): void => {
      const post = posts.find((p) => p.id === postId);
      if (postId && actionId === 1) {
         favUnFavPost(postId, !post?.isFavorite, (res: IResponse) => {
            setPosts([...CommonMethods.modifyPostObject(res?.data, posts)]);
         });
      }

      if (postId && actionId === 2) {
         savedUnSavedPost(postId, !post?.isSaved, (res: IResponse) => {
            setPosts([...CommonMethods.modifyPostObject(res?.data, posts)]);
         });
      }

      if (postId && actionId === 4) {
         deletePost(postId, (res: IResponse) => {
            setSearch("");
            getPosts({ page, limit: 100, searchTerm: search }, false);
         });
      }

      if (postId && actionId === 3) {
         acceptNotAcceptBuddyPost(postId, user?.id, (res: IResponse) => {
            if (res?.status) {
               setSearch("");
               // getPosts({ searchTerm: search }, false);
            }
         });
      }
   };

   const likePost = (postId: string, userId: string) => {
      isLikeUnlikePostRequest(postId, userId, (res: IResponse) => {
         setPosts([...CommonMethods.modifyPostObject(res?.data, posts)]);
      });
   };

   const showToast = (success: boolean, msg: string): void => {
      if (success) {
         toast.success(msg);
      } else {
         toast.error(msg);
      }
   };

   function getLocation() {
      if (navigator.geolocation) {
         navigator.geolocation.getCurrentPosition(showPosition);
      }
   }

   function showPosition(position) {
      const latlng = {
         lat: position.coords.latitude,
         lng: position.coords.longitude,
      };
      return latlng;
   }
   return (
      <MainLayout columns={3} docTitle="Golf Buddy">
         <FlexColumn className="w-full">
            <h3 className="text-xl leading-6 font-medium text-black block">
          Golf Buddy
            </h3>
            <Span
               className="text-sm text-black block"
               title="Find your golf player when you are playing"
            />

            <GolfBuddyCreation
               createPost={(obj) => createPost(obj)}
               username={user?.username}
               postId={mypostId}
            />

            <LoadData loading={loading} arr={posts} msg="No posts available">
               <Grid className="grid-cols-1 mb-8">
                  {posts.map((post, index) => (
                     <SimplePostItem
                        post={post}
                        onAction={(postId: string, actionId: number) =>
                           onAction(postId, actionId)
                        }
                        postLikes={post?.likes?.length ? post?.likes?.length : ""}
                        likePost={() => likePost(post?.id, user?.id)}
                        acceptCount={10} // post.accept
                        activeUser={user}
                        key={index}
                        isBuddyPost={true}
                     />
                  ))}
               </Grid>
            </LoadData>
         </FlexColumn>
      </MainLayout>
   );
};
