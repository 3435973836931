import React, { FC, Fragment, useEffect, useState } from "react";
import { IResponse } from "Utilities/Interface";
import { IPost, IUser, IUserProfile } from "Utilities/State.interfaces";
import CommentFilters from "./CommentFilter/CommentFilter";
import MainComment from "./MainComment/MainComment";
import { getAllCommentsOfSinglePost } from "./Services/thunk";
import ShowComments from "./ShowComments/ShowComments";

interface IMainComment {
   userProfile: IUserProfile | undefined;
   post: IPost | undefined;
   activeUser: IUser;
}
const MainCommentSection: FC<IMainComment> = ({
   post,
   userProfile,
   activeUser,
}): JSX.Element => {
   const [comments, setComments] = useState<any>([]);
   const [isLoading, setIsLoading] = useState<boolean>(false);

   useEffect(() => {
      if (post?.id && post?.user?.id) {
         getAllComments(post?.id, post?.user?.id);
         setIsLoading(true);
      }
   }, []);

   const getAllComments = (postId: string, userId: string): void => {
      getAllCommentsOfSinglePost(
         { postId, userId, page: 1, limit: 50 },
         (res: IResponse) => {
            const { data, status } = res;
            if (status) {
               setComments(data);
               setIsLoading(false);
            }
         }
      );
   };
   return (
      <Fragment>
         <CommentFilters />
         <MainComment
            post={post}
            getAllComments={() => getAllComments(post?.id, post?.user?.id)}
            userProfile={userProfile}
            activeUser={activeUser}
            PlaceHolderComment={"Add a comment ..."}
         />
         <ShowComments
            userProfile={userProfile}
            post={post}
            activeUser={activeUser}
            comments={comments}
            isLoading={isLoading}
            getAllComments={() => getAllComments(post?.id, post?.user?.id)}
         />
      </Fragment>
   );
};

export default MainCommentSection;
