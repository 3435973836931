import * as React from 'react';

interface IAlertContainer {
   className?: string;
   children?: JSX.Element
}

export const AlertContainer:React.FC<IAlertContainer> = ({ children, className }) => {    

   return(
      <div className="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
         <div className="fixed inset-0 bg-gray-200 bg-opacity-75 transition-opacity"></div>
  
         <div className="fixed inset-0 overflow-y-auto" style={{ zIndex: 9999 }}>
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0 ease-out duration-300">
               <div className={`relative z-50 bg-white rounded-lg px-4 ${className} text-left overflow-hidden shadow-xl transform transition-all sm:p-6`}>
                  { children }
               </div>
            </div>
         </div>
      </div>
   );
};