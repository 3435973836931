import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppRoutes } from 'Routes/Routes';

// hoc
import MainLayout from 'HOC/MainLayout/MainLayout';

// components
import PageNotFound from 'Components/Theme/PageNotFound/PageNotFound';

// Screens
import { UserProfile } from './screens/Profile/Profile';
import { Settings } from './screens/Settings/Settings';
import { Blocking } from './screens/Blocking/Blocking';
import { Language } from './screens/Language/Language';
import { Privacy } from './screens/Privacy/Privacy';
import { Location } from './screens/Location/Location';
import { Notifications } from './screens/Notifications/Notifications';
import { Billing } from './screens/Billing/Billing';
import { BillingPlan } from './screens/BillingPlan/BillingPlan';
import useFetchDataWithRedux from 'Hooks/useFetch/useFetchDataWithRedux';
import withS3Bucket from 'HOC/WithS3Bucket/WithS3Bucket';
import { S3BucketConfig } from 'Utilities/Constants';

interface IProps {
   condition?: boolean;
   myBucket?: any;
}

const UserSettings:React.FC<IProps> = (props) => {

   const [ user, navigate, dispatch ] = useFetchDataWithRedux();

   const { myBucket } = props;

   return (

      <MainLayout changeNavigation columns={2} className="">
         <Routes>
            <Route path={AppRoutes.profile} element={<UserProfile condition={true} user={user} myBucket={myBucket} />} />
            <Route path={AppRoutes.settings} element={<Settings condition={true} user={user} userProfile={user?.userProfile} />} />
            <Route path={AppRoutes.privacy} element={<Privacy condition={true} />} />
            <Route path={AppRoutes.location} element={<Location condition={true} user={user} userProfile={user?.userProfile} />} />
            <Route path={AppRoutes.notifications} element={<Notifications condition={true} />} />
            <Route path={AppRoutes.language} element={<Language condition={true} />} />
            <Route path={AppRoutes.blocking} element={<Blocking condition={true} />} />
            <Route path={AppRoutes.billing} element={<Billing condition={true} />} />
            <Route path={AppRoutes.subscriptionPlan} element={<BillingPlan condition={true} />} />
            <Route path="*" element={<PageNotFound />} />
         </Routes>
      </MainLayout>

   );

};

export default withS3Bucket(UserSettings, S3BucketConfig.bucketName);