import { Assets } from "Assets/Assets";
import { AppRoutes } from "Routes/Routes";
import { ILinkObject, ITab, ISimpleMenu } from "./Interface";
import AppSVG from "./Svg";
import { Strings } from "./Strings";
import { Constants } from 'Utilities/Strings';
import { useParams } from "react-router";

export const topLinks: ILinkObject[] = [
   { id: 1, name: 'Golf Ball', route: AppRoutes.posts, image: Assets.golfBall },
   // { id: 2, name: 'Golfing', route: AppRoutes.golfBuddy, image: Assets.golfing },
   // { id: 3, name: 'Golfer', route: AppRoutes.golfClubs, image: Assets.golfPark }
];

export const sideBarMainLinks: ILinkObject[] = [
   { id: 0, name: 'Home', route: AppRoutes.posts, image: Assets.golfBall, disabled: false },
   { id: 1, name: 'Friends', route: AppRoutes.friends, image: Assets.golfStick, disabled: false },
   { id: 2, name: 'Golf Courses', route: AppRoutes.golfClubs, image: Assets.golfPark, disabled: false },
   { id: 3, name: 'Golf Buddy', route: AppRoutes.golfBuddy, image: Assets.golfing, disabled: false },
   { id: 4, name: 'Trainers', route: AppRoutes.trainers, image: Assets.golf, disabled: false },
   { id: 5, name: 'Marketplace', route: AppRoutes.marketPlace, image: Assets.golfClub, disabled: true },
   { id: 6, name: 'Club Reviews', route: AppRoutes.golfReviews, image: Assets.clubReviews, disabled: true },
   { id: 7, name: 'Tee Times', route: AppRoutes.teeTime, image: Assets.teeTime, disabled: true },
   { id: 8, name: 'Saved', route: AppRoutes.saved, image: Assets.saved, disabled: false },
   { id: 9, name: 'Favorites', route: AppRoutes.favorites, image: Assets.favorites, disabled: false }
];

export const sideBarUserLinks: ILinkObject[] = [
   { id: 1, name: 'Profile', route: AppRoutes.user+`/${AppRoutes.profile}`, image: AppSVG.userCircleSvg, svg: true, disabled: false },
   { id: 2, name: 'General', route: AppRoutes.user+`/${AppRoutes.settings}`, image: AppSVG.settingSvg, svg: true, disabled: false },
   { id: 3, name: 'Privacy', route: AppRoutes.user+`/${AppRoutes.privacy}`, image: AppSVG.privacySvg, svg: true, disabled: false },
   { id: 4, name: 'Location', route: AppRoutes.user+`/${AppRoutes.location}`, image: AppSVG.locationSvg, svg: true, disabled: false },
   { id: 5, name: 'Notifications', route: AppRoutes.user+`/${AppRoutes.notifications}`, image: AppSVG.notificationSvg, svg: true, disabled: false },
   { id: 6, name: 'Language', route: AppRoutes.user+`/${AppRoutes.language}`, image: AppSVG.languageSvg, svg: true, disabled: false },
   { id: 4, name: 'Subscription Plan', route: AppRoutes.user+`/${AppRoutes.subscriptionPlan}`, image: AppSVG.billingSvg, svg: true, disabled: true },
   { id: 4.1, name: 'Billing', route: AppRoutes.user+`/${AppRoutes.billing}`, image: AppSVG.billingSvg, svg: true, disabled: true },
   { id: 9, name: 'Block Users', route: AppRoutes.user+`/${AppRoutes.blocking}`, image: AppSVG.blockedSvg, svg: true, disabled: true }
];


export const clubsTabs: ITab[] = [
   { id: 1, name: 'All Clubs', route: AppRoutes.golfClubs, disbaled: false },
   { id: 2, name: 'Top Reviews', route: AppRoutes.topReviews, disbaled: true },
   { id: 3, name: 'Current City', route: AppRoutes.currentCity, disbaled: true }
];

export const friendsTabs: ITab[] = [
   { id: 1, name: 'All Friends', route: AppRoutes.friends },
   { id: 2, name: 'Recently Added', route: AppRoutes.saved, disbaled: true },
   { id: 3, name: 'Current City', route: AppRoutes.currentCity, disbaled: true }
];

export const trainersTabs: ITab[] = [
   { id: 1, name: 'All Trainers', route: AppRoutes.trainers, disbaled: false },
   { id: 2, name: 'Recently Added', route: AppRoutes.saved, disbaled: true },
   { id: 3, name: 'Golf Trainers', route: AppRoutes.currentCity, disbaled: true }
];

export const profileMenu: ISimpleMenu[] = [
   { id: 1, name: Strings.ProfileMenu_Profile },
   { id: 2, name: Strings.ProfileMenu_AccountSettings },
   { id: 3, name: Strings.ProfileMenu_SignOut }
];

export const genders: ISimpleMenu[] = [
   { id: 1, name: 'Male' },
   { id: 2, name: 'Female' }
];

export const postActions = (isSaved: boolean, isFav: boolean, isAccept?: boolean): ISimpleMenu[] => {
   return [
      { id: 0, name: 'View post' },
      { id: 1, name: isFav ? 'Remove from favorites' : 'Add to favorite' },
      { id: 2, name: isSaved ? 'Remove from saved' : 'Add to saved' },
      { id: 3, name : isAccept ? 'Unaccept' : 'Accept' },
      { id: 4, name: 'Delete' },


   ];
};

export const shareLinks = (): ISimpleMenu[] => {
   return [
      { id: 0, name: 'Share link' },
      { id: 1, name: 'Save Course' }
   ];
};

export const userTypes = () => {
   return [
      { id: 1, name: 'Golfer' },
      { id: 2, name: 'Golf Trainer' },
   ];
};