// import * as React from 'react';
// import { Popover } from '@headlessui/react';
// import Props from './Props';

// const Menu:React.FC<Props> = (props) => {

//    if(props.type === 'popover') {
//       return (
//          <Popover className="relative">

//             { ({ open }) => (
//                <React.Fragment>

//                   <Popover.Button className={props.button.className}>
//                      { props.button.title }
//                   </Popover.Button>

//                   { open ? <Popover.Panel className={`absolute z-10 ${props.className}`}>
//                      { props.children }
//                   </Popover.Panel> : null }

//                   {/* <Transition appear={true} show={open}          // react 18 not supporting Transition Tag in headless ui
//                      enter="transition duration-100 ease-out"
//                      enterFrom="transform scale-95 opacity-0"
//                      enterTo="transform scale-100 opacity-100"
//                      leave="transition duration-75 ease-out"
//                      leaveFrom="transform scale-100 opacity-100"
//                      leaveTo="transform scale-95 opacity-0"
//                   >

//                      <Popover.Panel className={`absolute z-10 ${props.className}`}>
//                         { props.children }
//                      </Popover.Panel>

//                   </Transition> */}

//                </React.Fragment>
//             ) }

//          </Popover>
//       );
//    }

//    return null;

// };

// export default Menu;

import * as React from "react";
import { Popover } from "@headlessui/react";
import Props from "./Props";

const Menu: React.FC<Props> = (props) => {
   if (props.type === "popover") {
      return (
         <Popover className="relative">
            <Popover.Button className={props.button.className}>
               {props.isTitleAsChildren && !props.button.title
                  ? props.isTitleAsChildren
                  : props.button.title}
            </Popover.Button>

            <Popover.Panel className={`absolute z-50 -left-60 ${props.className}`}>
               {props.children}
            </Popover.Panel>
         </Popover>
      );
   }

   return null;
};

export default Menu;
