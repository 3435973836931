import React, { FC } from 'react';

interface ISettingHeading{
   title: string;
}

export const SettingHeading:FC<ISettingHeading> = ({ title }) => {
   return (
      <div>
         <h3 className="text-lg leading-6 font-medium text-black">{ title }</h3>
      </div>      
   );
};