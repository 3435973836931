import { gql } from '@apollo/client';
import ApiClient from 'Services/ApiClient';
import CommonMethods from 'Utilities/CommonMethods';
import { IResponse } from 'Utilities/Interface';

export const IS_SIGNUP_QUERY = gql`
  mutation($inputUser: InputUser!) {
    signUp(inputUser: $inputUser)
  }
`;

export const isSignUpRequest = (inputUser, callback: (response: IResponse) => void) => {

   const variables = { inputUser };

   ApiClient.sendPostWithAuthWithCallback(variables, IS_SIGNUP_QUERY, async () => {

      callback(CommonMethods.responseProcess(true, inputUser));
      
   }, (err) => {
      
      callback(CommonMethods.responseProcess(false, err?.message));

   });

};