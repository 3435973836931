interface IRoute {
   none: string;
   empty: string;
   pathParam: string;
   signin: string;
   signup: string;
   forgotPassword: string;
   dashboard: string;
   profile: string;
   trainers: string;
   trainer: string;
   friends: string;
   friend: string;
   settings: string;
   golfClubs: string;
   saved: string;
   marketPlace: string;
   favorites: string;
   golfs: string;
   golfReviews: string;
   golfBuddy: string;
   user: string;
   topReviews: string;
   currentCity: string;
   security: string;
   privacy: string;
   location: string;
   notifications: string;
   language: string;
   blocking: string;
   billing: string;
   subscriptionPlan: string;
   posts: string;
   verified: string;
   teeTime: string;
   confirmation: string;
   resetPassword: string;
   calendar: string;
}

export const AppRoutes: IRoute = {
   none: '',
   empty: '/',
   pathParam: '?path',
   signin: 'signin',
   signup: 'signup',
   forgotPassword: 'forgot-password',
   dashboard: 'dashboard',
   profile: 'profile',
   trainers: 'trainers',
   trainer: 'trainer',
   friends: 'friends',
   friend: 'friend',
   settings: 'settings',
   saved: 'saved',
   marketPlace: 'marketplace',
   favorites: 'favorites',
   golfs: 'golfs',
   golfClubs: 'golf-courses',
   golfReviews: 'golfreviews',
   golfBuddy: 'golfbuddy',
   teeTime: 'tee-times',
   user: 'user',
   topReviews: 'top-reviews',
   currentCity: 'current-city',
   security: 'security',
   privacy: 'privacy',
   location: 'location',
   notifications: 'notifications',
   language: 'language',
   blocking: 'blocking',
   billing: 'billing',
   subscriptionPlan: 'subscriptionplan',
   posts: 'posts',
   verified: 'verified',
   confirmation: 'confirmation',
   resetPassword: 'reset-password',
   calendar : 'calendar'
};