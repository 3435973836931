import * as React from 'react';
import { FlexColumn, FlexRow, Image } from 'Components/Theme/Flex/Flex';
import { AlertContainer } from 'Components/Theme/Alert/AlertContainer';
import AppSVG from 'Utilities/Svg';
import { Assets } from 'Assets/Assets';
import { LoadData } from 'Components/Theme/LoadData/LoadData';
import { IGolfCourse } from 'Utilities/State.interfaces';
import { useFetchGolfCourse } from 'Hooks/useFetch/useFetch';

interface ICityCountryDetail {
   courseId: string;
   isOpen: boolean;
   onClose: () => void;
}

const CityCountryDetails:React.FC<ICityCountryDetail> = ({ courseId, onClose, isOpen }) => {

   const [ loading, golfCourse ] = useFetchGolfCourse(courseId);

   const dataMapping = (c: IGolfCourse) => {
      return c ? [
         { id: 1, heading: 'Name', text: c.name ?? 'NA' },
         { id: 2, heading: 'Description', text: c.description ?? 'NA' },
         { id: 3, heading: 'Address', text: c.address ?? 'NA' },
         { id: 4, heading: 'Country', text: c.country ?? 'NA' },
         { id: 5, heading: 'City', text: c.city ?? 'NA' },
         { id: 6, heading: 'State', text: c.state ?? 'NA' },
         { id: 7, heading: 'Email', text: c.email ?? 'NA' },
         { id: 8, heading: 'Website', text: c.website ?? 'NA' },
         { id: 9, heading: 'Industry', text: c.industry ?? 'NA' },
         { id: 10, heading: 'SicCode', text: c.sicCode ?? 'NA' },
         { id: 11, heading: 'Phone', text: c.phone ?? 'NA' }
      ] : [];
   };

   if(!isOpen){
      return null;
   }

   return (

      <AlertContainer className="lg:w-1/2 xl:w-1/2 md:w-1/2 w-full">
         
         <LoadData msg='No data found.' loading={loading} arr={[golfCourse]}>
            <FlexColumn className="w-full">
               <span className="absolute right-2 top-3 w-auto cursor-pointer text-base" onClick={onClose}>
                  { AppSVG.close }
               </span>

               <h6 className="font-medium text-base mt-2 block"> { golfCourse?.name } </h6>

               <div className="grid grid-cols-3 gap-4 pt-3 mb-2">
                  <div className="col-span-2">
                     <div className="aspect-w-3 aspect-h-3 rounded-lg relative w-full">
                        <Image
                           src={golfCourse?.image ?? Assets.emptySrc} 
                           defaultSrc={golfCourse?.image ?? Assets.emptySrc}
                           alt={ golfCourse?.name } className="w-full h-full object-center object-cover rounded"
                        />
                     </div>
                  </div>
                  <div className="col-span-1">
                     <FlexRow className="items-start justify-between w-full flex-wrap">
                        { dataMapping(golfCourse)?.map((data) => (
                           <div className="w-1/2 mb-2 pl-1" key={data.id}>
                              <dt className="text-sm font-bold text-gray-500">{data.heading}</dt>
                              <dd className="text-sm text-gray-900 break-words">{ data.text }</dd>
                           </div>
                        )) }
                     </FlexRow>
                  </div>
               </div>
            
            </FlexColumn>
         </LoadData>
           
      </AlertContainer>

   );

};

export default CityCountryDetails;