import React, { useEffect, useState, FC } from "react";
import GoogleMapReact from "google-map-react";
import { Constants } from "Utilities/Constants";

interface IMapGoogle {
   address: string;
   height: number;
   position: { lat: number; lng: number; };
   getPosition: (obj: { lat: number; lng: number; }) => void;
}

const defaultMapOptions = {
   zoomControl: true,
   mapTypeControl: true,
   scaleControl: false,
   streetViewControl: true,
   rotateControl: false,
   fullscreenControl: true,
};

const circleOptions = {
   strokeColor: '#FF0000',
   strokeOpacity: 0.8,
   strokeWeight: 1.2,
   fillColor: '#FF0000',
   fillOpacity: 0.35,
   clickable: false,
   draggable: true,
   editable: false,
   visible: true,
   radius: 30000,
   zIndex: 1
};

const ReactGoogleMap: FC<IMapGoogle> = (props) => {

   const { height, position, getPosition, address } = props;

   const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number; }>({ lat: 0, lng: 0 });

   const [_map, set_Map] = useState();

   useEffect(() => {
      setMapCenter(position);
      addMarker(position, _map);
   }, [position]);
   
   // once map is loaded, sets marker and its infowindow on google map
   const isMapLoaded = (map): void => {
      const bounds = new (window as any).google.maps.LatLngBounds();

      google.maps.event.addListener(map, "click", (event) => {
         addMarker(event.latLng, map);
      });

      // Add a marker at the center of the map.
      addMarker(position, map);

      map.fitBounds(bounds);
      set_Map(map);
   };

   // on marker dragged on the map get its current latlong and update on the sheet also it marks it as user supplied.
   const onMarkerDrag = (lat: number, lng: number): void => {
      getPosition({ lat: lat, lng: lng });
   };

   function toggleBounce(marker): void {
      if (marker.getAnimation() !== null) {
         marker.setAnimation(null);
      } else {
         marker.setAnimation(google.maps.Animation.BOUNCE);
      }
   }

   function addMarker(location: google.maps.LatLngLiteral, map: google.maps.Map): void {
      // Add the marker at the clicked location, and add the next-available label
      // from the array of alphabetical characters.
      const mark = new google.maps.Marker({
         position: location,
         animation: google.maps.Animation.DROP,
         map: map,
         title: address,
         draggable: true,
         optimized: true
      });

      new google.maps.Circle({ ...circleOptions, map, center: location });

      mark.addListener("dragend", () => {
         onMarkerDrag(mark.getPosition().lat(), mark.getPosition().lng());
      });

      const infoWindow = new google.maps.InfoWindow();

      mark.addListener("click", () => {
         toggleBounce(mark);
         infoWindow.close();
         infoWindow.setContent(mark.getTitle());
         infoWindow.open(mark.getMap(), mark);
      });
   }

   return (
      <div className={`gmap-container`} style={{ height: `${height}px`, position: 'relative' }}>
         <GoogleMapReact
            bootstrapURLKeys={{ key: Constants.GOOGLE_API_KEY }}
            center={mapCenter}
            zoom={5}
            options={defaultMapOptions}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map }) => isMapLoaded(map)}
         >                          
         </GoogleMapReact>            
      </div>
   );
};

export default ReactGoogleMap;
