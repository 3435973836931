import React, { FC } from 'react';
import Props from './Props';

import { SettingContainer } from '../../sub-components/SettingContainer/SettingContainer';

export const Notifications:FC<Props> = () => {

   return (
      
      <SettingContainer title="Notifications">

         <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
            <div className="pt-6 sm:pt-5">
               <div role="group" aria-labelledby="label-email">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                     <div>
                        <div className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                           id="label-email">By Email</div>
                     </div>
                     <div className="mt-4 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg space-y-4">
                           <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                 <input id="comments" name="comments" type="checkbox"
                                    className="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" />
                              </div>
                              <div className="ml-3 text-sm">
                                 <label htmlFor="comments"
                                    className="font-medium text-gray-700">Comments</label>
                                 <p className="text-gray-500">Get notified when someones posts a comment on a posting.</p>
                              </div>
                           </div>
                           <div>
                              <div className="relative flex items-start">
                                 <div className="flex items-center h-5">
                                    <input id="candidates" name="candidates" type="checkbox"
                                       className="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" checked={true} />
                                 </div>
                                 <div className="ml-3 text-sm">
                                    <label htmlFor="candidates"
                                       className="font-medium text-gray-700">Golf Clubs</label>
                                    <p className="text-gray-500">Get the best Golf Club suggestions located around you.</p>
                                 </div>
                              </div>
                           </div>
                           <div>
                              <div className="relative flex items-start">
                                 <div className="flex items-center h-5">
                                    <input id="offers" name="offers" type="checkbox"
                                       className="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" />
                                 </div>
                                 <div className="ml-3 text-sm">
                                    <label htmlFor="offers"
                                       className="font-medium text-gray-700">Offers</label>
                                    <p className="text-gray-500">Get notified for the best offers.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </SettingContainer>      

   );

};