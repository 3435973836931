import * as React from "react";

interface IPagination {
   onChange: (id: number) => void;
   currentPage: number;
   range: { from: number; to: number };
}

export const Pagination: React.FC<IPagination> = ({
   onChange,
   currentPage,
   range,
}) => {
   return (
      <nav
         className="h-36 bg-white flex items-center justify-between border-t border-gray-200"
         aria-label="Pagination"
      >
         <div className="hidden sm:block">
            <p className="text-sm text-gray-700 hidden">
          Showing
               <span className="font-medium"> {range?.from} </span>
          to
               <span className="font-medium"> {range?.to} </span>
          results
            </p>
         </div>
         <div className="flex-1 flex justify-between sm:justify-end">
            <button
               disabled={currentPage < 2}
               type="button"
               onClick={() => onChange(1)}
               className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
               {" "}
          Previous{" "}
            </button>
            <button
               type="button"
               onClick={() => onChange(2)}
               className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
               {" "}
          Next{" "}
            </button>
         </div>
      </nav>
   );
};
