// this file contains open close feature of comment
import Avatar from "Components/Theme/AvatarWithName/Avatar";
import { FlexColumn, FlexRow } from "Components/Theme/Flex/Flex";
import React, { FC } from "react";
import AddAComment from "../AddAComment/AddAComment";
import IComment from "../Props";

const MainComment: FC<IComment> = ({
   post,
   userProfile,
   activeUser,
   PlaceHolderComment,
   getAllComments,
}) => {
   return (
      <FlexColumn className="relative">
         <FlexRow className={""}>
            <div className={`flex-shrink-0 h-6 w-6 relative mt-4 ml-4`}>
               <Avatar
                  buddyAvatar={false}
                  src={userProfile?.profilePhoto ?? null}
                  title={
                     post?.user?.username?.substring(0, 2) ??
              activeUser?.username?.substring(0, 2)
                  }
                  className="flex-shrink-0 h-6 w-6 text-xs rounded-full"
               />
            </div>
            <AddAComment
               getAllComments={() => getAllComments(post?.id, post?.user?.id)}
               placeHolder={PlaceHolderComment}
               postId={post?.id}
               userId={post?.user?.id}
            />
         </FlexRow>
      </FlexColumn>
   );
};

export default MainComment;
