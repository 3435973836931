import { gql } from '@apollo/client';

export const IS_FORGOT_PASSWORD = gql`
  mutation($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const IS_RESET_PASSWORD = gql`
  mutation($newPassword: String! $oldPassword: String!){
    resetPassword(newPassword: $newPassword oldPassword: $oldPassword)
  }
`;