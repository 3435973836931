/* eslint-disable no-useless-escape */
import { PostAttachmentTypesEnumerator } from "./Enums";
import { IResponse, ISimpleMenu } from "./Interface";
import { IPost } from "./State.interfaces";

class Methods{

   public goToSection = (id: string): void => {
      if(id){
         const element = document.getElementById(id);
         if(element){
            element.scrollIntoView({ behavior: 'smooth' });      
         }
      }
   };

   public classNames(...classes): string {
      return classes.filter(Boolean).join(' ');
   }

   public responseProcess = (status: boolean, data): IResponse => {
      return {
         status, data
      };
   };

   public getResultWithEnum = (obj): ISimpleMenu[] => {
      return Object.values(obj).map((value: string, i) => {
         return {
            id: i, name: value
         };
      });
   };

   public modifyPostObject = (post: IPost, arr: IPost[]) : IPost[] => {
      if (post) {
         const findIndex = arr.findIndex((p) => p.id === post?.id);
         arr[findIndex] = post;
         return arr;
      }
      else return arr;
   };

   public replaceWithBr = (str: string): string => {
      return str.replace(/\n/g, "<br />").toString();
   };

   public unixStampToDateTime = (unixStamp): { newDate: string | number; newTime: string | number } => {
      const date = new Date(unixStamp).toLocaleDateString('en-US').toString();

      const time = new Date(unixStamp).toLocaleTimeString('en-US').toString();

      const arrDate = date.split('/');

      const month = arrDate[0];

      const day = arrDate[1];

      const year = arrDate[2];

      const arrTime = time.split(':');

      const arrTimeFormat = time.split(' ')[1];

      const newDate = month + '-' + day + '-' + year;

      const newTime = `${arrTime[0]}:${arrTime[1]} ${arrTimeFormat}`;

      return { newDate, newTime };
   };

   public unixStampToDate = (unixStamp): string | number => {
      const date = new Date(unixStamp).toLocaleDateString('en-US').toString();

      const arrDate = date.split('/');

      const month = arrDate[0];

      const day = arrDate[1];

      const year = arrDate[2];

      return month + '-' + day + '-' + year;
   };

   public getFileType = (type: string): string => {
      if(type === "image/jpeg" || type === "image/jpg" || type === "image/png"){
         return PostAttachmentTypesEnumerator.IMAGE;
      } else if(type === "audio/mpeg" || type === "audio/mp3"){
         return PostAttachmentTypesEnumerator.AUDIO;
      } else {
         return PostAttachmentTypesEnumerator.VIDEO;
      }
   };

   public validateEmail = (email: string): boolean => {
      // eslint-disable-next-line no-useless-escape
      const regularExpression =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
      return regularExpression.test(email.toLowerCase());
   };

}

const CommonMethods = new Methods();
export default CommonMethods;