import React, { FC } from "react";
import { ILinkObject } from "Utilities/Interface";
import { Link } from "../Link/Link";
import Props from "./Props";

export const SideBar: FC<Props> = ({ arr }) => {
   return (
      <div className="hidden lg:block sm:col-span-3 md:col-span-2 lg:col-span-2 bg-white px-2 py-4 sm:px-4 lg:px-8 mt-1 shadow relative ">
         {arr.map((item: ILinkObject, i: number) => (
            <Link
               className={`${
                  item.disabled
                     ? "text-gray-400 pointer-events-none "
                     : "text-gray-900 hover:text-primary hover:bg-gray-50  pointer-events-auto"
               } group flex items-center sm:text-md lg:text-md md:text-sm text-md px-2 py-2 text-base font-medium rounded-md`}
               key={i}
               route={item.route}
               activeClassName="lg:text-md text-primary md:text-sm sm:text-md bg-gray-50 hover:text-primary hover:bg-gray-50 group flex items-center px-2 py-2 text-base font-medium rounded-md"
            >
               <div className="flex">
                  <span className="mr-2 sm:w-5 sm:h-5 md:w-6 md:h-6">
                     {" "}
                     {item.svg ? (
                        item.image
                     ) : (
                        <img
                           alt={item.name}
                           src={item.image}
                           className="w-5 h-5 text-gray-400 group-hover:text-gray-500 flex-shrink-0 lg:h-6 lg:w-6 sm:w-5 sm:h-5 md:w-6 md:h-6"
                        />
                     )}
                  </span>
                  <span> {item.name} </span>
               </div>
            </Link>
         ))}

         <div className="absolute flex border-t h-auto border-gray-300 my-10 flex-col ">
            <span className="text-xs sm:text-xs md:text-sm   text-black block">
          Privacy - Terms - Cookies
            </span>
            <span className="text-xs sm:text-xs md:text-sm  text-black block">
          Copyright &copy; 2022
            </span>
         </div>
      </div>
   );
};
