import React, { FC, useState, useCallback, useEffect } from 'react';
import { Link } from 'Components/Theme/Link/Link';
import { AppRoutes } from 'Routes/Routes';
import Props from './Props';
import { DocumentTitle } from 'Components/Theme/DocumentTitle/DocumentTitle';
import { Label } from 'Components/Theme/FormElements/Label';
import InputField from 'Components/Theme/FormElements/Input';
import { IField, IResponse } from 'Utilities/Interface';
import { Button } from 'Components/Theme/FormElements/Button';
import { CheckBox } from 'Components/Theme/FormElements/CheckBox';
import { Assets } from "Assets/Assets";
import { toast } from 'react-toastify';
import { FaSpinner } from 'react-icons/fa';
import CommonMethods from 'Utilities/CommonMethods';
import { isForgotPasswordRequest } from './Redux/Thunk';
import { useCookies } from 'react-cookie';

export const ForgotPassword:FC<Props> = () => {

   const [cookies, setCookie, removeCookie] = useCookies(['email']);

   const [values, setValues] = useState<{ email: string; }>({ email: '' });
   const [emailCheck, setEmailCheck] = useState<boolean>(false);
   const [loading, setLoading] = useState<boolean>(false);

   useEffect(() => {
      if(cookies?.email){
         onChangeHandler(cookies?.email, 'email');
         setEmailCheck(true);
      }
      if(!cookies?.email){
         setEmailCheck(false);
      }
   }, [cookies]);

   const fields: IField[] = [
      { id: 1, key: 'email', disabled: false, placeholder: '', required: true, type: 'email', label: 'Email Address *' }
   ];

   const onChangeHandler = useCallback((value, key: string): void => {
      setValues({ ...values, [key]: value });
   }, [values]);

   const submit = (e): void => {
      e.preventDefault();

      if(!values.email){
         toast.error('Fields with (*) are required.');
      }
      else if(!CommonMethods.validateEmail(values.email)){
         toast.error('Kindly enter the valid email.');
      }
      else {
         setLoading(true);
         isForgotPasswordRequest(values.email, (res: IResponse) => {
            if(res.status){
               toast.success('Password recovery link has been sent to your registered email address.');
            } else {
               toast.error('Something went wrong.');
            }

            setLoading(false);
         });
      }
   };

   return (

      <DocumentTitle condition={true} title="Forgot Password">

         <div className="min-h-screen flex flex-col justify-center py-6 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
               <img
                  className="mx-auto w-24"
                  src={Assets.golfLogo}
                  alt="GolfGuiders"
               />
               <h2 className="mt-6 text-center text-3xl font-extrabold text-primary">Golf Guiders</h2>
               <p className="mt-5 text-center text-md text-gray-600">
                  Connect and share your Golf experience with people in your life. Share photos and videos, send messages and get updates.
               </p>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
               <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                  <div className="space-y-6">

                     { fields.map((field: IField, i: number) => (
                        <div key={i}>
                           <Label htmlFor={field.key} className="block text-sm font-medium text-gray-700" title={field.label} />
                           <div className="mt-1">
                              <InputField 
                                 htmlFor={field.key} placeholder={field.placeholder} required={field.required} value={values[field.key]}
                                 type={field.type} onChange={(val) => onChangeHandler(val, field.key)}
                                 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                              />                           
                           </div>
                        </div>
                     )) }

                     <div>
                        <Button 
                           className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                           disabled={loading} id="submit" loading={false} onClick={(e) => submit(e)}
                           title={ loading ? <FaSpinner className="animate-spin text-base text-white" /> : "Reset Password" }
                        />
                     </div>
                  </div>

                  <div className="mt-6">
                  
                     <div className="relative mb-2">
                        <div className="absolute inset-0 flex items-center">
                           <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center text-sm">
                           <span className="px-2 bg-white text-gray-500">Already have an account?</span>
                           <Link route={AppRoutes.signin} className="bg-white underline text-blue-500 pr-2" activeClassName="" title='Sign In' />
                        </div>
                     </div>
                                          
                  </div>

               </div>
            </div>
         </div>
         
      </DocumentTitle>

   );

};