import React from 'react';
import { Link } from '../Link/Link';

export default function PageNotFound(){
   return(
      <div className="min-h-screen items-center justify-center flex flex-col bg-white">
         <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">

            <div className="flex-shrink-0 flex justify-center">
               <Link className="inline-flex" route='.' activeClassName=''>
                  <React.Fragment>
                     <span className="sr-only">Workflow</span>
                     <img className="h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=600" alt="" />
                  </React.Fragment>
               </Link>
            </div>

            <div className="py-16">
               <div className="text-center">
                  <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">404 error</p>
                  <h1 className="mt-2 text-2xl font-extrabold text-gray-900 tracking-tight">Page not found.</h1>
                  <p className="mt-2 text-base text-gray-500">Sorry, we couldn’t find the page you’re looking for.</p>
                  <div className="mt-6">
                     <Link className="text-base font-medium text-indigo-600 hover:text-indigo-500" route='.' activeClassName=''>
                        <React.Fragment>
                           Go back home<span aria-hidden="true"> &rarr;</span>
                        </React.Fragment>
                     </Link>
                  </div>
               </div>
            </div>

            <nav className="flex justify-center space-x-4">
               {
                  ['Contact Support', 'Status', 'Twitter'].map((str, i) => (
                     <Link className="text-sm font-medium text-gray-500 hover:text-gray-600" route='.' activeClassName='' key={i} title={str}>
                        <span className="inline-block border-l border-gray-300" aria-hidden="true"></span>                                                   
                     </Link>
                  ))
               }
            </nav>

         </main>
      </div>
   );
}