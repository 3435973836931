import { Dialog } from '@headlessui/react';

interface IDialog{
   open: boolean; setOpen: () => void; children: JSX.Element;
}

export default function AppDialog({ open, setOpen, children }: IDialog) {

   return (
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen} open={open}>
         <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ease-out duration-300">
               
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                  &#8203;
            </span>
               
            { children }

         </div>
      </Dialog>
   );
}
