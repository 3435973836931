import ApiClient from 'Services/ApiClient';
import CommonMethods from 'Utilities/CommonMethods';
import { IResponse } from 'Utilities/Interface';
import { CREATE_NEW_COMMENT, DELETE_A_COMMENT, GET_ALL_COMMENT_OF_A_POST } from './query';

export const createAComment = (comments , callback: (response: IResponse) => void): void => {

   const variables = { comments };

   ApiClient.sendGetWithAuthWithCallback(variables, CREATE_NEW_COMMENT, (res) => {

      callback(CommonMethods.responseProcess(true, res.data.createPostComment));
      
   }, () => {

      callback(CommonMethods.responseProcess(false, []));

   });
 
};

export const getAllCommentsOfSinglePost = (args: {userId:string, postId:string, page: number, limit:number} , callback: (response: IResponse) => void): void => {

   const variables = { ...args };

   ApiClient.sendGetWithAuthWithCallback(variables, GET_ALL_COMMENT_OF_A_POST, (res) => {

      callback(CommonMethods.responseProcess(true, res.data.getPostComments));
      
   }, () => {

      callback(CommonMethods.responseProcess(false, []));

   });
 
};


export const deleteASingleComment = (args: {id:string} , callback: (response: IResponse) => void): void => {

   const variables = { ...args };

   ApiClient.sendGetWithAuthWithCallback(variables, DELETE_A_COMMENT, (res) => {

      callback(CommonMethods.responseProcess(true, res.data.deletePostComment));
      
   }, () => {

      callback(CommonMethods.responseProcess(false, []));

   });
 
};