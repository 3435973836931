import React, { useState } from "react";
import MainLayout from "HOC/MainLayout/MainLayout";
import { useParams } from "react-router-dom";
import TabsNavigation from "Components/Theme/TabNavigation/TabNavigation";
import { FlexColumn, FlexRow, Image } from "Components/Theme/Flex/Flex";
import { IUserProfile } from "Utilities/State.interfaces";
import useFetch, { useFetchBuddyPosts } from "Hooks/useFetch/useFetch";
import { isGetUserProfileRequestViaCallback } from "../UserSettings/screens/Profile/Redux/Thunk";
import { IResponse, ITab } from "Utilities/Interface";
import { Assets } from "Assets/Assets";
import { FaSpinner } from "react-icons/fa";
import { Link } from "Components/Theme/Link/Link";
import { AppRoutes } from "Routes/Routes";
import { TopProfileHeader } from "./TopProfileHeader";
import SportsHistory from "./sportsHistory";

export default function UserDetails() {
   const [load, countries, cities] = useFetch(undefined);

   const { trainerId, friendId } = useParams<{
      trainerId?: string;
      friendId?: string;
   }>();

   const [userProfile, setUserProfile] = React.useState<IUserProfile>(undefined);
   const [loading, setLoading] = React.useState<boolean>(true);

   React.useEffect(() => {
      if (trainerId || friendId) {
         isGetUserProfileRequestViaCallback(
            { userId: trainerId ? trainerId : friendId },
            (res: IResponse) => {
               setUserProfile(res.data);
               setLoading(false);
            }
         );
      } else {
         setLoading(false);
      }
   }, [friendId, trainerId]);

   const [isLoading, buddyPosts] = useFetchBuddyPosts(friendId);

   const friendDetailsTabs = [
      {
         id: 1,
         name: "Profile",
         route: `${AppRoutes.friends}/${friendId}`,
         disbaled: false,
         isActive: true,
      },
      // {
      //    id: 2,
      //    name: "Calendar",
      //    route: `${AppRoutes.friends}/${friendId}/${AppRoutes.calendar}`,
      //    disbaled: false,
      //    isActive: false,
      // },
   ];
   const dataMapping = userProfile
      ? [
         {
            id: 1,
            text: "Full Name",
            heading: userProfile?.firstname + " " + userProfile?.lastname,
         },
         { id: 2, text: "Email", heading: userProfile?.user?.email ?? "NA" },
         { id: 3, text: "Gender", heading: userProfile?.gender ?? "NA" },
         { id: 4, text: "Address", heading: userProfile?.address ?? "NA" },
         {
            id: 8,
            text: "Country",
            heading:
            countries.find((c) => c.id === userProfile?.countryId)?.name ??
            "NA",
         },
         {
            id: 9,
            text: "City",
            heading:
            cities.find((c) => c.id === userProfile?.cityId)?.name ?? "NA",
         },
         {
            id: 5,
            text: "State / Province",
            heading: userProfile?.state ?? "NA",
         },
         {
            id: 6,
            text: "Account",
            heading: userProfile?.user?.type?.split("_")?.join(" ") ?? "NA",
         },
         { id: 7, text: "About", heading: userProfile?.about ?? "NA" },
      ]
      : [];

   if (loading) {
      return (
         <MainLayout columns={2} docTitle="User Details">
            <FlexRow className="w-full py-3 items-center justify-center">
               <FaSpinner className="animate-spin text-base text-black" />
            </FlexRow>
         </MainLayout>
      );
   }

   if (!userProfile) {
      return (
         <MainLayout columns={2} docTitle="User Details">
            <FlexRow className="w-full py-3 text-center justify-center text-base text-gray-500">
          This user has not updated its profile. you cannot view it.
            </FlexRow>
         </MainLayout>
      );
   }

   return (
      <MainLayout columns={2} docTitle="User Details">
         <FlexColumn className="w-full">
            <article>
               <TopProfileHeader
                  coverPhoto={userProfile?.coverPhoto}
                  firstname={userProfile?.firstname}
                  lastname={userProfile?.lastname}
                  profilePhoto={userProfile?.profilePhoto}
                  user={userProfile?.user}
                  friendsTab={friendDetailsTabs}
               />

               <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                     {dataMapping?.map((data) => (
                        <div className="sm:col-span-1" key={data.id}>
                           <dt className="text-sm font-bold text-gray-500">
                              {data.text}
                           </dt>
                           <dd className="mt-1 text-sm text-gray-900">{data.heading}</dd>
                        </div>
                     ))}
                  </dl>
                  <br />
                  <br /> <br />
               </div>
               <div className="flex justify-center mb-6 w-full">
                  <div className="border-t border-gray-200 pt-2 pb-1 px-1 w-5/6"></div>
               </div>
               <h3 className="text-2xl text-gray-700 font-bold mb-10 ml-2">
            History
               </h3>

               {buddyPosts ? (
                  buddyPosts?.map((item: any, index) => (
                     <SportsHistory
                        key={index}
                        postId={item?.post?.id}
                        username={item?.post?.user?.username}
                        src={item?.post?.user?.userProfile?.profilePhoto ?? null}
                        title={item?.post?.user?.username?.substring(0, 2)}
                        loading={isLoading}
                        date={item?.post?.created}
                        desc={item?.post?.description}
                     />
                  ))
               ) : (
                  <div className="mb-40 ml-2 text-lg text-center text-gray-500">
              No Friends History Available.
                  </div>
               )}
            </article>
         </FlexColumn>
      </MainLayout>
   );
}
