import { createRoot } from 'react-dom/client';
// import ReactDOM from "react-dom/client";
import 'Styles/tailwind.css';
import 'Styles/global.css';
import App from './App';
import Toast from 'Components/Theme/Toast/Toast';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";

import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { configureStore } from 'Redux/Store';

import i18nExecute from 'Helpers/Internationalization/i18n';
import { I18nextProvider } from 'react-i18next';

import { ApolloProvider } from '@apollo/client';
import { client } from 'Apollo/ApolloClient';


const store = configureStore();
const persistor = persistStore(store);

const container: HTMLElement = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
   <Provider store={store}>
      <I18nextProvider i18n={i18nExecute}>
         <PersistGate
            loading={<div>Loading...</div>}
            persistor={persistor}
         >
            <Router>
               <ApolloProvider client={client}>
                  <App />
                  <Toast />
               </ApolloProvider>
            </Router>
         </PersistGate>
      </I18nextProvider>
   </Provider>
);

reportWebVitals();