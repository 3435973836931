import { FlexRow } from "Components/Theme/Flex/Flex";
import Svg from "libraries/AppSvg";
import { FC, useState } from "react";

interface IReplies {
   countReplies?: number;
   onClick?: () => void;
}
const ReplyActions: FC<IReplies> = ({ countReplies, onClick }) => {
   return (
      <FlexRow className="items-center justify-start flex-wrap w-full mt-1 mb-3 text-cc-bg-grey text-xs">
         {/* <span className="mr-3 cursor-pointer" onClick={onClick}>
        Reply
         </span>
         <span className="mr-3">
            {Svg.replyRegular("h-3 w-3 inline-flex", "#9799A2")}{" "}
            {`${countReplies + " "} Replies`}
         </span>
         <div
            onClick={onClick}
            className="text-cc-gray-40 cursor-pointer hover:underline hover:text-cc-green transition-all duration-300 ease-out mr-3"
         >
        Load previous replies
         </div> */}
      </FlexRow>
   );
};

export default ReplyActions;
