import { HttpStatusCodeEnumerator } from 'Utilities/Enums';
import { from, of, Observable, forkJoin } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { tokenService, userService } from './tokenService';
import { baseUrl } from 'Environment/environment';

let IS_REFRESHING = false;

export const refreshTokenInterceptorFetch = (uri: RequestInfo, intitalOptions: RequestInit): Promise<Response> =>
   from(
      fetch(uri, intitalOptions)
   ).pipe(
      switchMap(
         (data: Response) =>
            forkJoin({
               data: of(data),
               jsonResponse: from(data.clone().json())
            })
      ),
      switchMap(
         ({ data, jsonResponse }): Observable<Response> =>
            (!IS_REFRESHING && isResponseUnauthorized(jsonResponse)) ?
               doRefreshTokenRequest(uri).pipe(
                  map(
                     (response): boolean => {
                        if (isResponseUnauthorized(response))
                           return false;

                        setTheNewTokens(response);

                        return true;
                     }
                  ),
                  switchMap(
                     (isSuccessful: boolean): Observable<Response> => {
                        if (isSuccessful)
                           return refetchTheInitialRequest(
                              uri,
                              intitalOptions,
                              tokenService.getAccessToken()
                           );

                        // FORCE LOGOUT() add logout logic here. because first it checks if the token is expired.
                        // if token expired then send refresh token
                        // if refresh token is expired then it comes here. now it should force logout the user.
                        logoutWithRefreshToken();
                     }
                  ),
                  tap(
                     () => IS_REFRESHING = false
                  )
               )
               :
               of(data)
      )
   ).toPromise();

function isResponseUnauthorized(response): boolean {
   return response && response.errors && response.errors.length && response.errors[0].message === HttpStatusCodeEnumerator[HttpStatusCodeEnumerator.UNAUTHORIZED];
}

function doRefreshTokenRequest(uri: RequestInfo): Observable<Record<string, any>> {
   const myHeaders = new Headers();

   myHeaders.append('Content-Type', 'application/json');

   const requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
         query: 'mutation refreshToken($refreshToken:String!, $userId:String!) {\n    refreshToken(\n        refreshToken: $refreshToken\n        userId: $userId\n    ) {\n        accessToken\n        refreshToken\n    }\n}\n',
         variables: {
            userId: userService.getMyUserId(),
            refreshToken: tokenService.getRefreshToken()            
         }
      }),
      redirect: 'follow'
   };

   return from(
      fetch(uri, requestOptions).then(
         (response: Response): Promise<Record<string, any>> => response.json() as Promise<Record<string, any>>
      )
   );
}

function refetchTheInitialRequest(uri: RequestInfo, { headers: initialHeaders, ...otherInitialOptions }: RequestInit, newToken: string): Observable<Response> {
   const myHeaders = new Headers();

   myHeaders.append('Content-Type', 'application/json');

   const newRequestOptions: RequestInit = {
      ...otherInitialOptions,
      headers: {
         ...initialHeaders,
         authorization: newToken ? `Bearer ${newToken}` : undefined
      }
   };

   return from(
      fetch(uri, newRequestOptions)
   );
}

function setTheNewTokens({ data: { refreshToken: { refreshToken, accessToken } } }: Record<string, any>): void {
   tokenService.setAccessToken(accessToken);
   tokenService.setRefreshToken(refreshToken);
}

function logoutWithRefreshToken(): void {
   tokenService.logoutWithToken();
   window.location.href = `${baseUrl}/login`;
   window.location.reload();
}