import React, { FC } from "react";
import { ISimpleMenu } from "Utilities/Interface";

interface ISimpleNavMenu {
   menu: ISimpleMenu[];
   onClick: (obj: ISimpleMenu) => void;
   className?: string;
   children?: JSX.Element;
}

export const SimpleNavMenu: FC<ISimpleNavMenu> = ({
   menu,
   onClick,
   className,
   children,
}) => {
   return (
      <nav
         className={`space-y-1 shadow bg-white z-50 mt-1 rounded-md py-1 ${className}`}
         aria-label="Sidebar"
      >
         {children}
         {menu.map((m, index) => (
            <span
               key={index}
               className="text-gray-900 flex items-center hover:bg-gray-50 hover:text-primary px-3 cursor-pointer py-2 text-sm font-medium rounded-md"
               aria-current="page"
               onClick={() => onClick(m)}
            >
               <span className="truncate"> {m.name} </span>
            </span>
         ))}
      </nav>
   );
};
