import React, { FC } from 'react';
import Props from './Props';

import { NavLink } from "react-router-dom";

export const Link:FC<Props> = ({ className, route, title, activeClassName, children }) => {

   return(

      <NavLink to={`/${route}`} className={({ isActive }) => isActive ? activeClassName : className }>
         { title || children }
      </NavLink>

   );

};