import React, { FC, useState, useEffect, useCallback, useMemo } from "react";
import Props, { IValues } from "./Props";

import DragDropFile from "Components/Theme/DragDropFile/DragDropFile";
import ProgressList from "Components/Theme/ProgressList/ProgressList";
import CommonMethods from "Utilities/CommonMethods";
import { Label } from "Components/Theme/FormElements/Label";
import InputField from "Components/Theme/FormElements/Input";
import { ICity, IField, IResponse, ISelectFile } from "Utilities/Interface";
import AppSVG from "Utilities/Svg";
import { SettingContainer } from "Components/Screen/UserSettings/sub-components/SettingContainer/SettingContainer";
import { Fields1, Fields2, allSteps, getDataObject } from "./Data.seed";
import { Button, ButtonLabel } from "Components/Theme/FormElements/Button";
import { FaSpinner } from "react-icons/fa";
import useFetch from "Hooks/useFetch/useFetch";
import { genders } from "Utilities/Data";
import { Alert } from "Components/Theme/Alert/Alert";
import {
   isCreateProfileRequest,
   isUpdateProfileRequest,
   isGetUserProfileRequest,
   isGetCitiesByCountryRequest,
} from "./Redux/Thunk";
import { useDispatch } from "react-redux";
import { IUserProfile } from "Utilities/State.interfaces";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { Span } from "Components/Theme/Flex/Flex";
import { SelectOption } from "Components/Theme/FormElements/Select";
import useUploadFile from "Hooks/Upload/UploadFile";
import { Classes } from "Utilities/Constants";
import { toast } from "react-toastify";
import { CgSpinnerTwo } from "react-icons/cg";
import { useNavigate } from "react-router";

export const UserProfile: FC<Props> = ({ user }) => {
   const dispatch = useDispatch();
   const [load, countries] = useFetch(undefined);
   const [upload] = useUploadFile();
   const navigate = useNavigate();
   const [isOpen, setIsOpen] = useState<
   | {
      flag: boolean;
      title: string;
      heading: string;
      forError?: boolean;
      forSuccess?: boolean;
   }
   | undefined
   >(undefined);
   const [steps, setSteps] = useState<
   { id: number; status: string; name: string }[]
   >(
      allSteps.map((s) => {
         return {
            ...s,
            status: s.id === 1 ? "complete" : s.id === 2 ? "inprogress" : s.status,
         };
      })
   );
   const [cities, setCities] = React.useState<ICity[]>([]);
   const [loading, setLoading] = React.useState<boolean>(false);
   const [fileLoading, setFileLoading] = React.useState<{
      profile: boolean;
      cover: boolean;
      pUrl?: string;
      cUrl?: string;
   }>({ profile: false, cover: false, pUrl: "", cUrl: "" });
   const [values, setValues] = useState<IValues>({
      username: user?.username,
      about: "",
      email: user?.email,
      firstName: "",
      lastName: "",
      street: "",
      city: undefined,
      postal: "",
      state: "",
      country: undefined,
      gender: genders[0],
   });

   const [profilePhoto, setProfilePhoto] = React.useState<ISelectFile>({
      id: 0,
      src: null,
      name: "",
      file: null,
   });
   const [coverPhoto, setCoverPhoto] = React.useState<ISelectFile>({
      id: 0,
      src: null,
      name: "",
      file: null,
   });

   const PersonalFields: IField[] = Fields1;

   const BillingFields: IField[] = Fields2;

   useEffect(() => {
      if (user && !user?.userProfile) {
         dispatch(isGetUserProfileRequest({ userId: user?.id }));
      }
   }, []);

   useEffect(() => {
      if (user?.userProfile) {
         setPredefinedValues(user?.userProfile);
      } else {
         setValues({
            ...values,
            country: countries[0] ?? undefined,
         });
      }
   }, [user, countries]);

   const setPredefinedValues = (userProfile: IUserProfile) => {
      setValues({
         ...values,
         about: userProfile?.about,
         firstName: userProfile?.firstname,
         lastName: userProfile?.lastname,
         street: userProfile?.address,
         postal: userProfile?.postalCode,
         gender:
        genders?.find(
           (g) => g?.name?.toLowerCase() === userProfile?.gender?.toLowerCase()
        ) ?? undefined,
         country:
        countries.find((c) => c.id === userProfile?.countryId) ?? undefined,
         state: userProfile?.state,
         city: cities.find((c) => c.id === userProfile?.cityId) ?? undefined,
      });

      setProfilePhoto({
         id: Math.random(),
         src: userProfile?.profilePhoto,
         name: "",
         file: null,
      });
      setCoverPhoto({
         id: Math.random(),
         src: userProfile?.coverPhoto,
         name: "",
         file: null,
      });
      setFileLoading({
         profile: false,
         cover: false,
         pUrl: userProfile?.profilePhoto,
         cUrl: userProfile?.coverPhoto,
      });
   };

   const onChangeHandler = useCallback(
      (value, key: string) => {
         setValues({ ...values, [key]: value });

         if (key === "country") {
            getCities(value?.id);
         }
      },
      [values]
   );

   const getCities = (countryId: string): void => {
      isGetCitiesByCountryRequest({ countryId }, (res: IResponse) => {
         setCities(res.data);
      });
   };

   const uploadSelectedFile = (
      file: File,
      type: string,
      srcType: string
   ): void => {
      if (type === "profile")
         setFileLoading({ ...fileLoading, profile: true, cover: false });
      else if (type === "cover")
         setFileLoading({ ...fileLoading, cover: true, profile: false });

      upload(file, type, (res: string) => {
         setFileLoading({
            ...fileLoading,
            profile: false,
            cover: false,
            [srcType]: res,
         });
      });
   };

   const selectAttachment = useCallback(
      (file: File, profile: boolean) => {
         if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
               if (profile) {
                  setProfilePhoto({
                     id: Math.random(),
                     src: reader.result,
                     name: file.name,
                     file: file,
                  });

                  uploadSelectedFile(file, "profile", "pUrl");
               } else {
                  setCoverPhoto({
                     id: Math.random(),
                     src: reader.result,
                     name: file.name,
                     file: file,
                  });
                  uploadSelectedFile(file, "cover", "cUrl");
               }
            };
            reader.readAsDataURL(file);

            // covertImage(file);
         }
      },
      [profilePhoto?.file, coverPhoto]
   );

   const checkIncompleteSection = (secId: number): boolean => {
      return steps[secId].status.toLowerCase() === "incomplete";
   };

   const checkValues = useCallback(
      (obj: IValues) => {
         const section1 = [obj.username, obj.about]; // profilePhoto, coverPhoto

         const section2 = [
            obj.firstName,
            obj.lastName,
            obj.email,
            obj.country,
            obj.street,
            obj.city,
            obj.state,
            obj.postal,
            obj.gender,
         ];

         if (section1.includes("") || section1.includes(null)) {
            setSteps(
               steps.map((s) => {
                  return { ...s, status: s.id === 1 ? "inprogress" : "incomplete" };
               })
            );

            CommonMethods.goToSection(steps[0].name.toLowerCase());
         }

         if (!section1.includes("") && steps[0].status !== "complete") {
            setSteps(
               steps.map((s) => {
                  return {
                     ...s,
                     status:
                s.id === 1 ? "complete" : s.id === 2 ? "inprogress" : s.status,
                  };
               })
            );

            CommonMethods.goToSection(steps[1].name.toLowerCase());
         }

         // if(!section2.includes('') && !section2.includes(null) && steps[1].status !== 'complete'){
         //    setSteps(steps.map(s => {
         //       return { ...s, status: s.id === 3 ? 'inprogress' : 'complete'  };
         //    }));

      //    CommonMethods.goToSection(steps[2].name.toLowerCase());
      // }
      },
      [values]
   );

   const onSubmit = (obj: IValues): void => {
      const details = getDataObject(obj, user, fileLoading);

      if (details) {
         setLoading(true);

         if (!details?.firstname || !details?.lastname) {
            verifyResponse(
               { status: false, data: "Fields with (*) are required." },
               ""
            );
         } else if (!details?.postalCode) {
            verifyResponse(
               { status: false, data: "Postal/Zip code is missing." },
               ""
            );
         } else {
            geocodeByAddress(details?.postalCode)
               .then((results) => getLatLng(results[0]))
               .then((latLng) => {
                  details["latitude"] = latLng?.lat ?? 0;
                  details["longitude"] = latLng?.lng ?? 0;

                  if (user?.userProfile) {
                     dispatch(
                        isUpdateProfileRequest(
                           user?.userProfile.id,
                           details,
                           (res: IResponse) => {
                              verifyResponse(res, "updated");
                           }
                        )
                     );
                  } else {
                     dispatch(
                        isCreateProfileRequest(details, (res: IResponse) => {
                           verifyResponse(res, "updated");
                        })
                     );
                  }
               })
               .catch((e) => {
                  toast.error("Invalid Postal Code");
                  setLoading(false);
               });
         }
      }
   };

   const verifyResponse = (res: IResponse, text: string) => {
      if (res.status) {
         setIsOpen({
            flag: true,
            title: "Success",
            heading: `Your account has been ${text} successfully.`,
            forSuccess: true,
         });
      } else {
         setIsOpen({
            flag: true,
            title: "Error",
            heading: res?.data,
            forError: true,
         });
      }

      setLoading(false);
   };

   return (
      <SettingContainer title="Setup Profile">
         <React.Fragment>
            <ProgressList
               steps={steps}
               onClick={(step) => CommonMethods.goToSection(step.name.toLowerCase())}
            />

            <form
               className="space-y-8 divide-y divide-gray-200"
               method="post"
               encType="multipart/form-data"
            >
               <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                  <div
                     aria-disabled={checkIncompleteSection(0)}
                     id={steps[0].name.toLowerCase()}
                  >
                     <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Account Information
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  This information will be displayed publicly so be careful what
                  you share.
                        </p>
                     </div>

                     <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        {[
                           { label: "Username", value: user?.username },
                           { label: "Email address", value: user?.email },
                           {
                              label: "Account type",
                              value: user?.type === "GOLF_USER" ? "Golfer" : "Golf User",
                           },
                        ].map((item, index) => (
                           <div
                              key={index}
                              className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                           >
                              <label
                                 htmlFor="username"
                                 className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                 {item.label}
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2 pointer-events-none">
                                 <div className="max-w-lg flex rounded-md">
                                    <Span
                                       className="flex-1 block w-full min-w-0 sm:text-sm border-gray-300"
                                       title={item.value}
                                    />
                                 </div>
                              </div>
                           </div>
                        ))}
                     </div>
                  </div>

                  <div
                     aria-disabled={checkIncompleteSection(1)}
                     id={steps[1].name.toLowerCase()}
                     className={`pt-8 space-y-6 sm:pt-10 sm:space-y-5 ${
                        checkIncompleteSection(1) ? "pointer-events-none" : ""
                     }`}
                  >
                     <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Profile Information
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Use a permanent address where you can receive mail.
                        </p>
                     </div>

                     <div className="space-y-6 sm:space-y-5">
                        {PersonalFields.map((field: IField, i: number) =>
                           i === 3 ? (
                              <>
                                 <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                       htmlFor="biography"
                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                          Biography{" "}
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                       <textarea
                                          id="about"
                                          name="about"
                                          rows={3}
                                          value={values["about"]}
                                          onChange={(e) =>
                                             onChangeHandler(e.target.value, "about")
                                          }
                                          className="max-w-lg shadow-sm block w-full focus:ring-primary focus:border-primary sm:text-sm border border-gray-300 rounded-md"
                                       ></textarea>
                                       <p className="mt-2 text-sm text-gray-500">
                            Write a few sentences about yourself.
                                       </p>
                                    </div>
                                 </div>

                                 <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                       htmlFor="photo"
                                       className="block text-sm font-medium text-gray-700"
                                    >
                                       {" "}
                          Profile Picture{" "}
                                    </label>
                                    <DragDropFile
                                       getFile={(file) => console.log()}
                                       className="mt-1 sm:mt-0 sm:col-span-2"
                                    >
                                       <div className="flex items-center">
                                          <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                                             {fileLoading.profile && (
                                                <CgSpinnerTwo className="text-sm mx-4 my-4 animate-spin text-black text-center items-center " />
                                             )}
                                             {profilePhoto.src ? (
                                                <img
                                                   alt=""
                                                   src={profilePhoto.src}
                                                   className="h-12 w-12"
                                                />
                                             ) : (
                                                AppSVG.userAvatar
                                             )}
                                          </span>
                                          {!fileLoading.profile && (
                                             <ButtonLabel
                                                onClick={() => console.log()}
                                                title="Change"
                                                loading={false}
                                                className={`ml-5 w-24 bg-white ${Classes.basicButton}`}
                                             >
                                                <input
                                                   placeholder=""
                                                   alt=""
                                                   accept="image/*"
                                                   type="file"
                                                   className="hidden"
                                                   onChange={(e) => {
                                                      selectAttachment(e.target.files[0], true);
                                                   }}
                                                />
                                             </ButtonLabel>
                                          )}
                                          {/* <ButtonLabel
                                             onClick={() =>
                                                uploadSelectedFile(
                                                   profilePhoto.file,
                                                   "profile",
                                                   "pUrl"
                                                )
                                             }
                                             title="Upload"
                                             loading={fileLoading.profile}
                                             className={`ml-3 w-24 bg-white ${Classes.basicButton}`}
                                          /> */}
                                       </div>
                                    </DragDropFile>
                                 </div>

                                 <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                       htmlFor="cover-photo"
                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                       {" "}
                          Cover Photo
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                       <DragDropFile
                                          className="max-w-lg flex cursor-pointer justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md mb-4"
                                          getFile={(file) => selectAttachment(file, false)}
                                       >
                                          {!fileLoading?.cover ? (
                                             <label className="space-y-1 text-center">
                                                {coverPhoto.src ? (
                                                   <img
                                                      alt=""
                                                      src={coverPhoto.src}
                                                      className="mx-auto h-12 w-12 rounded"
                                                   />
                                                ) : (
                                                   <svg
                                                      className="mx-auto h-12 w-12 text-gray-400"
                                                      stroke="currentColor"
                                                      fill="none"
                                                      viewBox="0 0 48 48"
                                                      aria-hidden="true"
                                                   >
                                                      <path
                                                         d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                         strokeWidth="2"
                                                         strokeLinecap="round"
                                                         strokeLinejoin="round"
                                                      />
                                                   </svg>
                                                )}
                                                <div className="flex text-sm text-gray-600">
                                                   <label
                                                      htmlFor="file-upload"
                                                      className="relative cursor-pointer bg-white rounded-md font-medium text-primary"
                                                   >
                                                      <span>Upload a file</span>
                                                      <input
                                                         id="file-upload"
                                                         name="file-upload"
                                                         type="file"
                                                         accept="image/*"
                                                         onChange={(e) =>
                                                            selectAttachment(
                                                               e.target.files[0],
                                                               false
                                                            )
                                                         }
                                                         className="sr-only"
                                                      />
                                                   </label>
                                                   <p className="pl-1">or drag and drop</p>
                                                </div>
                                                <p className="text-xs text-gray-500">
                                  PNG, JPG, GIF up to 10MB
                                                </p>
                                                <input
                                                   id="file-upload"
                                                   name="file-upload"
                                                   type="file"
                                                   accept="image/*"
                                                   className="hidden"
                                                   onChange={(e) =>
                                                      selectAttachment(e.target.files[0], false)
                                                   }
                                                />
                                             </label>
                                          ) : (
                                             <CgSpinnerTwo className="text-sm mx-4 my-4 animate-spin text-black text-center items-center " />
                                          )}
                                       </DragDropFile>
                                       {/* <ButtonLabel
                                          onClick={() =>
                                             uploadSelectedFile(
                                                coverPhoto.file,
                                                "cover",
                                                "cUrl"
                                             )
                                          }
                                          title="Upload"
                                          loading={fileLoading.cover}
                                          className={`bg-white w-24 ${Classes.basicButton}`}
                                       /> */}
                                    </div>
                                 </div>
                              </>
                           ) : (
                              <div
                                 key={i}
                                 className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                              >
                                 <Label
                                    htmlFor={field.key}
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    title={field.label}
                                 />
                                 <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    {field?.dropdown ? (
                                       <SelectOption
                                          className="max-w-lg block focus:ring-primary focus:border-primary w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 border rounded-md"
                                          options={
                                             field.key === "city"
                                                ? cities
                                                : field.key === "country"
                                                   ? countries
                                                   : genders
                                          }
                                          setSelected={(obj) =>
                                             onChangeHandler(obj, field.key)
                                          }
                                          selected={values[field.key]}
                                       />
                                    ) : (
                                       <InputField
                                          htmlFor={field.key}
                                          placeholder={field.placeholder}
                                          required={field.required}
                                          value={values[field.key]}
                                          type={field.type}
                                          onChange={(val) => onChangeHandler(val, field.key)}
                                          readonly={field?.readonly}
                                          className={`max-w-lg block w-full shadow-sm focus:ring-primary focus:border-primary ${
                                             field.id === 3 || field.id === 5
                                                ? ""
                                                : "sm:max-w-xs"
                                          } sm:text-sm 
                                           ${
                                       field.required &&
                                             (!values[field.key] ||
                                               isOpen?.forError)
                                          ? "border-red-500"
                                          : "border-gray-300"
                                       } rounded-md`}
                                       />
                                    )}
                                 </div>
                              </div>
                           )
                        )}
                     </div>
                  </div>
                  {BillingFields.length ? (
                     <div
                        aria-disabled={checkIncompleteSection(2)}
                        id={steps[2].name.toLowerCase()}
                        className={`divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5 ${
                           checkIncompleteSection(2) ? "pointer-events-none" : ""
                        }`}
                     >
                        <div>
                           <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Billing Information
                           </h3>
                           <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    We'll always let you know about important changes, but you
                    pick what else you want to hear about.
                           </p>
                        </div>

                        <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
                           <div className="space-y-6 sm:space-y-5">
                              {BillingFields.map((field: IField, i: number) => (
                                 <div
                                    key={i}
                                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                                 >
                                    <Label
                                       htmlFor={field.key}
                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                       title={field.label}
                                    />
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                       <InputField
                                          htmlFor={field.key}
                                          placeholder={field.placeholder}
                                          required={field.required}
                                          value={values[field.key]}
                                          type={field.type}
                                          onChange={(val) => onChangeHandler(val, field.key)}
                                          className={`max-w-lg block w-full shadow-sm focus:ring-primary focus:border-primary sm:max-w-xs sm:text-sm border-red-400 border-0 rounded-md`}
                                       />
                                    </div>
                                 </div>
                              ))}
                           </div>
                        </div>
                     </div>
                  ) : null}
               </div>

               <div className="pt-5" style={{ height: 125 }}>
                  <div className="flex justify-end">
                     <Button
                        className="bg-white py-2 w-24 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        disabled={loading}
                        id="submit"
                        loading={false}
                        onClick={() => navigate("/posts")}
                        title="Cancel"
                     />
                     <Button
                        className="ml-3 inline-flex justify-center py-2 w-24 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        disabled={loading}
                        id="submit"
                        loading={false}
                        onClick={() => onSubmit(values)}
                        title={
                           loading ? (
                              <FaSpinner className="animate-spin text-base text-white" />
                           ) : (
                              "Save"
                           )
                        }
                     />
                  </div>
               </div>
            </form>

            {isOpen && (
               <Alert
                  isOpen={isOpen?.flag}
                  setIsOpen={() => setIsOpen(undefined)}
                  className=""
                  title={isOpen?.title}
                  description={isOpen?.heading}
                  forError={isOpen?.forError}
                  forSuccess={isOpen?.forSuccess}
                  setCalendarAlert={() => console.log()}
                  showClose={isOpen?.forError ? true : false}
                  submitBtnText="OK"
                  onSubmit={() => navigate("/posts")}
               />
            )}
         </React.Fragment>
      </SettingContainer>
   );
};
