import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Props from './Props';
import { SettingContainer } from 'Components/Screen/UserSettings/sub-components/SettingContainer/SettingContainer';
import { FlexColumn } from 'Components/Theme/Flex/Flex';
import ReactGoogleMap from './ReactGoogleMap';
import { Constants } from 'Utilities/Constants';
import { SearchLocation } from './SearchLocation';
import {
   geocodeByAddress,
   getLatLng,
} from 'react-places-autocomplete';

export const Location:FC<Props> = ({ userProfile }) => {

   const [position, setPosition] = useState<{ lat: number, lng: number }>(undefined);
   const [address, setAddress] = useState<string>('');
   const [country, setCountry] = useState("us");

   useEffect(() => {
      if(userProfile && userProfile?.latitude){
         setPosition({ lat: userProfile?.latitude, lng: userProfile?.longitude });
      } else {
         getGeoCodeByAddress('20013');
      }
   }, []);

   useEffect(() => {
      if(position){
         getAddress(position);
      }
   }, [position]);

   const getGeoCodeByAddress = (add): void => {
      geocodeByAddress(add)
         .then(results => getLatLng(results[0]))
         .then(latLng => setPosition(latLng))
         .catch(error => console.error('Error', error));
   };

   const getCity = (addressArray): string => {
      let city = '';
      for (let i = 0; i < addressArray.length; i++) {
         if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
            city = addressArray[i].long_name;
            return city;
         }
      }
   };

   const getArea = (addressArray): string => {
      let area = '';
      for (let i = 0; i < addressArray.length; i++) {
         if (addressArray[i].types[0]) {
            for (let j = 0; j < addressArray[i].types.length; j++) {
               if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
                  area = addressArray[i].long_name;
                  return area;
               }
            }
         }
      }
   };

   const getAddress = useCallback(({ lat, lng }) => {
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=false&key=${Constants.GOOGLE_API_KEY}`)
         .then((res) => res.json())
         .then((res1) => {
            console.log(res1);

            const address = res1.results[1].formatted_address,
               addressArray = res1.results[1].address_components,
               city = getCity(addressArray),
               area = getArea(addressArray);

            setAddress(address);
            setCountry(city);
         });      
   }, [position]);

   return (
      
      <SettingContainer title="Location">

         <FlexColumn className="w-full">
            
            <SearchLocation address={address} getPosition={setPosition} />
            
            <div className="py-4 sm:py-5 w-full">
               <ReactGoogleMap address={address} height={700} getPosition={setPosition} position={position} />
            </div>
         </FlexColumn>

      </SettingContainer>      

   );

};