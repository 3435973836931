import React, { FC } from 'react';
import Props from './Props';

import { SelectSwitch } from 'Components/Theme/FormElements/Switch';
import { Switch } from '@headlessui/react';
import { SettingContainer } from 'Components/Screen/UserSettings/sub-components/SettingContainer/SettingContainer';

export const Privacy:FC<Props> = () => {

   const [state, setState] = React.useState<{ [key: string]: boolean } | undefined>(undefined);

   return (
      
      <SettingContainer title="Privacy">

         <div className="divide-y divide-gray-200">

            <ul role="list" className="mt-2 divide-y divide-gray-200">
               <Switch.Group as="li" className="py-4 flex items-center justify-between">
                  <div className="flex flex-col">
                     <Switch.Label as="p" className="text-sm font-medium text-gray-900" passive>
                        Profile Visibility
                     </Switch.Label>
                     <Switch.Description className="text-sm text-gray-500">
                        Set the privacy on your profile
                     </Switch.Description>
                  </div>
                  <SelectSwitch checked={state?.hire} onChange={() => setState({ ...state, hire: !state?.hire })} />
               </Switch.Group>
            </ul>

            <div className="divide-y divide-gray-200 mb-4"></div>            

         </div>

      </SettingContainer>     

   );

};