import { Assets } from "Assets/Assets";
import Avatar from "Components/Theme/AvatarWithName/Avatar";
import { useFetchBuddyAvatars } from "Hooks/useFetch/useFetch";
import { Fragment } from "react";
import { useNavigate } from "react-router";
import CommonMethods from "Utilities/CommonMethods";
import { IPostBuddyAvatar } from "Utilities/State.interfaces";

interface IBuddyPost {
   loading?: boolean;
   postId?: string;
   src?: string;
   username?: string;
   title?: string;
   date?: number;
   desc?: string;
}
const SportsHistory: React.FC<IBuddyPost> = (props) => {
   const { postId, src, title, date, desc, username } = props;
   const navigate = useNavigate();

   const [isLoading, userAvatar] = useFetchBuddyAvatars(postId);

   return (
      <div className="w-full mb-20">
         <ol className="ml-5 ">
            <li className="border-l-2 border-primary">
               <div className="md:flex flex-start sm:w-full md:w-full lg:w-full">
                  <div className="bg-white border border-primary w-6 h-6 flex items-center justify-center rounded-full -ml-3.5">
                     <img src={Assets.golfing} className="w-4 h-4" alt="golf buddy" />
                  </div>
                  <div className="w-full p-6 rounded-lg shadow-lg bg-gray-100 ml-6 mb-10 md:w-cc-margin-410px justify-center">
                     <div className="flex justify-between mb-4 sm:text-xs">
                        <div className="flex flex-row ">
                           <Avatar
                              src={src ?? null}
                              title={title}
                              buddyAvatar={false}
                              className="flex-shrink-0 h-7 w-7 text-xs rounded-full"
                           />
                           <p className="ml-2 text-sm  text-gray-900">{username}</p>
                        </div>
                        <a className=" text-black duration-300 transition ease-in-out text-sm sm:text-xs">
                           {CommonMethods.unixStampToDate(date) +
                    " / " +
                    new Date(date).toLocaleTimeString()}
                        </a>
                     </div>
                     <p className="text-gray-700   mb-6 md:text-sm sm:text-sm">
                        {desc}
                     </p>
                     <div className="flex justify-between">
                        <button
                           onClick={() => navigate(`/posts/${postId}`)}
                           className="inline-flex items-center justify-center w-24 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        >
                  View Post
                        </button>
                        <div className="flex text-sm text-gray-500 items-center">
                           <div className="flex -space-x-1 relative z-0 mr-2">
                              {isLoading ? (
                                 <div className="flex flex-row animate-pulse relative z-30 -space-x-2">
                                    <div className="rounded-full bg-slate-300 h-8 w-8"></div>
                                    <div className="rounded-full bg-slate-300 h-8 w-8"></div>
                                    <div className="rounded-full bg-slate-300 h-8 w-8"></div>
                                 </div>
                              ) : (
                                 userAvatar?.map((value, index) => {
                                    if (value.userAvatar)
                                       return (
                                          <img
                                             key={index}
                                             className="relative z-30 inline-block h-8 w-8 rounded-full ring-2 ring-white"
                                             src={value?.userAvatar}
                                             alt={""}
                                          />
                                       );
                                    else
                                       return (
                                          <Avatar
                                             buddyAvatar={true}
                                             src={null}
                                             title={title}
                                             className="flex-shrink-0 h-8 w-8  text-xs rounded-full"
                                          />
                                       );
                                 })
                              )}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </li>
         </ol>
      </div>
   );
};

export default SportsHistory;
