export const Constants = {
   friendId: 'friendId',
   placeId: 'placeId',
   trainerId: 'trainerId',
   postId: 'postId',
   userId: 'userId',
   courseId: 'courseId'
};

export enum Strings {
   ProfileMenu_Profile = 'Your Profile',
   ProfileMenu_AccountSettings = 'Account Settings',
   ProfileMenu_SignOut = 'Sign Out'
}