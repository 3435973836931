import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { IReducers, IUser } from 'Utilities/State.interfaces';

const useFetchDataWithRedux = (): [
    user?: IUser,
    navigate?: (to: string, options?) => void,
    dispatch?: (args?) => void,
    isLoggedIn?: boolean
] => {

   const state = useSelector((state: IReducers) => state.loginReducer);
   const dispatch = useDispatch();

   const navigate = useNavigate();

   return React.useMemo(() => [state.user, navigate, dispatch, state.isLoggedIn], [state]);
};

export default useFetchDataWithRedux;