import * as React from 'react';
import { ICity, ICountry, ISimpleMenu } from 'Utilities/Interface';
import { Listbox, Transition } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/solid';

interface ISelectOption{
   className: string;
   setSelected: (option) => void;
   options: ICountry[] | ICity[] | ISimpleMenu[] | { id: string; name?: string; }[] | any;
   selected: ICountry | ICity | ISimpleMenu | { id: string; name?: string; };
}

export const SelectOption:React.FC<ISelectOption> = ({ className, options, setSelected, selected }) => {

   return (
      <Listbox value={selected} onChange={setSelected}>
         <div className={`relative mt-1 ${className}`}>
            <Listbox.Button className="relative w-full overflow-hidden rounded-lg text-left shadow focus:outline-none 
               py-2 px-3 cursor-pointer
                sm:text-sm z-40">
               <span className="block truncate">{ selected?.name ?? 'Choose'}</span>
               <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <SelectorIcon
                     className="h-5 w-5 text-gray-400"
                     aria-hidden="true"
                  />
               </span>
            </Listbox.Button>
            <Transition
               as={React.Fragment}
               leave="transition ease-in duration-100"
               leaveFrom="opacity-100"
               leaveTo="opacity-0"
            >
               <Listbox.Options className="absolute mt-1 max-h-60 z-50 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((option, optionIdx) => (
                     <Listbox.Option
                        key={optionIdx}
                        className={({ active }) =>
                           `relative select-none py-2 px-4 cursor-pointer ${
                              active ? 'bg-gray-100 text-black' : 'text-black'
                           }`
                        }
                        value={option}
                     >
                        {({ selected }) => (
                           <React.Fragment>
                              <span
                                 className={`block truncate ${
                                    selected ? 'font-medium' : 'font-normal'
                                 }`}
                              >
                                 {option?.name}
                              </span>                                 
                           </React.Fragment>
                        )}
                     </Listbox.Option>
                  ))}

                  { !options?.length && (
                     <Listbox.Option
                        key={1}
                        className="relative select-none py-2 px-4 pointer-events-none text-black"
                        value={undefined}
                     >
                        <span
                           className="block truncate font-normal"
                        >
                           Option not found
                        </span>
                     </Listbox.Option>
                  )}
               </Listbox.Options>
            </Transition>
         </div>
      </Listbox>
   );
};