import ApiClient from 'Services/ApiClient';
import CommonMethods from 'Utilities/CommonMethods';
import { IResponse } from 'Utilities/Interface';
import { IPostAction } from 'Utilities/State.interfaces';
import { GET_POSTS_BY_ACTIONS, IS_EMAIL_INVITATION, GET_A_POST } from './Query';

export const isPostsOfUserRequestByActions = (args: IPostAction, callback: (response: IResponse) => void): void => {

   const variables = { ...args };
 
   ApiClient.sendGetWithAuthWithCallback(variables, GET_POSTS_BY_ACTIONS, (res) => {
 
      callback(CommonMethods.responseProcess(true, res.data.getPostsByActions));
       
   }, () => {
 
      callback(CommonMethods.responseProcess(false, []));
 
   });
  
};

export const isGetPostRequest = (id: string, callback: (response: IResponse) => void): void => {

   const variables = { id };
 
   ApiClient.sendGetWithAuthWithCallback(variables, GET_A_POST, (res) => {
 
      callback(CommonMethods.responseProcess(true, res.data.getAPost));
       
   }, () => {
 
      callback(CommonMethods.responseProcess(false, undefined));
 
   });
  
};

export const isSendInvitationRequest = (email: string, callback: (response: IResponse) => void) => {

   const variables = { email };

   ApiClient.sendPostWithAuthWithCallback(variables, IS_EMAIL_INVITATION, async () => {

      callback(CommonMethods.responseProcess(true, true));
      
   }, (err) => {
      
      callback(CommonMethods.responseProcess(false, err?.message));

   });

};