import React, { useEffect, useState } from "react";
import MainLayout from "HOC/MainLayout/MainLayout";
import { IPost } from "Utilities/State.interfaces";
import { LoadData } from "Components/Theme/LoadData/LoadData";
import { IResponse } from "Utilities/Interface";
import { isGetPostRequest } from "Apollo/GeneralRedux/Thunk";
import { FlexColumn } from "Components/Theme/Flex/Flex";
import { SimplePostItem } from "Components/Theme/PostItem/PostItem";
import { useNavigate, useParams } from "react-router";
import useFetchDataWithRedux from "Hooks/useFetch/useFetchDataWithRedux";
import {
   acceptNotAcceptBuddyPost,
   isLikeUnlikePostRequest,
} from "../Activate/Redux/Thunk";
import usePostAction from "Hooks/PostAction/PostAction";
export default function SinglePost() {
   const [user] = useFetchDataWithRedux();

   const { postId } = useParams<{ postId: string }>();
   const [savedUnSavedPost, favUnFavPost, deletePost] = usePostAction();

   const [loading, setLoading] = useState<boolean>(true);
   const [post, setPost] = useState<IPost>(undefined);
   const navigate = useNavigate();
   useEffect(() => {
      getPost();

      return () => {
         setPost(undefined);
         setLoading(true);
      };
   }, []);

   const getPost = (): void => {
      isGetPostRequest(postId, (res: IResponse) => {
         setPost(res.data);
         setLoading(false);
      });
   };

   const onAction = (postId: string, actionId: number): void => {
      if (postId && actionId === 1) {
         favUnFavPost(postId, !post?.isFavorite, (res: IResponse) => {
            setPost(res?.data);
         });
      }

      if (postId && actionId === 2) {
         savedUnSavedPost(postId, !post?.isSaved, (res: IResponse) => {
            setPost(res?.data);
         });
      }

      if (postId && actionId === 4) {
         deletePost(postId, (res: IResponse) => {
            if (res?.status) navigate("/posts");
         });
      }

      if (postId && actionId === 3) {
         acceptNotAcceptBuddyPost(postId, user?.id, () => console.log());
      }
   };

   const likePost = (postId: string, userId: string) => {
      isLikeUnlikePostRequest(postId, userId, (res: IResponse) => {
         if (res.status) getPost();
      });
   };

   return (
      <MainLayout columns={3} docTitle={`Post / ${post?.title}`}>
         <FlexColumn className="w-full">
            <LoadData loading={loading} arr={[post]} msg="No posts available">
               <React.Fragment>
                  {[post].map((post: IPost, index) => (
                     <SimplePostItem
                        post={post}
                        onAction={(postId: string, actionId: number) =>
                           onAction(postId, actionId)
                        }
                        isBuddyPost={post?.type === "BUDDY"}
                        likePost={() => likePost(post?.id, user?.id)}
                        activeUser={user}
                        key={index}
                        postLikes={post?.likes?.length ? post?.likes?.length : ""}
                        removeMenu={false}
                     />
                  ))}
               </React.Fragment>
            </LoadData>
         </FlexColumn>
      </MainLayout>
   );
}
