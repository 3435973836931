import * as React from "react";

import { Assets } from "Assets/Assets";
import { FlexRow, Image } from "../Flex/Flex";
import { IUser } from "Utilities/State.interfaces";

export const UserItem: React.FC<{ onClick: () => void; user: IUser }> = ({
   user,
   onClick,
}) => {
   return (
      <li onClick={() => onClick()}>
         <div className="space-y-4 mb-14 cursor-pointer">
            <div className="aspect-w-3 aspect-h-2">
               <Image
                  src={user?.userProfile?.profilePhoto ?? Assets.emptyUserSrc}
                  defaultSrc={user?.userProfile?.profilePhoto ?? Assets.emptyUserSrc}
                  alt={user.username}
                  className="w-full h-40 sm:w-40 sm:h-40 lg:w-40 lg:h-40 object-center object-cover shadow-lg rounded-lg md:w-52 md:h-52"
               />
            </div>

            <div className="space-y-2">
               <div className="items-center justify-start">
                  <div>
                     <h3 className="text-lg leading-6 font-medium capitalize mb-2">
                        <span className="mr-2 mb-1 inline-block h-2 w-2 flex-shrink-0 rounded-full bg-green-400"></span>
                        {user.username}
                     </h3>
                  </div>
                  <div className="flex mt-1">
                     <p className="text-primary capitalize text-xs text-center border border-primary mx-1 rounded-full px-2 py-1">
                        {" "}
                        {user?.type?.split("_")?.join(" ")}{" "}
                     </p>
                     <p className="text-primary capitalize text-xs text-center border border-primary rounded-full px-2 py-1">
                        {" "}
                        {user?.status}{" "}
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </li>
   );
};
