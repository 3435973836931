import React, { FC, useEffect } from "react";
import { FindWithSearch } from "Components/Theme/FindWithSearch/FindWithSearch";
import Props from "./Props";

import { useNavigate } from "react-router-dom";
import { AppRoutes } from "Routes/Routes";
import MainLayout from "HOC/MainLayout/MainLayout";
import Menu from "Components/Theme/Menu/Menu";
import AppSVG from "Utilities/Svg";
import { SimpleNavMenu } from "Components/Theme/SimpleNavMenu/SimpleNavMenu";

export const MarketPlace: FC<Props> = () => {
   const navigate = useNavigate();

   const [loading, setLoading] = React.useState<boolean>(true);
   const [page, setPage] = React.useState<number>(1);
   const [search, setSearch] = React.useState<string>("");

   useEffect(() => {
      getMarketPlaces({ page, search });

      return () => {
         setPage(1);
         setLoading(true);
      };
   }, [page]);

   const getMarketPlaces = (args: { page?: number; search?: string }): void => {
      setLoading(true);
   };

   const dummyImages = [
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
      "https://media.istockphoto.com/id/619503842/photo/wazir-khan-mosque-lahore-pakistan.jpg?s=612x612&w=0&k=20&c=3vrR52_8PkripvQhwP_TOVCW4e9xvv4lIjlSRyPd9Hs=",
      "https://thumbs.dreamstime.com/b/beautiful-rain-forest-ang-ka-nature-trail-doi-inthanon-national-park-thailand-36703721.jpg",
      "https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?cs=srgb&dl=pexels-pixabay-268533.jpg&fm=jpg",
      "https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?cs=srgb&dl=pexels-anjana-c-674010.jpg&fm=jpg",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMma1RsDUuSNsB1CLxhrSbNJH9OApmgGQndQ&usqp=CAU",
      "https://images.ctfassets.net/hrltx12pl8hq/a2hkMAaruSQ8haQZ4rBL9/8ff4a6f289b9ca3f4e6474f29793a74a/nature-image-for-website.jpg?fit=fill&w=480&h=320",
      "https://cdn.pixabay.com/photo/2018/08/14/13/23/ocean-3605547__340.jpg",
      "http://c.files.bbci.co.uk/C870/production/_112921315_gettyimages-876284806.jpg",
      "https://bd.gaadicdn.com/processedimages/royal-enfield/classic350/source/classic350612f277880878.jpg",
      "https://www.w3schools.com/howto/img_forest.jpg",
      "https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg",
      "https://www.w3schools.com/css/img_5terre_wide.jpg",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnSsLWVn6ZOrtsgl4lhc4C9DnRGk8ituA04w&usqp=CAU",
   ];

   return (
      <MainLayout columns={3} docTitle="MarketPlace">
         <div className="flex flex-col w-full">
            <FindWithSearch
               search={search}
               setSearch={setSearch}
               pressEnter={(val) => getMarketPlaces({ search: val })}
               heading="MarketPlace"
               subHeading="Find your own"
            />
            <h1 className="text-2xl font-bold mt-10">Today's Pick</h1>
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-7 mt-7 mb-14">
               {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((place, i) => (
                  <div
                     onClick={() => navigate(`/${AppRoutes.marketPlace}/${i}`)}
                     className="flex flex-col col-span-1 mb-2 cursor-pointer rounded-lg"
                     key={i}
                  >
                     <img
                        alt=""
                        src={dummyImages[i]}
                        className="w-full shadow-sm h-52 rounded-lg object-cover"
                     />
                     <h2 className="font-bold text-lg mt-2">$10</h2>
                     <h2>Beautiful Views Of Random Places</h2>
                     <span className="text-sm mt-1 text-gray-500">wah, pakistan</span>

                     {/* <div className="w-full">
                        <div className="flex items-center justify-between w-full">
                           <div>
                              <span className="text-base">MON 10, JAN</span>
                           </div>
                           <div className="flex items-center z-0 relative">
                              <button
                                 type="button"
                                 className="inline-flex justify-center mr-2 px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-slate-100 hover:bg-gray-50 focus:outline-none"
                              >
                                 <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                 >
                                    <path
                                       strokeLinecap="round"
                                       strokeLinejoin="round"
                                       d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                                    />
                                 </svg>
                                 <span>Interested</span>
                              </button>
                              <Menu
                                 type="popover"
                                 className="right-0"
                                 button={{
                                    title: AppSVG.ellipsisHorizontal,
                                    className:
                          "inline-flex justify-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-slate-100 hover:bg-gray-50 focus:outline-none",
                                 }}
                              >
                                 <SimpleNavMenu
                                    className="w-52 px-1"
                                    menu={[
                                       { id: 1, name: "Saved" },
                                       { id: 2, name: "Delete" },
                                    ]}
                                    onClick={(obj) => console.log(obj)}
                                 ></SimpleNavMenu>
                              </Menu>
                           </div>
                        </div>
                        <h5 className="text-lg font-bold mb-1">Market Place</h5>
                        <span className="text-sm text-gray-500 block font-medium">
                  Online
                        </span>
                        <span className="text-sm mt-1 text-gray-500 block">
                  98 Interested, 5 went
                        </span>
                     </div> */}
                  </div>
               ))}
            </div>
         </div>
      </MainLayout>
   );
};
