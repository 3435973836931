import React, { FC } from 'react';
import Props from './Props';

import { SettingContainer } from 'Components/Screen/UserSettings/sub-components/SettingContainer/SettingContainer';
import { ViewReceipt } from './ViewReceipt';
import { CheckPermission } from 'Components/Theme/CheckPermission/CheckPermission';

const payments = [
   {
      id: 1,
      date: '1/1/2020',
      datetime: '2020-01-01',
      description: 'Business Plan - Annual Billing',
      amount: 'CA$109.00',
      href: '#',
   },
   {
      id: 1,
      date: '1/1/2020',
      datetime: '2020-01-01',
      description: 'Business Plan - Annual Billing',
      amount: 'CA$109.00',
      href: '#',
   },
   {
      id: 1,
      date: '1/1/2020',
      datetime: '2020-01-01',
      description: 'Business Plan - Annual Billing',
      amount: 'CA$109.00',
      href: '#',
   }
];

export const Billing:FC<Props> = () => {

   const [view, setView] = React.useState<boolean>(false);

   return (
      
      <CheckPermission condition={false}>

         <SettingContainer title="Biling History">

            <section aria-labelledby="billing-history-heading" className="mt-6">
               <div className="overflow-hidden border-t border-gray-200">
                  <table className="min-w-full divide-y divide-gray-200">
                     <thead className="bg-gray-50">
                        <tr>
                           <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                           >
                                  Date
                           </th>
                           <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                           >
                                  Description
                           </th>
                           <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                           >
                                  Amount
                           </th>
                           <th
                              scope="col"
                              className="relative px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                           >
                              <span className="sr-only">View receipt</span>
                           </th>
                        </tr>
                     </thead>
                     <tbody className="bg-white divide-y divide-gray-200">
                        {payments.map((payment) => (
                           <tr key={payment.id} className="cursor-pointer">
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                 <time dateTime={payment.datetime}>{payment.date}</time>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                 {payment.description}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                 {payment.amount}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                 <span className="text-indigo-600 hover:text-indigo-600" onClick={() => setView(true)}>
                                      View receipt
                                 </span>
                              </td>
                           </tr>
                        ))}
                     </tbody>
                  </table>
               </div>

               <ViewReceipt open={view} setOpen={() => setView(!view)} />

            </section>

         </SettingContainer>     

      </CheckPermission>

   );

};