import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import { AppRoutes } from "Routes/Routes";
import { Redirect } from "Routes/Redirect";
import { Constants } from "Utilities/Strings";

// components
import PageNotFound from "Components/Theme/PageNotFound/PageNotFound";
import UserDetails from "Components/Screen/Modals/UserDetails";

// Screens
import Posts from "Components/Screen/Activate/AllPosts";
import Favorites from "Components/Screen/Favorites/Favorites";
import { Saved } from "Components/Screen/Saved/Saved";
import { Friends } from "Components/Screen/Friends/Friends";
import GolfClubs from "Components/Screen/GolfClubs/GolfClubs";
import UserSettings from "Components/Screen/UserSettings/UserSettings";
import { Trainers } from "Components/Screen/Trainers/Trainers";
import { MarketPlace } from "Components/Screen/MarketPlace/MarketPlace";
import GolfReviews from "Components/Screen/GolfReviews/GolfReviews";
import { ScreenModal } from "Components/Screen/Modals/ScreenModal";
import { GolfBuddy } from "Components/Screen/GolfBuddy/GolfBuddy";
import Dashboard from "Components/Screen/Dashboard/Dashboard";
import { SignIn } from "Components/Screen/SignIn/SignIn";
import { SignUp } from "Components/Screen/SignUp/SignUp";
import { ForgotPassword } from "Components/Screen/ForgotPassword/ForgotPassword";
import SinglePost from "Components/Screen/Modals/SinglePost";
import ConfirmationAccount from "Components/Screen/SignIn/Confirmation";
import { ResetPassword } from "Components/Screen/ForgotPassword/ResetPassword";
import Calendar from "Components/Screen/Calendar/Calendar";
import MyCalendar from "Components/Screen/Calendar/Calendar";
import SingleMarketPlacePost from "Components/Screen/Modals/SingleMarketPlacePost";

function App() {
   return (
      <Routes>
         <Route
            path={AppRoutes.empty}
            element={<Redirect to={AppRoutes.signin} />}
         />

         <Route path={AppRoutes.posts} element={<Outlet />}>
            <Route index element={<Posts condition={true} />} />
            <Route path={`:${Constants.postId}`} element={<SinglePost />} />
         </Route>

         <Route path={AppRoutes.signin} element={<Outlet />}>
            <Route index element={<SignIn verified={false} />} />
            <Route
               path={`${AppRoutes.confirmation}/:${Constants.userId}`}
               element={<ConfirmationAccount />}
            />
         </Route>

         <Route path={AppRoutes.resetPassword} element={<Outlet />}>
            <Route index element={<ResetPassword condition={true} />} />
         </Route>

         <Route path={AppRoutes.signup} element={<SignUp condition={true} />} />

         <Route
            path={AppRoutes.forgotPassword}
            element={<ForgotPassword condition={true} />}
         />

         <Route
            path={AppRoutes.dashboard}
            element={<Dashboard condition={true} />}
         />

         <Route path={AppRoutes.golfClubs} element={<Outlet />}>
            <Route index element={<GolfClubs condition={true} />} />
            <Route path={`:${Constants.courseId}`} element={<ScreenModal />} />
         </Route>

         <Route
            path={AppRoutes.golfReviews}
            element={<GolfReviews condition={true} />}
         />

         <Route
            path={AppRoutes.favorites}
            element={<Favorites condition={true} />}
         />

         <Route path={AppRoutes.saved} element={<Saved condition={true} />} />

         <Route path={AppRoutes.golfBuddy} element={<GolfBuddy />} />

         <Route path={AppRoutes.friends} element={<Outlet />}>
            <Route index element={<Friends />} />
            <Route path={AppRoutes.golfBuddy} element={<GolfBuddy />} />
            <Route path={`:${Constants.friendId}`} element={<UserDetails />} />
            <Route
               path={`:${Constants.friendId}/${AppRoutes.calendar}`}
               element={<MyCalendar />}
            />
         </Route>

         <Route path={AppRoutes.marketPlace} element={<Outlet />}>
            <Route index element={<MarketPlace condition={true} />} />
            <Route
               path={`:${Constants.placeId}`}
               element={<SingleMarketPlacePost />}
            />
         </Route>

         <Route path={AppRoutes.trainers} element={<Outlet />}>
            <Route index element={<Trainers condition={true} />} />
            <Route path={`:${Constants.trainerId}`} element={<UserDetails />} />
         </Route>

         <Route
            path={AppRoutes.user}
            element={<Redirect to={AppRoutes.profile} />}
         />
         <Route path={AppRoutes.user + "/*"} element={<UserSettings />} />

         <Route path="*" element={<PageNotFound />} />
      </Routes>
   );
}

export default App;
