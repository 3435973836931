import { IS_LOGIN, IS_LOGOUT, IS_UPDATE_PROFILE } from "Redux/Types";
import { ILoginState, IAction } from "Utilities/State.interfaces";

const initialState: ILoginState = {
   isLoggedIn: false,
   user: undefined
};

export const loginReducer = (state = initialState, action: IAction): ILoginState => {

   const { payload, type } = action;

   switch(type){

      case IS_LOGIN: {
         return {
            ...state,
            isLoggedIn: true,
            user: payload         
         };
      }

      case IS_UPDATE_PROFILE: {
         return {
            ...state,
            user: {
               ...state.user,
               userProfile: payload
            }
         };
      }

      case IS_LOGOUT: {
         return {
            ...initialState
         };
      }

      default:
         return state;

   }

};