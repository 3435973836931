import React, { FC, useCallback, useState, useEffect } from "react";
import { Link } from "Components/Theme/Link/Link";
import { AppRoutes } from "Routes/Routes";
import Props from "./Props";
import { DocumentTitle } from "Components/Theme/DocumentTitle/DocumentTitle";
import { Label } from "Components/Theme/FormElements/Label";
import InputField from "Components/Theme/FormElements/Input";
import { IField, IResponse } from "Utilities/Interface";
import { Button } from "Components/Theme/FormElements/Button";
import AppSVG from "Utilities/Svg";
import { CheckBox } from "Components/Theme/FormElements/CheckBox";
import { isLoginRequest } from "./Redux/Thunk";
import { Redirect } from "Routes/Redirect";
import { tokenService } from "Services/tokenService";
import { Alert } from "Components/Theme/Alert/Alert";
import { FaSpinner } from "react-icons/fa";
import useFetchDataWithRedux from "Hooks/useFetch/useFetchDataWithRedux";
import { Assets } from "Assets/Assets";
import { useCookies } from "react-cookie";

export const SignIn: FC<Props> = () => {
   const [cookies, setCookie, removeCookie] = useCookies(["username"]);

   const [user, navigate, dispatch, isLoggedIn] = useFetchDataWithRedux();

   const [values, setValues] = useState<{ username: string; password: string }>({
      username: "",
      password: "",
   });
   const [emailCheck, setEmailCheck] = useState<boolean>(false);
   const [isOpen, setIsOpen] = useState<
   | { flag: boolean; title: string; heading: string; error: boolean }
   | undefined
   >(undefined);
   const [loading, setLoading] = useState<boolean>(false);

   const [fields, setFields] = useState<IField[]>([
      {
         id: 1,
         key: "username",
         disabled: false,
         placeholder: "",
         required: true,
         type: "text",
         label: "Username *",
      },
      {
         id: 2,
         key: "password",
         disabled: false,
         placeholder: "",
         required: true,
         type: "password",
         label: "Password *",
      },
   ]);

   useEffect(() => {
      if (cookies?.username) {
         onChangeHandler(cookies?.username, "username");
         setEmailCheck(true);
      }
      if (!cookies?.username) {
         setEmailCheck(false);
      }
   }, [cookies]);

   const onChangeHandler = useCallback(
      (value, key: string) => {
         setValues({ ...values, [key]: value });
      },
      [values]
   );

   const showHidePassword = useCallback(
      (id: number, index: number) => {
         fields[index] = {
            ...fields[index],
            type: fields[index].type === "password" ? "text" : "password",
         };

         setFields([...fields]);
      },
      [fields]
   );

   const onLogin = (): void => {
      if (!values.username || !values.password) {
         setIsOpen({
            flag: true,
            title: "Error",
            heading: "Fields with (*) are required.",
            error: true,
         });
      } else if (values.password.length < 8) {
         setIsOpen({
            flag: true,
            title: "Error",
            heading: "Password should be greater than 7 characters.",
            error: true,
         });
      } else {
         setLoading(true);
         dispatch(
            isLoginRequest(values, (response: IResponse) => {
               setLoading(false);

               if (!response.status) {
                  setIsOpen({
                     flag: true,
                     title: "Error",
                     heading: "Invalid username/password.",
                     error: true,
                  });
               }
            })
         );
      }
   };

   if (user && isLoggedIn && tokenService.getAccessToken())
      return <Redirect to={`/${AppRoutes.posts}`} />;

   return (
      <DocumentTitle condition={true} title="Sign In">
         <div className="min-h-screen flex flex-col justify-center py-6 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
               <img
                  className="mx-auto w-24"
                  src={Assets.golfLogo}
                  alt="GolfGuiders"
               />
               <h2 className="mt-6 text-center text-3xl font-extrabold text-primary">
            GolfGuiders
               </h2>
               <p className="mt-5 text-center text-md text-gray-600">
            Connect and share your Golf experience with people in your life.
            Share photos and videos, send messages and get updates.
               </p>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
               <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                  <form className="space-y-6" action="#" method="POST">
                     {fields.map((field: IField, i: number) => (
                        <div key={i}>
                           <Label
                              htmlFor={field.key}
                              className="block text-sm font-medium text-gray-700"
                              title={field.label}
                           />
                           <div className="mt-1 relative">
                              <InputField
                                 htmlFor={field.key}
                                 placeholder={field.placeholder}
                                 required={field.required}
                                 value={values[field.key]}
                                 type={field.type}
                                 onChange={(val) => onChangeHandler(val, field.key)}
                                 className={`appearance-none block w-full px-3 py-2 border ${
                                    isOpen?.error && !values[field.key]
                                       ? "border-red-500"
                                       : "border-gray-300"
                                 }  rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                                 onKeyPress={() => onLogin()}
                              />

                              {field.id === 2 && (
                                 <span
                                    className="absolute right-3 bottom-0 mb-2"
                                    onClick={() => showHidePassword(field.id, i)}
                                 >
                                    {field.type === "password"
                                       ? AppSVG.eyeOpen("")
                                       : AppSVG.eyeClose("")}
                                 </span>
                              )}
                           </div>
                        </div>
                     ))}

                     <div className="flex items-center justify-between">
                        <CheckBox
                           className="items-center"
                           htmlFor="remember-me"
                           title="Remember me"
                           checked={emailCheck}
                           type="checkbox"
                           onChange={(flag) => {
                              if (flag) {
                                 setCookie("username", values.username);
                              } else {
                                 removeCookie("username");
                              }

                              setEmailCheck(flag);
                           }}
                        />

                        <div className="text-sm">
                           <Link
                              route={AppRoutes.forgotPassword}
                              className="font-medium text-blue-500 hover:text-blue-500"
                              activeClassName=""
                              title="Forgot your password?"
                           />
                        </div>
                     </div>

                     <div>
                        <Button
                           className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                           disabled={loading}
                           id="submit"
                           loading={false}
                           onClick={onLogin}
                           title={
                              loading ? (
                                 <FaSpinner className="animate-spin text-base text-white" />
                              ) : (
                                 "Sign in"
                              )
                           }
                        />
                     </div>
                  </form>

                  <div className="mt-6">
                     <div className="relative mb-2">
                        <div className="absolute inset-0 flex items-center">
                           <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center text-sm">
                           <span className="px-2 bg-white text-gray-500">
                    Already have an account?
                           </span>
                           <Link
                              route={AppRoutes.signup}
                              className="bg-white underline text-blue-500 pr-2"
                              activeClassName=""
                              title="Sign Up"
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            {isOpen && (
               <Alert
                  isOpen={isOpen?.flag}
                  setIsOpen={() => setIsOpen({ ...isOpen, flag: false })}
                  className=""
                  title={isOpen?.title}
                  description={isOpen?.heading}
                  forError={true}
                  showClose={true}
               />
            )}
         </div>
      </DocumentTitle>
   );
};
