import React from 'react';
import Props from './Props';

const DragDropFile: React.FC<Props> = ({ getFile, className, children }) => {

   const dragOver = (e): void => {
      e.preventDefault();
   };

   const dragEnter = (e): void => {
      e.preventDefault();
   };

   const dragLeave = (e): void => {
      e.preventDefault();

      const files = e.dataTransfer.files[0];

      setFile(files);
   };

   const fileDrop = (e): void => {
      e.preventDefault();

      const files = e.dataTransfer.files[0];

      setFile(files);
   };

   const setFile = (file): void => {
      if(file){
         getFile(file);
      }
   };   

   return(

      <div className={`${className}`}
         onDragOver={dragOver}
         onDragEnter={dragEnter}
         onDragLeave={dragLeave}
         onDrop={fileDrop}
      >
         { children }
      </div>

   );

};
 
export default DragDropFile;