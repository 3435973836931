import React, { FC, useState, useCallback } from "react";
import { Link } from "Components/Theme/Link/Link";
import { AppRoutes } from "Routes/Routes";
import Props from "./Props";
import { DocumentTitle } from "Components/Theme/DocumentTitle/DocumentTitle";
import { Label } from "Components/Theme/FormElements/Label";
import InputField from "Components/Theme/FormElements/Input";
import { IField, IResponse, ISimpleMenu } from "Utilities/Interface";
import { Button } from "Components/Theme/FormElements/Button";
import AppSVG from "Utilities/Svg";
import { Alert } from "Components/Theme/Alert/Alert";
import { FaSpinner } from "react-icons/fa";
import { isSignUpRequest } from "./Redux/Thunk";
import { UserStatusEnumerator, UserTypeEnumerator } from "Utilities/Enums";
import { useNavigate } from "react-router-dom";
import { Image } from "Components/Theme/Flex/Flex";
import { SelectOption } from "Components/Theme/FormElements/Select";
import { userTypes } from "Utilities/Data";
import { Assets } from "../../../Assets/Assets";

export const SignUp: FC<Props> = () => {
   const navigate = useNavigate();

   const [isOpen, setIsOpen] = useState<
   | {
      flag: boolean;
      title: string;
      heading: string;
      forError?: boolean;
      forSuccess?: boolean;
   }
   | undefined
   >(undefined);
   const [loading, setLoading] = useState<boolean>(false);
   const [values, setValues] = useState<{
      username: string;
      email: string;
      password: string;
      type: ISimpleMenu;
   }>({
      username: "",
      email: "",
      password: "",
      type: userTypes()[0],
   });

   const [fields, setFields] = useState<IField[]>([
      // { id: 1, key: 'username', disabled: false, placeholder: '', required: true, type: 'text', label: 'Username *' },
      {
         id: 2,
         key: "email",
         disabled: false,
         placeholder: "",
         required: true,
         type: "email",
         label: "Email Address *",
      },
      {
         id: 3,
         key: "password",
         disabled: false,
         placeholder: "",
         required: true,
         type: "password",
         label: "Password *",
      },
      {
         id: 4,
         key: "type",
         disabled: false,
         placeholder: "",
         required: true,
         type: "text",
         label: "Type *",
         readonly: false,
         dropdown: true,
      },
   ]);

   const showHidePassword = useCallback(
      (id: number, index: number) => {
         fields[index] = {
            ...fields[index],
            type: fields[index].type === "password" ? "text" : "password",
         };

         setFields([...fields]);
      },
      [fields]
   );

   const onChangeHandler = useCallback(
      (value, key: string) => {
         setValues({ ...values, [key]: value });
      },
      [values]
   );

   const onSubmit = (): void => {
      if (!values.password || !values.email || !values.type?.name) {
         setIsOpen({
            flag: true,
            title: "Error",
            heading: "Fields with (*) are required.",
            forError: true,
         });
      } else if (values.password.length < 8) {
         setIsOpen({
            flag: true,
            title: "Error",
            heading: "Password should be greater than 7 characters.",
            forError: true,
         });
      } else {
         setLoading(true);
         const obj = {
            ...values,
            username: values.email.split("@")[0],
            type:
          values.type?.id === 1
             ? UserTypeEnumerator.GOLF_USER
             : UserTypeEnumerator.GOLF_TRAINER,
            status: UserStatusEnumerator.ACTIVE,
            isBlocked: true,
         };

         isSignUpRequest(obj, (response: IResponse) => {
            setLoading(false);

            if (!response.status) {
               setIsOpen({
                  flag: true,
                  title: "Error",
                  heading: response?.data,
                  forError: true,
               });
            } else {
               setIsOpen({
                  flag: true,
                  title: "Success",
                  heading:
              "Your account has been created successfully. Please check your inbox to verify the email and activate this account.",
                  forSuccess: true,
               });
               setValues({ username: "", email: "", password: "", type: undefined });
            }
         });
      }
   };

   return (
      <DocumentTitle condition={true} title="Sign Up">
         <div className="min-h-screen flex flex-col justify-center py-6 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
               <Image
                  className="mx-auto w-24"
                  defaultSrc={Assets.golfLogo}
                  src={Assets.golfLogo}
                  alt="GolfGuiders"
               />
               <h2 className="mt-6 text-center text-3xl font-extrabold text-primary">
            GolfGuiders
               </h2>
               <p className="mt-5 text-center text-md text-gray-600">
            Connect and share your Golf experience with people in your life.
            Share photos and videos, send messages and get updates.
               </p>
            </div>

            <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-md">
               <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                  <form className="space-y-6" action="#" method="POST">
                     {fields.map((field: IField, i: number) => (
                        <div key={i}>
                           <Label
                              htmlFor={field.key}
                              className="block text-sm font-medium text-gray-700"
                              title={field.label}
                           />
                           <div className="mt-1 relative">
                              {field?.dropdown ? (
                                 <SelectOption
                                    className=" focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    options={userTypes()}
                                    setSelected={(obj) => onChangeHandler(obj, field.key)}
                                    selected={values[field.key]}
                                 />
                              ) : (
                                 <InputField
                                    htmlFor={field.key}
                                    placeholder={field.placeholder}
                                    required={field.required}
                                    value={values[field.key]}
                                    type={field.type}
                                    onChange={(val) => onChangeHandler(val, field.key)}
                                    className={`appearance-none block w-full px-3 py-2 border ${
                                       (!values.password ||
                            !values.email ||
                            !values.type?.name) &&
                          isOpen?.forError
                                          ? "border-red-500"
                                          : "border-gray-300"
                                    }  rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                                    onKeyPress={() => onSubmit()}
                                 />
                              )}

                              {field.id === 3 && (
                                 <span
                                    className="absolute right-3 bottom-0 mb-2"
                                    onClick={() => showHidePassword(field.id, i)}
                                 >
                                    {field.type === "password"
                                       ? AppSVG.eyeOpen("")
                                       : AppSVG.eyeClose("")}
                                 </span>
                              )}
                           </div>
                        </div>
                     ))}

                     <div>
                        <Button
                           className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                           disabled={loading}
                           id="submit"
                           loading={false}
                           onClick={onSubmit}
                           title={
                              loading ? (
                                 <FaSpinner className="animate-spin text-base text-white" />
                              ) : (
                                 "Sign up"
                              )
                           }
                        />
                     </div>
                  </form>

                  <div className="mt-6">
                     <div className="relative mb-2">
                        <div className="absolute inset-0 flex items-center">
                           <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center text-sm">
                           <span className="px-2 bg-white text-gray-500">
                    Already have an account?
                           </span>
                           <Link
                              route={AppRoutes.signin}
                              className="bg-white underline text-blue-500 pr-2"
                              activeClassName="bg-white"
                              title="Sign in"
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            {isOpen && (
               <Alert
                  isOpen={isOpen?.flag}
                  setIsOpen={() => setIsOpen({ ...isOpen, flag: false })}
                  className=""
                  title={isOpen?.title}
                  description={isOpen?.heading}
                  forError={isOpen?.forError}
                  forSuccess={isOpen?.forSuccess}
                  showClose={false}
                  submitBtnText="Go to Sign in"
                  onSubmit={() => navigate(`/${AppRoutes.signin}`)}
               />
            )}
         </div>
      </DocumentTitle>
   );
};
