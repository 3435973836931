import { gql } from '@apollo/client';
import { PostFragment } from 'Apollo/Fragments';

export const IS_CREATE_NEW_POST = gql`
  mutation($details: InputPost!) {
    createPost(details: $details) {
      id
      ...PostFragment
    }
  }
  ${PostFragment}
`;

export const IS_DELETE_POST = gql`
  mutation($id: String!) {
    deletePost(id: $id)
  }
`;

export const IS_GET_ALL_POSTS_QUERY = gql`
  query($searchTerm: String $type: String $page: Float $limit: Float){
    getAllPosts(searchTerm: $searchTerm type: $type page: $page limit: $limit){
        id
        ...PostFragment
    }
  }
  ${PostFragment}
`;

export const GET_POSTS_BY_USER = gql`
  query($userId: String! $page: Float $limit: Float){
    getPostsByUser(userId: $userId page: $page limit: $limit){
        id
        ...PostFragment
    }
  }
  ${PostFragment}
`;

export const IS_SAVED_UNSAVED_POST = gql`
  mutation($id: String! $isSaved: Boolean!) {
    isSavedUnsavedPost(id: $id isSaved: $isSaved){
      id
      ...PostFragment
    }
  }
  ${PostFragment}
`;

export const IS_FAV_UNFAV_POST = gql`
  mutation($id: String! $isFavorite: Boolean!) {
    isFavoriteUnFavoritePost(id: $id isFavorite: $isFavorite){
      id
      ...PostFragment
    }
  }
  ${PostFragment}
`;

export const IS_LIKE_UNLIKE_POST = gql`
  mutation($postId: String! $userId: String!) {
    isLikeOrUnlikeAPost(postId: $postId userId: $userId){
      id
      ...PostFragment
    }
  }
  ${PostFragment}
`;


export const ACCEPT_NOT_ACCEPT_BUDDY_POST = gql`
  mutation($postId: String! $userId: String!) {
    acceptBuddyPost(postId: $postId userId: $userId){
      id
      isUsersAccepted
    }
  }
`;