import React, { FC } from 'react';
import AppSVG from 'Utilities/Svg';
import Props from './Props';

function CompleteStep({ stepName, onClick }){
   return(
      <li className="relative pr-8 sm:pr-20" onClick={onClick}>
    
         <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="h-0.5 w-full bg-primary"></div>
         </div>
         <div className="relative w-8 h-8 flex items-center justify-center bg-primary rounded-full hover:bg-indigo-900 cursor-pointer">
            { AppSVG.progressStep }
            <span className="sr-only">
               { stepName }
            </span>
         </div>
      </li>
   );
}

function InCompleteStep({ stepName, onClick, lastStep }){
   return(
      <li className={`relative ${ lastStep ? '' : 'pr-8 sm:pr-20' }`} onClick={onClick}>
         <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="h-0.5 w-full bg-gray-200"></div>
         </div>
         <div
            className="relative w-8 h-8 flex items-center justify-center bg-white border-2 border-primary rounded-full cursor-pointer"
            aria-current="step">
            <span className="h-2.5 w-2.5 bg-primary rounded-full" aria-hidden="true"></span>
            <span className="sr-only">{ stepName }</span>
         </div>
      </li>
   );
}

function ComingStep({ stepName, lastStep }){
   return(
      <li className={`relative ${ lastStep ? '' : 'pr-8 sm:pr-20' }`}>
         <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="h-0.5 w-full bg-gray-200"></div>
         </div>
         <div
            className="group relative w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full hover:border-gray-400">
            <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"
               aria-hidden="true"></span>
            <span className="sr-only">{ stepName }</span>
         </div>
      </li>
   );
}

const ProgressList:FC<Props> = ({ steps, onClick }) => {

   return(

      <nav aria-label="Progress">
         <ol role="list" className="flex items-center my-10">            

            { steps.map((step, i) => (
               step.status.toLowerCase() === 'complete' ? <CompleteStep stepName={step.name} key={i} onClick={() => onClick(step)} /> :
                  step.status.toLowerCase() === 'inprogress' ? <InCompleteStep lastStep={(i===steps.length-1)} stepName={step.name} key={i} onClick={() => onClick(step)} /> :
                     <ComingStep stepName={step.name} key={i} lastStep={!(i<steps.length-1)} />
            )) }
            
         </ol>
      </nav>

   );

};

export default ProgressList;