import React, { FC } from 'react';
import Props from './Props';

import { Switch, RadioGroup } from '@headlessui/react';
import { SelectSwitch } from 'Components/Theme/FormElements/Switch';
import CommonMethods from 'Utilities/CommonMethods';
import { SettingContainer } from 'Components/Screen/UserSettings/sub-components/SettingContainer/SettingContainer';
import { CheckPermission } from 'Components/Theme/CheckPermission/CheckPermission';

const plans = [
   { name: 'Startup', priceMonthly: 29, priceYearly: 290, limit: 'Up to 5 active job postings' },
   { name: 'Business', priceMonthly: 99, priceYearly: 990, limit: 'Up to 25 active job postings' },
   { name: 'Enterprise', priceMonthly: 249, priceYearly: 2490, limit: 'Unlimited active job postings' },
];

export const BillingPlan:FC<Props> = () => {

   const [selectedPlan, setSelectedPlan] = React.useState(plans[1]);
   const [state, setState] = React.useState<boolean>(false);

   return (
      
      <CheckPermission condition={false}>

         <SettingContainer title="Subscription Plan">

            <section aria-labelledby="plan-heading">
               <form action="#" method="POST">
                  <div className="mb-4 mt-6 sm:rounded-md sm:overflow-hidden ">

                     <RadioGroup value={selectedPlan} onChange={setSelectedPlan}>
                        <RadioGroup.Label className="sr-only">Pricing plans</RadioGroup.Label>
                        <div className="relative bg-white rounded-md -space-y-px">
                           {plans.map((plan, planIdx) => (
                              <RadioGroup.Option
                                 key={plan.name}
                                 value={plan}
                                 className={({ checked }) =>
                                    CommonMethods.classNames(
                                       planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                       planIdx === plans.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                       checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200',
                                       'relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none'
                                    )
                                 }
                              >
                                 {({ active, checked }) => (
                                    <>
                                       <div className="flex items-center text-sm">
                                          <span
                                             className={CommonMethods.classNames(
                                                checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                                                active ? 'ring-2 ring-offset-2 ring-gray-900' : '',
                                                'h-4 w-4 rounded-full border flex items-center justify-center'
                                             )}
                                             aria-hidden="true"
                                          >
                                             <span className="rounded-full bg-white w-1.5 h-1.5" />
                                          </span>
                                          <RadioGroup.Label as="span" className="ml-3 font-medium text-gray-900">
                                             {plan.name}
                                          </RadioGroup.Label>
                                       </div>
                                       <RadioGroup.Description className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                                          <span
                                             className={CommonMethods.classNames(
                                                checked ? 'text-indigo-700' : 'text-gray-900',
                                                'font-medium'
                                             )}
                                          >
                             ${plan.priceMonthly} / mo
                                          </span>{' '}
                                          <span className={checked ? 'text-indigo-700' : 'text-gray-500'}>
                             (${plan.priceYearly} / yr)
                                          </span>
                                       </RadioGroup.Description>
                                       <RadioGroup.Description
                                          className={CommonMethods.classNames(
                                             checked ? 'text-indigo-700' : 'text-gray-500',
                                             'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right'
                                          )}
                                       >
                                          {plan.limit}
                                       </RadioGroup.Description>
                                    </>
                                 )}
                              </RadioGroup.Option>
                           ))}
                        </div>
                     </RadioGroup>

                     <Switch.Group as="div" className="flex items-center mt-4 p-0">
                        <SelectSwitch checked={state} onChange={setState} />
                        <Switch.Label as="span" className="ml-3 p-0">
                           <span className="text-sm font-medium text-gray-900 mr-2">Annual Billing Plan</span>
                           <span className="text-sm text-gray-500">(Save 10%)</span>
                        </Switch.Label>
                        <button className="px-6 py-2 text-sm bg-indigo-600 text-white rounded-md ml-auto">
               Save
                        </button>
                     </Switch.Group>

                  </div>
               </form>
            </section>

         </SettingContainer>
         
      </CheckPermission>
          
   );

};