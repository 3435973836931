import { Assets } from "Assets/Assets";
import { Image, Span } from "../Flex/Flex";

export default function Avatar({ src, title, className, buddyAvatar }) {
   if (src) {
      return (
         <div className="rounded-full ring-2 ring-offset-2 ring-primary">
            <Image
               src={src}
               className={`rounded-full ${className}`}
               defaultSrc={Assets.userClip}
            />
         </div>
      );
   }

   return (
      <Span
         title={title}
         className={` bg-primary text-white rounded-full flex flex-col uppercase items-center font-medium justify-center text-sm focus:outline-none ${
            buddyAvatar ? "" : "ring-primary ring-2 ring-offset-2 "
         } ${className}`}
      />
   );
}
