import React, { FC, useEffect, useRef, useState } from "react";
import { AlertContainer } from "./AlertContainer";

interface IAlert {
   isOpen: boolean;
   setIsOpen: (flag: boolean) => void;
   onSubmit?: () => void;
   setCalendarAlert?: (flag: boolean) => void;
   className?: string;
   forError?: boolean;
   forSuccess?: boolean;
   title: string;
   description: string;
   submitBtnText?: string;
   showClose?: boolean;
}

export const Alert: FC<IAlert> = (props) => {
   const {
      isOpen,
      setIsOpen,
      submitBtnText,
      description,
      title,
      showClose,
      forError,
      forSuccess,
      onSubmit,
      setCalendarAlert,
   } = props;

   const cancelBtnRef = useRef<HTMLButtonElement>(null);

   useEffect(() => {
      if (cancelBtnRef.current) {
         cancelBtnRef.current.focus();
      }
   }, []);

   if (!isOpen) return null;

   return (
      <AlertContainer className="pt-5 pb-4 sm:max-w-lg sm:w-full">
         <div>
            <div className="sm:flex sm:items-start">
               <div
                  className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 
                           ${
      forError
         ? "bg-red-100"
         : forSuccess
            ? "bg-green-100"
            : "bg-grey-200"
      }
                        `}
               >
                  {forError && (
                     <svg
                        className="h-6 w-6 text-red-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        aria-hidden="true"
                     >
                        <path
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                     </svg>
                  )}
                  {forSuccess && (
                     <svg
                        className="h-6 w-6 text-green-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        aria-hidden="true"
                     >
                        <path
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           d="M5 13l4 4L19 7"
                        />
                     </svg>
                  )}
               </div>
               <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                     className="text-lg leading-6 font-medium text-gray-900"
                     id="modal-title"
                  >
                     {title}
                  </h3>
                  <div className="mt-2">
                     <p className="text-sm text-gray-500">{description}</p>
                  </div>
               </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
               {onSubmit && forSuccess && (
                  <button
                     onClick={onSubmit}
                     type="button"
                     className="mt-3 mr-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:w-auto sm:text-sm"
                  >
                     {submitBtnText ?? "Submit"}
                  </button>
               )}
               {showClose && (
                  <button
                     ref={cancelBtnRef}
                     onClick={() => {
                        setIsOpen(false);
                        setCalendarAlert(false);
                     }}
                     type="button"
                     className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:w-auto sm:text-sm"
                  >
              Close
                  </button>
               )}
            </div>
         </div>
      </AlertContainer>
   );
};
