import * as React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface IDatePicker {
   startDate: Date;
   setStartDate: (date: Date) => void;
   className?: string;
   children?: JSX.Element
}

interface IExampleCustomInput {
   children?: React.ReactNode;
   type?: "submit" | "button";
   onClick?: () => void;
}

export const CustomDatePicker:React.FC<IDatePicker> = ({ startDate, setStartDate, children, className }) => {

   const ExampleCustomInput = React.forwardRef((props: IExampleCustomInput, ref: React.RefObject<HTMLButtonElement>) => {
      return (
         <button className={`${className}`} onClick={props.onClick} ref={ref}>
            { children }
         </button>
      );
   });

   return (
      <DatePicker
         selected={startDate}
         onChange={(date) => setStartDate(date)}
         customInput={<ExampleCustomInput />}
         minDate={new Date()}
         showTimeInput
         timeInputLabel="Time:"
         timeFormat="HH:mm"
         dateFormat="MMMM d, yyyy h:mm aa"
      />
   );
};