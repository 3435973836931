// this file contains open close feature of comment
import { FlexColumn } from "Components/Theme/Flex/Flex";
import React, { FC, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import Svg from "libraries/AppSvg";

const CommentFilters: FC<any> = () => {
   return (
      <FlexColumn className="relative w-full justify-center items-end">
         <div className="flex-shrink-0 relative z-10 mt-2 ">
            <Menu as="div" className="relative inline-block text-left text-xs">
               <Menu.Button className="inline-flex w-full justify-center items-center rounded-md px-3 py-1 text-sm font-bold text-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                  <div className="mr-2"> Filter</div>
                  <div>
                     {Svg.chevronArrowDown("h-3 w-3 mt-1 font-bold", "#6b7280")}
                  </div>
               </Menu.Button>
               <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
               >
                  <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                     <div className="px-1 py-1 ">
                        <Menu.Item>
                           {({ active }) => (
                              <FlexColumn
                                 className={`${
                                    active ? "bg-gray-500 text-white" : "text-gray-500"
                                 } group flex w-auto items-start rounded-md px-1 py-1 mt-1 ml-1 mr-1 text-sm cursor-pointer`}
                              >
                                 <button className="font-bold mb-1">
                        Latest Comments
                                 </button>
                                 <div className="text-xs">
                        The most latest comments will appear first.
                                 </div>
                              </FlexColumn>
                           )}
                        </Menu.Item>
                        <Menu.Item>
                           {({ active }) => (
                              <FlexColumn
                                 className={`${
                                    active ? "bg-gray-500 text-white" : "text-gray-500"
                                 } group flex w-auto items-start rounded-md px-1 py-1 mt-1 ml-1 mb-1 mr-1 text-sm cursor-pointer`}
                              >
                                 <button className="font-bold mb-1">All Comments</button>
                                 <div className="text-xs">
                        Show all comments. The most recent comments will appear
                        first.
                                 </div>
                              </FlexColumn>
                           )}
                        </Menu.Item>
                     </div>
                  </Menu.Items>
               </Transition>
            </Menu>
         </div>
      </FlexColumn>
   );
};

export default CommentFilters;
