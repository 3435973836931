import { Image } from 'Components/Theme/Flex/Flex';
import { PostAttachmentTypesEnumerator } from 'Utilities/Enums';

export default function PostType({ src, type, className, alt }){

   if(type.toLowerCase() === PostAttachmentTypesEnumerator.VIDEO.toLowerCase()){
      return (
         <video autoPlay={false} controls={true} muted loop className={`${className}`} 
            controlsList="nodownload" preload="metadata" 
            onMouseOver={(e: any) => e.target.play()} playsInline webkit-playsinline="true" onMouseOut={(e: any) => e.target.pause()}
         >
            <source src={`${src}#t=0.1`} type="video/mp4" />
            <source src={`${src}#t=0.1`} type="video/ogg" />
         </video>
      );
   }

   if(type.toLowerCase() === PostAttachmentTypesEnumerator.AUDIO.toLowerCase() || type.toLowerCase() === PostAttachmentTypesEnumerator.MUSIC.toLowerCase()){
      return (
         <div className="flex">
            <audio
               loop autoPlay={false} controls muted={true} src={src} className="w-100 ml-3" controlsList="nodownload"
               onPlayingCapture={() => console.log(false)} onPauseCapture={() => console.log(true)}
            >
               <source type="audio/*" />
            </audio> <br/>
         </div>
      );
   }

   return (
      <Image
         src={src} 
         defaultSrc={src}
         alt={ alt } className={`${className}`}
      />
   );
}
