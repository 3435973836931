import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
// import { initReactI18next } from 'react-i18next';
// Translation Files
import translationEng from "./Locales/en/translation.json";
import translationGerman from "./Locales/de/translation.json";


i18n.use(LanguageDetector).init({
   // we init with resources
   resources: {
      en: {
         translations: translationEng,
      },
      de: {
         translations: translationGerman,
      }      
   },
   fallbackLng: "en",
   debug: false,
   // have a common namespace used around the full app
   ns: ["translations"],
   defaultNS: "translations",
   keySeparator: false, // we use content as keys
   interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ","
   }
});

export default i18n;