import React, { FC, Fragment } from "react";
import "../../../Styles/global.css";
import { FlexColumn, FlexRow } from "Components/Theme/Flex/Flex";
import { IPostAction } from "./Props";
import ShareActions from "Components/Screen/ShareActions/ShareActions";
import { Menu, Transition } from "@headlessui/react";
import Svg from "libraries/AppSvg";

export const PostActions: React.FC<IPostAction> = ({
   onClick,
   likePost,
   isLiked,
   postLikes,
}) => {
   // const { shares, views } = props;
   const [shareItems, showShareItems] = React.useState<boolean>(false);

   return (
      <FlexColumn className={""}>
         <FlexRow className="w-full mt-2 mb-2 justify-around border-t border-gray-300 ">
            {/* <div className="w-full flex items-center justify-between py-2 border-b border-gray-300 px-4">
            <div className="flex text-sm text-gray-500 truncate items-center">
               <div className="flex -space-x-1 relative z-0 overflow-hidden mr-2">
                  <img className="relative z-30 inline-block h-6 w-6 rounded-full ring-2 ring-white"
                     src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                     alt="" />
                  <img className="relative z-10 inline-block h-6 w-6 rounded-full ring-2 ring-white"
                     src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                     alt="" />
                  <img className="relative z-0 inline-block h-6 w-6 rounded-full ring-2 ring-white"
                     src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                     alt="" />
               </div>
               { views }
            </div>
            <div className="text-sm text-gray-500 truncate">{shares} Shares</div>
         </div> */}

            <div id="like_post_action">
               <button
                  onClick={likePost}
                  type="button"
                  className="cursor-pointer relative flex-1 inline-flex items-center justify-center px-4 py-2 text-sm font-medium  text-gray-400 focus:z-10 focus:outline-none "
               >
                  {isLiked ? "Liked" : "Like"}
               </button>
               <span className="text-sm text-gray-400 -ml-2">
                  {postLikes ? `(${postLikes})` : ""}
               </span>
               {isLiked ? (
                  <>
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative -mt-7 -ml-2 h-5 w-5 text-gray-400 cursor-pointer"
                        fill="#7bb062"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                        onClick={likePost}
                     >
                        <path
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                        />
                     </svg>
                  </>
               ) : (
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     className="relative -mt-7 -ml-2 mr-4 h-5 w-5 text-gray-400 cursor-pointer"
                     fill="none"
                     viewBox="0 0 24 24"
                     stroke="currentColor"
                     strokeWidth="2"
                     onClick={likePost}
                  >
                     <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                     />
                  </svg>
               )}
            </div>
            <div id="comment_post_action" onClick={onClick}>
               <button
                  type="button"
                  className="relative flex-1 inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-400 focus:z-10"
               >
            Comment
               </button>
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="relative -mt-7 -ml-2 mr-4 h-5 w-5 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
               >
                  <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                  />
               </svg>
            </div>

            {/* <div id="share_post_action" onClick={() => showShareItems(!shareItems)}>
               <button
                  type="button"
                  className="relative flex-1 inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-400 focus:z-10 focus:text-gray-500"
               >
            Share
               </button>
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="relative -mt-7 -ml-2 mr-4 h-5 w-5 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
               >
                  <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
                  />
               </svg>
            </div> */}

            {/* asd */}

            <div className="mt-2 relative">
               <Menu as="div">
                  <Menu.Button className="relative flex-1 inline-flex items-center justify-center px-4 text-sm font-medium text-gray-400">
                     <div id="share_post_action">
                        <span> Share</span>
                        {Svg.shareLink(
                           "absolute -mt-5 -ml-6 h-5 w-5 text-gray-400",
                           "none"
                        )}
                     </div>
                  </Menu.Button>
                  <Transition
                     as={Fragment}
                     enter="transition ease-out duration-100"
                     enterFrom="transform opacity-0 scale-95"
                     enterTo="transform opacity-100 scale-100"
                     leave="transition ease-in duration-75"
                     leaveFrom="transform opacity-100 scale-100"
                     leaveTo="transform opacity-0 scale-95"
                  >
                     <Menu.Items className="hidden  right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-black shadow-lg ring-1 ring-black ring-opacity-5 ">
                        <div className="px-1 py-1 ">
                           <Menu.Item>
                              {({ active }) => (
                                 <FlexRow
                                    className={`${
                                       active ? "bg-gray-500 text-white" : "text-gray-500"
                                    } flex w-auto items-center rounded-md px-1 py-1 mt-1 ml-1 mb-1 mr-1 text-sm cursor-pointer`}
                                 >
                                    <span>
                                       {Svg.shareFriends(
                                          "relative mt-1 mr-2 h-5 w-5 text-gray-400",
                                          `${active ? "white" : "rgb(107,114,128)"}`
                                       )}
                                    </span>
                                    <button className="font-bold ">Share to friends</button>
                                 </FlexRow>
                              )}
                           </Menu.Item>
                           <Menu.Item>
                              {({ active }) => (
                                 <FlexRow
                                    className={`${
                                       active ? "bg-gray-500 text-white" : "text-gray-500"
                                    } flex w-auto items-center rounded-md px-1 py-1 mt-1 ml-1 mb-1 mr-1 text-sm cursor-pointer`}
                                 >
                                    <span>
                                       {Svg.shareFeed(
                                          "relative mt-1 mr-2 h-5 w-5 text-gray-400",
                                          `${active ? "white" : "rgb(107,114,128)"}`
                                       )}
                                    </span>
                                    <button className="font-bold ">Share to Feed</button>
                                 </FlexRow>
                              )}
                           </Menu.Item>
                        </div>
                     </Menu.Items>
                  </Transition>
               </Menu>
            </div>

            {/* a{shareItems && <ShareActions />} */}
         </FlexRow>
         <span className="mt-2 h-auto relative inline-flex w-full justify-between border-t border-gray-300"></span>
      </FlexColumn>
   );
};
