import AppDialog from 'Components/Theme/Menu/Dialog';
import React, { FC } from 'react';

interface IViewReceipt{
   open: boolean; 
   setOpen: () => void;
}

export const ViewReceipt:FC<IViewReceipt> = ({ open, setOpen }) => {

   return (
      
      <AppDialog open={open} setOpen={setOpen}>

         <div>
            d
         </div>

      </AppDialog>      

   );

};