import {
   ApolloClient,
   ApolloClientOptions,
   InMemoryCache,
   split,
   DefaultOptions,
   ApolloLink,
   from,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { environment, baseUrl } from "Environment/environment";
import { tokenService } from "Services/tokenService";
import { getMainDefinition } from "apollo-utilities";
import { refreshTokenInterceptorFetch } from "Services/refresh-token.interceptor";

const attachToken = () => ({
      authorization: tokenService.getAccessToken()
         ? `Bearer ${tokenService.getAccessToken()}`
         : undefined,
      "Apollo-Require-Preflight": "true",
   }),
   createWebsocketLink = () =>
      new WebSocketLink({
         uri: environment.socketConnectionUrl,
         options: {
            lazy: true,
            reconnect: true,
            minTimeout: 30000,
            connectionParams: () => attachToken(),
         },
      });

let wsLink = createWebsocketLink();

const errorLink = onError(({ graphQLErrors, networkError }) => {
   let err;

   const gqlError: [{ message: string; code: number }] | any = graphQLErrors;

   if (networkError) err = networkError;

   if (gqlError?.length)
      gqlError.forEach(({ message }) => {
         err = message;
      });

   if (gqlError?.length && gqlError?.[0]?.code === 500)
      if (
         !localStorage.getItem("authenticationAccessToken") ||
      !localStorage.getItem("authenticationRefreshToken")
      ) {
         sessionStorage.clear();
         localStorage.clear();
         window.location.href = `${baseUrl}/login`;
         window.location.reload();
      }
});

const authLink: ApolloLink = new ApolloLink((operation, forward) => {
   const myHeaders = new Headers();

   myHeaders.append("Content-Type", "application/json");

   operation.setContext(({ headers = {} }) => ({
      headers: {
         ...headers,
         ...attachToken(),
         ...myHeaders,
      },
   }));

   return forward(operation);
});

const httpLink: ApolloLink = createUploadLink({
   uri: environment.ip,
   headers: attachToken(),
   fetch: refreshTokenInterceptorFetch,
});

let splitLink = split(
   ({ query }) => {
      const definition = getMainDefinition(query);

      return (
         definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
      );
   },
   wsLink,
   authLink.concat(httpLink)
);

const apolloClientConfigurationOptions: ApolloClientOptions<unknown> = {
   link: from([errorLink, splitLink]),
   cache: new InMemoryCache(),
   name: "Golf_Guider",
   version: "1.0",
   defaultOptions: {
      mutate: {
         fetchPolicy: "no-cache",
      },
      watchQuery: {
         fetchPolicy: "network-only",
         nextFetchPolicy: "no-cache",
         errorPolicy: "all",
      },
      query: {
         fetchPolicy: "network-only",
         nextFetchPolicy: "no-cache",
         errorPolicy: "all",
      },
   } as DefaultOptions,
};

export let client: ApolloClient<unknown> = new ApolloClient(
   apolloClientConfigurationOptions
);

export const resetConfigurationsAfterLogout = (): void => {
   wsLink = createWebsocketLink();

   splitLink = split(
      ({ query }) => {
         const definition = getMainDefinition(query);

         return (
            definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
         );
      },
      wsLink,
      authLink.concat(httpLink)
   );

   client = new ApolloClient(apolloClientConfigurationOptions);
};
