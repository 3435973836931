import { Assets } from "Assets/Assets";
import { Image } from "Components/Theme/Flex/Flex";
import { Link } from "Components/Theme/Link/Link";
import ITopProfileHeader from "./Props";

export const TopProfileHeader: React.FC<ITopProfileHeader> = ({
   coverPhoto,
   firstname,
   lastname,
   profilePhoto,
   user,
   friendsTab,
}) => {
   return (
      <>
         <div>
            <div>
               <Image
                  src={coverPhoto ?? Assets.emptySrc}
                  defaultSrc={coverPhoto ?? Assets.emptySrc}
                  alt={firstname}
                  className="h-32 w-full object-cover lg:h-48 rounded"
               />
            </div>

            <div className="max-w-5xl mx-auto px-4 sm:px-3 lg:px-3">
               <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                  <div className="flex">
                     <Image
                        src={profilePhoto ?? Assets.emptyUserSrc}
                        defaultSrc={profilePhoto ?? Assets.emptyUserSrc}
                        alt={firstname}
                        className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                     />
                  </div>
                  <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                     <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                        <h1 className="text-2xl font-bold text-gray-900 truncate">
                           {user?.username ? user?.username : "NA"}
                        </h1>
                     </div>
                     {/* <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                        <button
                           type="button"
                           className="inline-flex w-full flex-1 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 sm:px-2 sm:py-0 text-sm font-medium text-gray-700 shadow-sm hover:text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                        >
                           <svg
                              className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                           >
                              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                           </svg>
                           <span>Send Message</span>
                        </button>
                        <button
                           type="button"
                           className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        >
                           <svg
                              className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                           >
                              <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                           </svg>
                           <span>Call</span>
                        </button>
                     </div> */}
                  </div>
               </div>
               <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
                  <h1 className="text-2xl font-bold text-gray-900 truncate">
                     {firstname ? firstname + " " + lastname : "NA"}
                  </h1>
               </div>
            </div>
         </div>
         <div className="mt-6 mb-4 sm:mt-2 2xl:mt-5">
            {friendsTab.map((link, i) => (
               <Link
                  key={i}
                  route={link.route}
                  className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-1/5 py-2 px-1 cursor-pointer text-center border-b-2 font-medium text-sm"
                  activeClassName={
                     link.isActive
                        ? "border-primary text-primary w-1/5 mr-4 ml-2 py-2 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-1/5 py-2 px-1 cursor-pointer text-center border-b-2 font-medium text-sm"
                  }
               >
                  <span>{link.name}</span>
               </Link>
            ))}
         </div>
      </>
   );
};
