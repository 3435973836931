// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const baseUrl = 'https://api.golfguiders.com';
// export const baseUrl = 'http://localhost:5000';
export const API_URL_IMAGE_SERVER = 'https://static.golfguiders.com';
// export const API_URL_IMAGE_SERVER = 'http://localhost/php-upload';
export const API_URL_UPLOAD_IMAGE = API_URL_IMAGE_SERVER + '/image.php';

const socketConnectionUrl = '';

export const environment = {
   socketConnectionUrl: socketConnectionUrl + '/graphql',
   stylesUrl: '',
   ip: baseUrl + '/graphql',
   production: false
};