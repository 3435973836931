import React, { FC, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import MainLayout from "HOC/MainLayout/MainLayout";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import "./Styles.css";
import { IUserProfile } from "Utilities/State.interfaces";
import { isGetUserProfileRequestViaCallback } from "../UserSettings/screens/Profile/Redux/Thunk";
import { ICalendar, IResponse } from "Utilities/Interface";
import { TopProfileHeader } from "../Modals/TopProfileHeader";
import { FlexRow } from "Components/Theme/Flex/Flex";
import { FaSpinner } from "react-icons/fa";
import { AppRoutes } from "Routes/Routes";
import { useSelector } from "react-redux";
import { Alert } from "Components/Theme/Alert/Alert";

const MyCalendar: FC<any> = () => {
   const [userProfile, setUserProfile] = React.useState<IUserProfile>(undefined);
   const [loading, setLoading] = React.useState<boolean>(true);
   const [hideRightContent, setHideRightContent] = useState<number>(0);
   const [calendarAlert, setCalendarAlert] = useState<boolean>(false);
   const [isOpen, setIsOpen] = useState<boolean>(true);
   const [title, setTitle] = useState<string>(undefined);
   const [desc, setDesc] = useState<string>(undefined);

   const EventDetails = useSelector((state) => state["userPostReducer"].details);

   const calendarEvents = [];
   EventDetails.forEach((event) => {
      const title = event.title;
      const start = new Date(event.dateTo);
      const end = new Date(event.dateTo);
      const desc = event.description;
      calendarEvents.push({ title, start, end, desc });
   });

   const { trainerId, friendId } = useParams<{
      trainerId?: string;
      friendId?: string;
   }>();

   const friendDetailsTabs = [
      {
         id: 1,
         name: "Profile",
         route: `${AppRoutes.friends}/${friendId}`,
         disbaled: false,
         isActive: false,
      },
      {
         id: 2,
         name: "Calendar",
         route: `${AppRoutes.friends}/${friendId}/${AppRoutes.calendar}`,
         disbaled: false,
         isActive: true,
      },
   ];

   React.useEffect(() => {
      if (trainerId || friendId) {
         isGetUserProfileRequestViaCallback(
            { userId: trainerId ?? friendId },
            (res: IResponse) => {
               setUserProfile(res.data);
               setHideRightContent(1);
               setLoading(false);
            }
         );
      } else {
         setLoading(false);
      }
   }, []);

   const localizer = momentLocalizer(moment);

   const eventSelected = (event) => {
      setTitle(event.title);
      setDesc(event.desc);
      setCalendarAlert(!calendarAlert);
   };
   const eventStyleGetter = (event): any => {
      const style = {
         backgroundColor: "transparent",
         borderRadius: "5px",
         outline: "none",
         opacity: 1,
         color: "black",
         border: "0px",
      };

      return {
         style: style,
      };
   };

   if (loading) {
      return (
         <MainLayout columns={2} docTitle="User Details">
            <FlexRow className="w-full py-3 items-center justify-center">
               <FaSpinner className="animate-spin text-base text-black" />
            </FlexRow>
         </MainLayout>
      );
   }

   if (!userProfile) {
      return (
         <MainLayout columns={2} docTitle="User Details">
            <FlexRow className="w-full py-3 items-start justify-start text-base text-left">
          Data not found.
            </FlexRow>
         </MainLayout>
      );
   }

   return (
      <MainLayout columns={3} docTitle="Calendar">
         <div className="flex flex-col">
            <TopProfileHeader
               coverPhoto={userProfile?.coverPhoto}
               firstname={userProfile?.firstname}
               lastname={userProfile?.lastname}
               profilePhoto={userProfile?.profilePhoto}
               user={userProfile?.user}
               friendsTab={friendDetailsTabs}
            />

            <Calendar
               localizer={localizer}
               events={EventDetails ? calendarEvents : []}
               startAccessor="start"
               endAccessor="end"
               step={60}
               style={{ height: window.innerHeight - 160 }} // ignore this error
               defaultDate={new Date()}
               defaultView="month"
               onSelectEvent={eventSelected}
               resizable
               views={{
                  week: true,
                  month: true,
                  day: true,
                  agenda: true,
               }}
               eventPropGetter={eventStyleGetter}
            />
            {calendarAlert && (
               <Alert
                  isOpen={isOpen}
                  setIsOpen={() => setIsOpen(true)}
                  setCalendarAlert={() => setCalendarAlert(false)}
                  className=""
                  title={title}
                  description={desc}
                  forError={false}
               ></Alert>
            )}
         </div>
      </MainLayout>
   );
};

export default MyCalendar;
