import React, { FC } from "react";
import Props from "./Props";
import InputField from "../FormElements/Input";

export const FindWithSearch: FC<Props> = ({
   heading,
   subHeading,
   search,
   pressEnter,
   setSearch,
}) => {
   return (
      <div className="flex flex-row flex-wrap items-center justify-between">
         <div className="flex flex-col w-auto">
            <h3 className="text-xl leading-6 font-medium text-black mr-2">
               {heading}
            </h3>
            <span className="lg:text-sm text-black md:text-sm sm:text-sm sm:mb-2 mt-1 mr-2">
               {subHeading}
            </span>
         </div>
         <div className="w-full lg:max-w-lg lg:flex-shrink-0 ">
            <label htmlFor="search" className="sr-only">
          Search
            </label>
            <div className="relative">
               <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg
                     className="h-5 w-5 text-gray-400"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 20 20"
                     fill="currentColor"
                     aria-hidden="true"
                  >
                     <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                     />
                  </svg>
               </div>
               <InputField
                  className="mt-2 sm:mt-0 md:mt-0 lg:mt-0 w-full  block pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm"
                  htmlFor="search"
                  placeholder="Search"
                  type="search"
                  value={search}
                  required={false}
                  onChange={(val: string) => setSearch(val)}
                  onKeyPress={() => pressEnter(search)}
               />
            </div>
         </div>
      </div>
   );
};
