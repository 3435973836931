/* eslint-disable @typescript-eslint/no-var-requires */
interface IAssets{
   golfLogo: string;
   golf: string;
   golfer: string;
   golfing: string;
   golfBall: string;
   golfPlayer: string;
   golfStick: string;
   golfPark: string;
   golfClub: string;
   teeTime: string;
   saved:string;
   favorites:string;
   userAvatar: string;
   userClip: string;
   clubReviews: string;
   emptySrc: string;
   emptyUserSrc: string;
}

export const Assets: IAssets = {
   golfLogo: require('./Images/golfguiders.png'),
   golf: require('./Images/trainers.png'),
   golfer: require('./Images/expert-trainers.png'),
   golfing: require('./Images/golf buddy.png'),
   golfBall: require('./Images/home.png'),
   golfPlayer: require('./Images/expert-trainers.png'),
   golfStick: require('./Images/friends.png'),
   golfPark: require('./Images/golf courses.png'),
   golfClub: require('./Images/marketplace.png'),
   teeTime: require('./Images/tee times.png'),
   userClip: require('./Images/user.png'),
   saved: require('./Images/saved.png'),
   favorites: require('./Images/favorites.png'),
   clubReviews: require('./Images/club review.png'),
   userAvatar: require('./Images/userAvatar.png'),
   emptySrc: 'https://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image.jpg',
   emptyUserSrc: 'https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/no-profile-picture-icon.png'
};