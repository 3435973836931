import { gql } from '@apollo/client';

export const UserFragment = gql`
  fragment UserFragment on User {       
    username
    email
    type
    status
  }
`;

export const PostFragment = gql`
  fragment PostFragment on Post {
    user{
      id
      username 
      userProfile{
        firstname
        lastname
      }
    }
    likes
    title
    description
    attachment
    attachmentType
    userId
    dateFrom
    dateTo
    type
    address
    isFavorite
    isSaved
    status
    isAccept
    created
  }
`;

export const GolfCourseFragment = gql`
  fragment GolfCourseFragment on GolfCourse {
    userId
    created
    modified
    name
    description
    address
    country
    city
    state
    zipCode
    county
    latitude
    longitude
    phone
    phoneDirect
    email
    website
    industry
    sicCode
    contactFirstName
    contactLastName
    contactTitle
    image
    year
    architect
    season
    greens
    par
    holes
    views
    type
    status
  }
`;

export const UserProfileFragment = gql`
  fragment UserProfileFragment on UserProfile {
    user{
      id
      username
      email
      type
      status
    }
    countryId
    cityId
    created
    modified
    firstname
    lastname
    latitude
    longitude
    gender
    type
    status
    about
    url
    postalCode
    state
    address
    coverPhoto
    profilePhoto
  }
`;

export const CountryFragment = gql`
  fragment CountryFragment on Country {
    name
    image
    order
    views
    latitude
    longitude
    created
  }
`;

export const CityFragment = gql`
  fragment CityFragment on City {
    name
    image
    order
    views
    latitude
    longitude
    created
  }
`;