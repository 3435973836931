import { gql } from '@apollo/client';
import { UserFragment } from 'Apollo/Fragments';

export const IS_LOGIN_QUERY = gql`
  mutation($username: String! $password: String!) {
    signIn(username: $username password: $password) {
        accessToken
        refreshToken
    }
  }
`;

export const GET_MY_COMPLETE_DETAILS_QUERY = gql`
  query{
    getMyCompleteDetails{
        id
        ...UserFragment
    }
  }
  ${UserFragment}
`;

export const IS_ACCOUNT_CONFIRMATION = gql`
  mutation($userId: String!){
    userAccountVerification(userId: $userId)
  }
`;

export const IS_LOGOUT = gql`
  mutation {
    signOut
  }
`;