import * as React from "react";

class AppSvg {
   public replyRegular = (
      className?: string,
      fill?: string
   ): SVGElement | any => {
      return (
         <svg
            xmlns="http://www.w3.org/2000/svg"
            fill={fill}
            className={`${className}`}
            viewBox="0 0 512 512"
         >
            <path d="M512 336v119.1C512 469.3 501.3 480 488 480s-24-10.75-24-23.1v-119.1c0-57.34-46.66-103.1-104-103.1H88.51l127.2 109.8c10.03 8.656 11.12 23.81 2.469 33.84C213.4 381.2 206.7 384 200 384c-5.562 0-11.16-1.909-15.69-5.847l-176-151.1C3.029 221.6 0 214.1 0 208C0 201 3.029 194.4 8.31 189.9l176-151.1c9.1-8.718 25.16-7.575 33.85 2.487c8.656 10.03 7.562 25.19-2.469 33.84L88.51 184H360C443.8 184 512 252.2 512 336z" />
         </svg>
      );
   };

   public chevronArrowDown = (
      className?: string,
      fill?: string
   ): SVGElement | any => {
      return (
         <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className={className}
            fill={fill}
         >
            <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
         </svg>
      );
   };

   public shareLink = (className?: string, fill?: string): SVGElement | any => {
      return (
         <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            fill={fill}
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
         >
            <path
               strokeLinecap="round"
               strokeLinejoin="round"
               d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
            />
         </svg>
      );
   };

   public shareFriends = (
      className?: string,
      fill?: string
   ): SVGElement | any => {
      return (
         <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            fill={fill}
            viewBox="0 0 512 512"
         >
            <path d="M296 160c13.3 0 24-10.7 24-24v-8V112 64L480 208 320 352l0-48V288v-8c0-13.3-10.7-24-24-24h-8H192c-70.7 0-128 57.3-128 128c0 8.3 .7 16.1 2 23.2C47.9 383.7 32 350.1 32 304c0-79.5 64.5-144 144-144H288h8zm-8 144v16 32c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4s-19 16.6-19 29.2V96v16 16H256 176C78.8 128 0 206.8 0 304C0 417.3 81.5 467.9 100.2 478.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8 431.9 96 414.4 96 384c0-53 43-96 96-96h64 32v16z" />
         </svg>
      );
   };

   public shareFeed = (className?: string, fill?: string): SVGElement | any => {
      return (
         <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            fill={fill}
            viewBox="0 0 512 512"
         >
            <path d="M447.1 0h-384c-35.25 0-64 28.75-64 63.1v287.1c0 35.25 28.75 63.1 64 63.1h96v83.1c0 9.838 11.03 15.55 19.12 9.7l124.9-93.7h144c35.25 0 64-28.75 64-63.1V63.1C511.1 28.75 483.2 0 447.1 0zM480 352c0 17.6-14.4 32-32 32h-144.1c-6.928 0-13.67 2.248-19.21 6.406L192 460v-60c0-8.838-7.164-16-16-16H64c-17.6 0-32-14.4-32-32V64c0-17.6 14.4-32 32-32h384c17.6 0 32 14.4 32 32V352zM345.5 109.1c-7.617-7.559-17.5-12.83-28.23-13.78c-13.24-1.172-25.9 3.414-35.16 12.59L157.9 232.1c-2.234 2.215-3.754 5.039-4.375 8.113L141.3 300.1c-2.234 11.11 7.633 20.91 18.83 18.68L221.3 307.5c3.094-.6133 5.938-2.125 8.172-4.344l123.1-122.2c7.617-7.555 12.92-17.37 13.87-28.02c1.168-13.14-3.457-25.7-12.71-34.88L345.5 109.1zM210.3 277.3L177.3 283.9l6.594-32.71l86.88-86.23l26.38 26.18L210.3 277.3zM331.1 157.4l-11.3 11.22l-26.38-26.18l11.31-11.22c4.688-4.645 12.28-4.652 16.97 .0039l9.41 9.336C335.8 145.2 335.8 152.8 331.1 157.4z" />
         </svg>
      );
   };

   public barStaggered = (
      className?: string,
      fill?: string
   ): SVGElement | any => {
      return (
         <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className={className}
            fill={fill}
         >
            <path d="M0 80C0 71.16 7.164 64 16 64H432C440.8 64 448 71.16 448 80C448 88.84 440.8 96 432 96H16C7.164 96 0 88.84 0 80zM64 240C64 231.2 71.16 224 80 224H496C504.8 224 512 231.2 512 240C512 248.8 504.8 256 496 256H80C71.16 256 64 248.8 64 240zM432 416H16C7.164 416 0 408.8 0 400C0 391.2 7.164 384 16 384H432C440.8 384 448 391.2 448 400C448 408.8 440.8 416 432 416z" />
         </svg>
      );
   };

   public barSort = (className?: string, fill?: string): SVGElement | any => {
      return (
         <svg
            className={className}
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
         >
            <path d="M0 80C0 71.16 7.164 64 16 64H432C440.8 64 448 71.16 448 80C448 88.84 440.8 96 432 96H16C7.164 96 0 88.84 0 80zM0 240C0 231.2 7.164 224 16 224H304C312.8 224 320 231.2 320 240C320 248.8 312.8 256 304 256H16C7.164 256 0 248.8 0 240zM176 416H16C7.164 416 0 408.8 0 400C0 391.2 7.164 384 16 384H176C184.8 384 192 391.2 192 400C192 408.8 184.8 416 176 416z" />
         </svg>
      );
   };

   public crossIcon = (className?: string, fill?: string): SVGElement | any => {
      return (
         <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            fill={fill}
            viewBox="0 0 320 512"
         >
            <path d="M315.3 411.3c-6.253 6.253-16.37 6.253-22.63 0L160 278.6l-132.7 132.7c-6.253 6.253-16.37 6.253-22.63 0c-6.253-6.253-6.253-16.37 0-22.63L137.4 256L4.69 123.3c-6.253-6.253-6.253-16.37 0-22.63c6.253-6.253 16.37-6.253 22.63 0L160 233.4l132.7-132.7c6.253-6.253 16.37-6.253 22.63 0c6.253 6.253 6.253 16.37 0 22.63L182.6 256l132.7 132.7C321.6 394.9 321.6 405.1 315.3 411.3z" />
         </svg>
      );
   };
}

const Svg = new AppSvg();

export default Svg;
