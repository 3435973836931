import React, { FC } from 'react';
import { Loading } from '../Loading/Loading';
import { CgSpinnerTwo } from 'react-icons/cg';

interface IButton{
   title: string | JSX.Element;
   className: string;
   id?: string;
   onClick: (e) => void;
   disabled: boolean;
   loading: boolean;
}

interface IButtonLabel{
   onClick: () => void;
   title: string;
   loading: boolean;
   className: string;
   children?: JSX.Element;
}

export const Button:FC<IButton> = ({ id, className, title, disabled, loading, onClick }) => {
   return <button className={className} id={id} disabled={disabled} onClick={(e) => onClick(e)} type="button">
      { loading && <Loading type='simple' /> }
      { title }
   </button>;
};

export const ButtonLabel:FC<IButtonLabel> = ({ className, title, loading, onClick, children }) => {
   return <label className={`${className} ${loading ? 'pointer-events-none' : 'pointer-events-auto'}`} onClick={() => loading ? console.log() : onClick()}>
      { loading ? <CgSpinnerTwo className="text-sm animate-spin text-black" /> : title }
      { children }
   </label>;
};