import React, { FC } from 'react';

interface ILoading{
   type?: string;
   className?: string;
   fill?: string;
}

export const Loading:FC<ILoading> = ({ type, className, fill }) => {

   if(type === 'simple'){
      return <svg className={`animate-spin h-5 w-5 mr-3 ${className}`} fill={fill} viewBox="0 0 24 24"></svg>;
   } 

   return (
      <div className="animate-pulse flex space-x-4">
         <div className="rounded-full bg-slate-700 h-10 w-10"></div>
         <div className="flex-1 space-y-6 py-1">
            <div className="h-2 bg-slate-700 rounded"></div>
            <div className="space-y-3">
               <div className="grid grid-cols-3 gap-4">
                  <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                  <div className="h-2 bg-slate-700 rounded col-span-1"></div>
               </div>
               <div className="h-2 bg-slate-700 rounded"></div>
            </div>
         </div>
      </div>
   );
};