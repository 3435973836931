import { IS_LOGIN, IS_LOGOUT } from "Redux/Types";
import { IAction, IUser } from "Utilities/State.interfaces";

export const isLoginAction = (data: IUser): IAction => ({
   type: IS_LOGIN,
   payload: data
});

export const isLogoutAction = (): IAction => ({
   type: IS_LOGOUT,
   payload: null
});