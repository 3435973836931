import { gql } from '@apollo/client';
import { UserFragment, UserProfileFragment } from 'Apollo/Fragments';

export const GET_ALL_USERS = gql`
  query($page: Float $limit: Float $searchTerm: String $type: String! $lat: Float $lng: Float) {
    getUsers(page: $page limit: $limit searchTerm: $searchTerm type: $type lat: $lat lng: $lng) {
        id       
        ...UserFragment 
        userProfile {
          id
          ...UserProfileFragment
        }
    }
  }
  ${UserFragment}
  ${UserProfileFragment}
`;

export const GET_USER_DETAIL = gql`
  query{
    getMyCompleteDetails{
        id
       ...UserFragment
       userProfile {
          id
          ...UserProfileFragment
       }
    }
  }
  ${UserFragment}
  ${UserProfileFragment}
`;